import { useRouter } from 'next/router';

import { useAmazonAccountsQ as _useAmazonAccountsQ } from '@/lib/hooks/queries/useAmazonAccounts.query';
import { useGetTotalCostSavingsQuery as _useGetTotalCostSavingsQuery } from '@/lib/hooks/queries/useGetTotalCostSavings.query';
import { useSelectedAccPaidStatusQ as _useSelectedAccPaidStatusQ } from '@/lib/hooks/queries/useSelectedAccPaidStatus.query';
import { useUserQ as _useUserQ } from '@/lib/hooks/queries/useUser.query';

import { useAssistantState } from '@/components/assistant/hooks/useAssistantState';
import { useAssistantCTAsQuery as _useAssistantCTAsQuery } from '@/components/assistant/queries/useAssistantCtas.query';

// import { useTogglesQuery as _useTogglesQuery } from '@/components/recommendations/queries/useToggles.query';
import { useCheckPagePermission } from '../permissions/app-permissions';
import { useFlag } from '@unleash/proxy-client-react';
import { FEATURE_FLAGS } from '../feature-flags/feature-flags.constants';
import { useGlobalStore } from '../state-management/useGlobalStore';
import { AmazonAccount } from '@/types/user/user-amazon-account.type';
import { useAbility } from '@casl/react';
import { AbilityContext } from '../permissions/abilities/Can';

const POSSIBLE_STATES = {
  INITIAL_STATE: 'INITIAL_STATE',
  LOADING: 'LOADING',
  TRIAL_EXPIRED: 'TRIAL_EXPIRED',
  PAID: 'PAID',
  ASSISTANT_CENTERED: 'ASSISTANT_CENTERED',
  ACCESS_DENIED: 'ACCESS_DENIED',
  CHILDREN_COMPONENTS: 'CHILDREN_COMPONENTS'
};

export const publicRoutes = [
  // URL / should be public because it is being redirected to /dashboard. We should check permissions for dashboard instead
  // if URL / is not public, while the user is not redirected, he will see a permission denied page
  '/',
  '/settings/[[...params]]',
  '/settings/general',
  '/settings/amazon-accounts',
  '/settings/plan-and-billing',
  '/settings/sharing'
];

export const adminRoutes = ['/admin', '/admin/client-data', '/admin/kpi', '/admin/use-cases'];

interface UseAppStateProps {
  useUserQ?: typeof _useUserQ;
  useSelectedAccPaidStatusQ?: typeof _useSelectedAccPaidStatusQ;
  useAmazonAccountsQ?: typeof _useAmazonAccountsQ;
  useGetTotalCostSavingsQuery?: typeof _useGetTotalCostSavingsQuery;
  useAssistantCTAsQuery?: typeof _useAssistantCTAsQuery;
}

export const useAppState = ({
  useUserQ = _useUserQ,
  useSelectedAccPaidStatusQ = _useSelectedAccPaidStatusQ,
  useAmazonAccountsQ = _useAmazonAccountsQ,
  useGetTotalCostSavingsQuery = _useGetTotalCostSavingsQuery,
  useAssistantCTAsQuery = _useAssistantCTAsQuery // useTogglesQuery = _useTogglesQuery
}: UseAppStateProps = {}) => {
  const APP_STATE = {
    stateName: POSSIBLE_STATES.INITIAL_STATE,
    shouldDisplayIfAvailable: {
      accessDenied: true,
      assistant: true,
      childrenComponents: true
    }
  };

  const { data: user } = useUserQ();
  const router = useRouter();
  const selectedAmazonAccount = [
    useGlobalStore((state) => state.selectedAmazonAccount as AmazonAccount)
  ];
  // console.log('router.route:', router.route);
  const hasPermissionToAccessPage = useCheckPagePermission({
    url: router.route,
    userModules: user?.userModules
  });
  const ability = useAbility(AbilityContext);
  // console.log('hasPermissionToAccessPage:', hasPermissionToAccessPage);

  const assistantState = useAssistantState({
    useUserQ,
    useGetTotalCostSavingsQuery,
    useSelectedAccPaidStatusQ,
    useAmazonAccountsQ,
    useAssistantCTAsQuery
    // useTogglesQuery
  });

  const isNewPricingPackagesEnabled = useFlag(FEATURE_FLAGS.NEW_PRICING_PACKAGES);
  const freeRoutesForNotPaidOrTrialUsers = [
    ...publicRoutes,
    '/accounts/[account]/dashboard/[[...params]]',
    '/accounts/[account]/dashboard/profit/[[...params]]',
    '/accounts/[account]/dashboard/ppc-reporting/[[...params]]',
    '/accounts/[account]/base-data/[[...params]]'
  ];

  // console.log('assistantState.stateNameSnapshot:', assistantState.stateNameSnapshot);
  // assistantState.properties.
  // if (assistantState.stateNameSnapshot === 'isPaidStatusSharedAccEnabled:true-isPaidUser:false-isOwnAmazonAccount:true-isLoadingAny:false-isSelectedAmazonAccountPaid:false-isSharingRecommendationsAutomatedEnabled:true-isFbaMissingInboundAutomatedEnabled:true') {
  // console.log(
  //   'assistantState.properties.isPositionTopOrCenter:',
  //   assistantState.properties.isPositionTopOrCenter
  // );

  // if we are in a route that we should always have access, we should show the children components
  // if the route is public, like settings, then return true anyway
  if (hasPermissionToAccessPage && publicRoutes.includes(router.route)) {
    // return true;
    APP_STATE.stateName = POSSIBLE_STATES.CHILDREN_COMPONENTS;
    APP_STATE.shouldDisplayIfAvailable.accessDenied = false;
    APP_STATE.shouldDisplayIfAvailable.assistant = false;
    APP_STATE.shouldDisplayIfAvailable.childrenComponents = true;
    // console.log('APP_STATE:', APP_STATE)
    return APP_STATE;
  }

  // User has no permission to access page:
  // if the account selected is owned by the user, Arthy should be shown in the center, offering to upgrade to paid -> that state is handled by the assistantState hook
  if (
    hasPermissionToAccessPage === false &&
    assistantState.stateProperties.isOwnAmazonAccount === true
  ) {
    APP_STATE.stateName = POSSIBLE_STATES.ACCESS_DENIED;
    APP_STATE.shouldDisplayIfAvailable.accessDenied = false;
    APP_STATE.shouldDisplayIfAvailable.assistant = true;
    APP_STATE.shouldDisplayIfAvailable.childrenComponents = false;
    // console.log('APP_STATE:', APP_STATE)
    return APP_STATE;
  }

  // User has permission to access page (without arthy):
  // if the account selected is owned by the user, is not paid/trial and the current route is one of the freeRoutesForNotPaidOrTrialUsers
  if (
    hasPermissionToAccessPage === true &&
    assistantState.stateProperties.isOwnAmazonAccount === true &&
    assistantState.stateProperties.isPaidUser === false &&
    isNewPricingPackagesEnabled &&
    freeRoutesForNotPaidOrTrialUsers.includes(router.route)
  ) {
    APP_STATE.stateName = POSSIBLE_STATES.ACCESS_DENIED;
    APP_STATE.shouldDisplayIfAvailable.accessDenied = false;
    APP_STATE.shouldDisplayIfAvailable.assistant =
      selectedAmazonAccount[0]?.status !== 'finished' ? true : false;
    APP_STATE.shouldDisplayIfAvailable.childrenComponents = true;
    // console.log('APP_STATE:', APP_STATE)
    return APP_STATE;
  }

  // if the account selected is not owned by the user (shared account), Arthy should not be shown, the Access Denied page should be shown instead
  if (
    hasPermissionToAccessPage === false &&
    assistantState.stateProperties.isOwnAmazonAccount === false
  ) {
    APP_STATE.stateName = POSSIBLE_STATES.ACCESS_DENIED;
    APP_STATE.shouldDisplayIfAvailable.accessDenied = true;
    APP_STATE.shouldDisplayIfAvailable.assistant = false;
    APP_STATE.shouldDisplayIfAvailable.childrenComponents = false;
    // console.log('APP_STATE:', APP_STATE)
    return APP_STATE;
  }

  // If Arthy is being shown in the center, the children components should not be shown

  // isPaidStatusSharedAccEnabled:true-isPaidUser:false-isOwnAmazonAccount:false-isLoadingAny:false-isSelectedAmazonAccountPaid:true-isSharingRecommendationsAutomatedEnabled:true-isFbaMissingInboundAutomatedEnabled:true

  if (
    assistantState.arthyState.arthyCenteredVisible === true &&
    assistantState.properties.isPositionTopOrCenter === 'position-center' &&
    !ability.can('see', router?.pathname)
  ) {
    APP_STATE.stateName = POSSIBLE_STATES.ASSISTANT_CENTERED;
    APP_STATE.shouldDisplayIfAvailable.accessDenied = false;
    APP_STATE.shouldDisplayIfAvailable.assistant = true;
    APP_STATE.shouldDisplayIfAvailable.childrenComponents = false;
    // console.log('APP_STATE:', APP_STATE)
    return APP_STATE;
  }

  // if is a Shared Account and the account owner is not paid,
  // we should not show the Children Components
  if (
    hasPermissionToAccessPage === true &&
    assistantState.stateProperties.isOwnAmazonAccount === false &&
    assistantState.stateProperties.isSelectedAmazonAccountPaid === false
  ) {
    APP_STATE.stateName = POSSIBLE_STATES.ASSISTANT_CENTERED;
    APP_STATE.shouldDisplayIfAvailable.accessDenied = false;
    APP_STATE.shouldDisplayIfAvailable.assistant = true;
    APP_STATE.shouldDisplayIfAvailable.childrenComponents = false;
    // console.log('APP_STATE:', APP_STATE)
    return APP_STATE;
  }

  // const appStateNameSnapshot = Object.entries(APP_STATE)
  //   .map(([key, value]) => {
  //     // console.log('key:', key)
  //     // console.log('value:', value)
  //     return `${key}:${JSON.stringify(value)}`;
  //   })
  //   .join('-');
  // console.log('appStateNameSnapshot:', appStateNameSnapshot);

  return APP_STATE;
};
