import { useEffect } from 'react';

import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';

import { useGlobalStore } from '../state-management/useGlobalStore';
import { useAmazonAndSharedAccountsQ } from './queries/useAmazonAndSharedAccounts.query';
import { useLinkHandling } from './useLinkHandling';
import { useUserQ } from './queries/useUser.query';
import { useIsAutomationAccount } from '@/components/recommendations/helpers/useIsAutomationAccount';

export const useRegisterRedirect = () => {
  const { data: userData, isLoading: isLoadingUser, isSuccess: isUserSuccess } = useUserQ();
  const {
    data: amazonAndSharedAccountsData = { ownAccounts: [], sharedAccounts: [] },
    isLoading: isLoadingAmazonAndSharedQ
  } = useAmazonAndSharedAccountsQ();

  const session = useSession();
  const selectedAmazonAccount = useGlobalStore((state) => state.selectedAmazonAccount);
  const router = useRouter();
  const { DASHBOARD, SIGN_UP_CONNECT_AMAZON_ACCOUNT, RECOMMENDATIONS } = useLinkHandling();
  const isAutomationUser = useIsAutomationAccount();

  const redirectToDashboard = () => {
    // (FIXED) TODO [AMZ-2345]: hier eventuell auf user.roles.includes('automation') umstellen
    if (isAutomationUser) {
      router.push(RECOMMENDATIONS.path);
    } else {
      router.push(DASHBOARD.path);
    }
  };

  const redirectToSignUp = () => {
    router.push(SIGN_UP_CONNECT_AMAZON_ACCOUNT.path);
  };

  const redirectIfAuthenticated = () => {
    if (session.status == 'authenticated' && userData && !isLoadingUser && isUserSuccess) {
      if (!selectedAmazonAccount) {
        const amazonAccountData = [
          ...amazonAndSharedAccountsData.ownAccounts,
          ...amazonAndSharedAccountsData.sharedAccounts
        ].flatMap((data) => data);
        if (amazonAccountData && amazonAccountData.length > 0) {
          redirectToDashboard();
        } else {
          if (!router.asPath.includes('settings') && !userData.hasPendingInvites) {
            redirectToSignUp();
          }
        }
      }
    }
  };

  useEffect(() => {
    if (!isLoadingAmazonAndSharedQ) {
      redirectIfAuthenticated();
    }
    // eslint-disable-next-line
  }, [amazonAndSharedAccountsData, selectedAmazonAccount, session, userData]);
};
