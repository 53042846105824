import { GREY } from '@/lib/styles/palette.style';
import { Autocomplete, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import { SyntheticEvent } from 'react';
import { useTranslation } from 'next-i18next';
import { useSearch } from '../../providers/search-provider.component';
import { MenuItemProps } from '../../base-menu.types';

interface AutoCompleteProps {
  filteredDataList: MenuItemProps[];
}

export const AutoCompleteComponent: React.FC<AutoCompleteProps> = ({ filteredDataList }) => {
  const { t } = useTranslation('common');
  const { setSearch } = useSearch();

  const handleSearch = (e: SyntheticEvent<Element, Event>, value: string) => {
    setSearch(value);
  };
  return (
    <Autocomplete
      data-cy='base-menu-auto-complete'
      freeSolo
      options={filteredDataList?.flatMap((data) => data.list.map((account) => account.name))}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            variant='standard'
            sx={{
              px: 2,
              py: 1
            }}
            placeholder={t('common:components.global-dropdown.find-account', {
              defaultValue: ''
            })}
            InputProps={{
              // @ts-ignore
              'data-cy': 'search-input-component',
              disableUnderline: true,
              startAdornment: (
                <Search
                  sx={{
                    color: GREY[500],
                    mr: 1
                  }}
                />
              )
            }}
          />
        );
      }}
      onInputChange={(e, val) => handleSearch(e, val)}
      sx={{
        marginBottom: 1
      }}
    />
  );
};
