import { GREY } from '@/lib/styles/palette.style';
import { keyframes } from '@mui/material';

export const chatConversationOverlay = {
  backgroundColor: GREY[100],
  height: 'calc(100vh - 96px)',
  display: 'flex',
  gridArea: 'chat-conversation',
  top: '80px',
  zIndex: 1100,
  right: 0
};

export const chatMessageActionButtonSize = { width: '16px', height: '16px' };

export const chatHistoryStyles = {
  height: '100%',
  flex: 1,
  overflowY: 'auto'
};

export const chatAvatarSize = { width: '28px', height: '28px', fontSize: '14px' };

const pulseBackground = keyframes`
  0% {
    background-color: transparent;
  }
  50% {
    background-color: rgba(33,133,251,0.2);
  }
  100% {
    background-color: transparent;
  }
`;
export const pulseAnimation = {
  animation: ` ${pulseBackground} 2s infinite`
};

export const chatAvatarShowButton = {
  position: 'fixed',
  bottom: '16px',
  right: '16px',
  width: '64px',
  height: '64px',
  zIndex: 999
};
