import { Card, Collapse, Stack } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import dynamic from 'next/dynamic';
import { chatConversationOverlay } from '../../style/chat.style';
import { ChatHeaderComponent } from './chat-header.component';
import { ChatMessageInputComponent } from '../chat-message/chat-message-input.component';
import useChatStore from '@/lib/state-management/useChatStore';
import useResponsive from '@/lib/hooks/useResponsive';
import { useFlag } from '@unleash/proxy-client-react';
import { FEATURE_FLAGS } from '@/lib/feature-flags/feature-flags.constants';
import { ChatHistoryComponent } from '../chat-history.component';
const ChatQuestionButtonsComponent = dynamic(
  () =>
    import('../chat-message/chat-question-buttons.component').then(
      (mod) => mod.ChatQuestionButtonsComponent
    ),
  {
    ssr: false
  }
);

export const ChatConversationLayoutComponent: React.FC = () => {
  const isArthyTalkEnabled = useFlag(FEATURE_FLAGS.CHAT);
  const isMinimized = useChatStore((state) => state.isMinimized);
  const isMobile = useResponsive('down', 'sm');
  const isTablet = useResponsive('down', 'md');

  if (!isArthyTalkEnabled) return null;
  return (
    <Collapse
      orientation='horizontal'
      in={isMinimized ? false : true}
      sx={{
        ...chatConversationOverlay,
        position: isTablet ? 'fixed' : 'sticky',
        mr: isMinimized ? 0 : 2,
        ml: isMobile ? 2 : 0
      }}
    >
      <Box
        data-cy='chat-conversation-layout-component'
        sx={{
          width: isMobile ? 'calc(100vw - 32px)' : isTablet ? '400px' : ' 500px',
          boxShadow: 2,
          height: '100%'
        }}
      >
        <Card sx={{ width: '100%', height: '100%' }}>
          <Stack
            spacing={4}
            sx={{
              width: '100%',
              height: '100%',
              p: 2
            }}
          >
            <ChatHeaderComponent />
            <ChatHistoryComponent />
            <ChatQuestionButtonsComponent />
            <ChatMessageInputComponent />
          </Stack>
        </Card>
      </Box>
    </Collapse>
  );
};
