import { PaymentSinglePackage } from '@/types/payment/new-payment-package';
import { Box, Card, Divider, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';

import PricingSectionFeatureListNew from './new-pricing-section-feature-list';
import PricingSectionInfo from './new-pricing-section-info';
import PricingSectionCheckoutNew from './new-pricing-section-checkout';
import { ContactSupport } from '@/components/ui/contact-support/contact-support.component';
import { useModalStore } from '@/lib/state-management/useModalStore';
import { useAmazonAccountsQ } from '@/lib/hooks/queries/useAmazonAccounts.query';
import { useCalculateYearlyRevenueForAllAccounts } from '@/components/assistant/hooks/useCalculateYearlyRevenueForAllAccounts';
import { useUserQ } from '@/lib/hooks/queries/useUser.query';

interface PricingSectionPackageProps {
  paymentPackage: PaymentSinglePackage;
  redirectType?: 'signup';
  currency?: 'EUR' | 'USD';
  activeVariant: number;
}

const PricingSectionPackage: React.FC<PricingSectionPackageProps> = ({
  redirectType,
  paymentPackage,
  currency,
  activeVariant
}) => {
  const { t } = useTranslation(['payment', 'common']);
  const setShowSubscriptionModal = useModalStore((state) => state.setShowSubscriptionModal);
  const { data: amazonAccounts } = useAmazonAccountsQ();
  const { yearlyRevenueForAllAccounts } = useCalculateYearlyRevenueForAllAccounts(
    amazonAccounts || []
  );

  const { data: user } = useUserQ();
  const packageIsCurrentSubscriptedUserPackage = user?.userModules?.some(
    (module) => 'arthy-' + module.key === paymentPackage.name
  );

  if (
    ['arthy-premium', 'arthy-unlimited'].includes(paymentPackage.name) &&
    yearlyRevenueForAllAccounts > 10000000
  ) {
    return;
  }
  if (paymentPackage.name === 'arthy-enterprise' && yearlyRevenueForAllAccounts < 10000000) {
    return;
  }

  return (
    <Card
      variant='outlined'
      key={paymentPackage.name}
      sx={{
        width: 416,
        backgroundColor: (theme) =>
          paymentPackage.name === 'arthy-base' || packageIsCurrentSubscriptedUserPackage
            ? theme.palette.grey[200]
            : theme.palette.background.paper,
        boxShadow: (theme) => `1px 1px 2px 0px ${theme.palette.grey[400]}`,
        p: 2
      }}
    >
      <Typography variant='h6'>
        {t(`payment.modals.${paymentPackage.name}.title`, { defaultValue: '' })}
      </Typography>
      <Divider sx={{ my: 1 }} />
      <PricingSectionInfo
        redirectType={redirectType}
        paymentPackage={paymentPackage}
        currency={currency}
        activeVariant={activeVariant}
      />
      <Divider sx={{ my: 1 }} />
      <PricingSectionFeatureListNew
        features={t(`payment.modals.${paymentPackage.name}`, {
          ns: 'payment',
          returnObjects: true,
          defaultValue: ''
        })}
      />

      {['arthy-premium', 'arthy-unlimited'].includes(paymentPackage?.name) &&
      !packageIsCurrentSubscriptedUserPackage ? (
        <>
          <Divider sx={{ my: 1 }} />
          <PricingSectionCheckoutNew
            paymentPackage={paymentPackage}
            redirectType={redirectType}
            activeVariant={activeVariant}
          />
        </>
      ) : null}
      {paymentPackage?.name === 'arthy-enterprise' ? (
        <>
          <Divider sx={{ my: 1 }} />
          <Box sx={{ textAlign: 'center', mt: 8 }}>
            <ContactSupport
              supportReason='requestEnterprisePaymentPackage'
              dialogHeadline={t('common:components.support.general-headline')}
              dialogMessage={t('common:components.support.general-message')}
              onCloseDialog={() => setShowSubscriptionModal(false)}
            />
          </Box>
        </>
      ) : null}
    </Card>
  );
};
export default PricingSectionPackage;
