import { useEffect } from 'react';
import { toast } from 'react-toastify';

import type { AdvertisingAccount } from '@/types/user/user-advertising-account.type';
import type { AmazonAccount } from '@/types/user/user-amazon-account.type';
import { InfoOutlined } from '@mui/icons-material';
import { Alert, Box, MenuItem, Stack, TextField, Tooltip } from '@mui/material';
import { useTranslation } from 'next-i18next';

import { disableEdit, disableNew } from '../../settings.helper';
import { SelectAmazonAccount } from './select-amazon-account.component';

interface SettingsAmazonAccountConnectFormContentProps {
  limitReached: boolean;
  sourceAmazonAccount: AmazonAccount | AdvertisingAccount | null;
  requiresMarketplaceConfirmation: boolean | null;
  handleRegion: (newValue: string) => void;
  handleMainMarketplace: (newValue: string) => void;
  selectedMainMarketplace: string;
  selectedRegion: string;
  typeSelect: string;
  setTypeSelect: (newValue: string) => void;
  setDefaultMarketplace: (newValue: string) => void;
  defaultMarketplace: string;
  formDisabled: boolean;
  nameInput: string;
  setNameInput: (newValue: string) => void;
  setButtonDisabled: (newValue: boolean) => void;
  hideAccountTypeSelect?: boolean;
}

export const SettingsAmazonAccountConnectFormContent: React.FC<
  SettingsAmazonAccountConnectFormContentProps
> = ({
  limitReached,
  sourceAmazonAccount,
  requiresMarketplaceConfirmation,
  handleRegion,
  handleMainMarketplace,
  selectedMainMarketplace,
  selectedRegion,
  typeSelect,
  setTypeSelect,
  formDisabled,
  setDefaultMarketplace,
  setButtonDisabled,
  defaultMarketplace,
  nameInput,
  setNameInput,
  hideAccountTypeSelect = false
}) => {
  const { t } = useTranslation(['settings', 'common']);
  useEffect(() => {
    const buttonDisabled = sourceAmazonAccount
      ? disableEdit({
          // @ts-ignore
          oldName: sourceAmazonAccount.name,
          newName: nameInput,
          requiresMarketplaceConfirmation,
          defaultMarketplace
        })
      : disableNew(
          // @ts-ignore
          {
            name: nameInput
          }
        );
    if (!buttonDisabled) {
    }
    setButtonDisabled(buttonDisabled);
  }, [
    defaultMarketplace,
    setButtonDisabled,
    nameInput,
    requiresMarketplaceConfirmation,
    sourceAmazonAccount
  ]);

  return (
    <Stack data-cy='amazon-account-new-content' gap={2} sx={{ pt: 1, mb: 2 }}>
      {limitReached === true ? (
        <Alert severity='warning'>
          {t('common:components.connected-amazon-accounts.form.noCredits')}
        </Alert>
      ) : (
        <>
          {requiresMarketplaceConfirmation ? (
            <TextField
              data-cy='amazon-account-marketplace-name-input-confirmation'
              select
              inputProps={{ MenuProps: { disableScrollLock: true } }}
              fullWidth
              helperText={
                <Tooltip
                  arrow
                  placement='bottom'
                  title={
                    <Box>
                      {t(
                        'common:components.connected-amazon-accounts.list.defaultMarketplaceTooltip'
                      )}{' '}
                      <Box component='span' sx={{ fontWeight: 'bold' }}>
                        {t(
                          'common:components.connected-amazon-accounts.list.defaultMarketplaceTooltipUnchangeable'
                        )}
                      </Box>
                    </Box>
                  }
                  enterDelay={500}
                >
                  <Box sx={{ cursor: 'pointer' }}>
                    {t('common:components.connected-amazon-accounts.list.defaultMarketplaceInfo')}
                    <InfoOutlined
                      sx={{
                        ml: 1,
                        mr: 1,
                        fontSize: (theme) => theme.typography.body1.fontSize
                      }}
                      color='primary'
                    />
                  </Box>
                </Tooltip>
              }
              onClick={() => toast.dismiss()}
              disabled={formDisabled}
              onChange={(e) => {
                setDefaultMarketplace(e.target.value);
              }}
              value={defaultMarketplace}
              label={t('common:components.connected-amazon-accounts.list.defaultMarketplace')}
            >
              {defaultMarketplace === '' ? (
                <MenuItem value='' disabled>
                  {t('common:components.connected-amazon-accounts.list.defaultMarketplace')}
                </MenuItem>
              ) : null}
              {/* @ts-ignore */}
              {sourceAmazonAccount.supportedAmazonCountries.map((country: string) => (
                <MenuItem key={country} value={country}>
                  {t(`common:general.countries.${country}`, {
                    defaultValue: ''
                  })}
                </MenuItem>
              ))}
            </TextField>
          ) : (
            <TextField
              data-cy='amazon-account-marketplace-name-input'
              fullWidth
              onClick={() => toast.dismiss()}
              disabled={formDisabled}
              onChange={(e) => setNameInput(e.target.value)}
              value={nameInput}
              label={t('common:components.connected-amazon-accounts.list.name')}
            />
          )}
          {!sourceAmazonAccount ? (
            <>
              <SelectAmazonAccount
                typeSelect={typeSelect}
                hideAccountTypeSelect={hideAccountTypeSelect}
                setTypeSelect={setTypeSelect}
                handleRegion={handleRegion}
                handleMainMarketplace={handleMainMarketplace}
                selectedMainMarketplace={selectedMainMarketplace}
                selectedRegion={selectedRegion}
              />
            </>
          ) : null}
        </>
      )}
    </Stack>
  );
};
