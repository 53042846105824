import CollapseButton from '@/components/ui/collapse-button';
import cssStyles from '@/lib/styles/cssStyles.style';
import ScrollbarComponent from '../scroll-bar/scroll-bar.component';
import useCollapseDrawer from '@/lib/hooks/useCollapseDrawer';
import useLocalStorage from '@/lib/hooks/useLocalStorage';
import useNavConfig from '@/lib/hooks/useNavConfig';
import useResponsive from '@/lib/hooks/useResponsive';
import { Box, Drawer } from '@mui/material';
import { customShadows } from '@/lib/styles/shadows.style';
import { GREY } from '@/lib/styles/palette.style';
import { NAVBAR } from '@/lib/constants/layout.constant';
import { NavSectionVertical } from '../nav-section/nav-section.component';
import { RootStyle } from './navbar.style';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useTheme } from '@mui/material/styles';

interface NavbarProps {
  isOpenSidebar: boolean;
  onCloseSidebar: () => void;
}

const NavbarVerticalComponent: React.FC<NavbarProps> = ({ isOpenSidebar, onCloseSidebar }) => {
  const theme = useTheme();
  const router = useRouter();
  const { pathname } = router;
  const navConfig = useNavConfig();
  const isDesktop = useResponsive('up', 'lg');
  const { isCollapse, collapseClick, collapseHover, onToggleCollapse } = useCollapseDrawer();
  const [isNavbarClosed, setIsNavbarClosed] = useLocalStorage('isNavbarClosed', false);

  useEffect(() => {
    if (collapseClick !== isNavbarClosed) {
      onToggleCollapse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <>
      <ScrollbarComponent>
        {navConfig && <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} />}
      </ScrollbarComponent>
      {isDesktop && (
        <Box
          sx={{
            pr: isCollapse ? 0 : 2,
            pb: 8,
            width: '100%',
            display: 'flex',
            justifyContent: isCollapse ? 'center' : 'flex-end',
            borderTop: `1px solid ${GREY[100]}`,
            backgroundColor: 'white'
          }}
        >
          <CollapseButton
            onToggleCollapse={onToggleCollapse}
            collapseClick={collapseClick}
            setIsNavbarClosed={setIsNavbarClosed}
          />
        </Box>
      )}
    </>
  );
  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH
        },
        display: 'block',
        backgroundColor: 'white',
        borderRight: '1px solid rgba(209, 213, 219, 0.24)' // style from MUI
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
        >
          {renderContent}
        </Drawer>
      )}
      {isDesktop && (
        <Drawer
          open
          variant='persistent'
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              backgroundColor: 'white',
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: () => customShadows.light.z24
              }),
              gridArea: 'nav',
              zIndex: 1,
              top: '64px',
              overflowY: 'auto',
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
};

export default NavbarVerticalComponent;
