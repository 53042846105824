import { useUserQ as _useUserQ } from '@/lib/hooks/queries/useUser.query';
import { useAmazonAccountsQ as _useAmazonAccountsQ } from '@/lib/hooks/queries/useAmazonAccounts.query';
import { useGetTotalCostSavingsQuery as _useGetTotalCostSavingsQuery } from '@/lib/hooks/queries/useGetTotalCostSavings.query';
import { useSelectedAccPaidStatusQ as _useSelectedAccPaidStatusQ } from '@/lib/hooks/queries/useSelectedAccPaidStatus.query';
import { useAssistantCTAsQuery as _useAssistantCTAsQuery } from '../../assistant/queries/useAssistantCtas.query';
import { useAppState as _useAppState } from '@/lib/hooks/useAppState';
import { useRouter } from 'next/router';
import { useLinkHandling } from '@/lib/hooks/useLinkHandling';
import { useGlobalStore } from '@/lib/state-management/useGlobalStore';
import { formatReactQuery } from '@/lib/helpers/utils/formatReactQuery';
import { DashboardLayoutMain } from './dashboard-layout-main.component';
import { useIsOwnAmazonAccount } from '@/lib/hooks/useIsOwnAmazonAccount';

interface DashboardLayoutMainWrapperProps {
  children?: React.ReactNode;
  useUserQ?: typeof _useUserQ;
  useAmazonAccountsQ?: typeof _useAmazonAccountsQ;
  useSelectedAccPaidStatusQ?: typeof _useSelectedAccPaidStatusQ;
  useGetTotalCostSavingsQuery?: typeof _useGetTotalCostSavingsQuery;
  useAssistantCTAsQuery?: typeof _useAssistantCTAsQuery;
  useAppState?: typeof _useAppState;
}
export const DashboardLayoutMainWrapper: React.FC<DashboardLayoutMainWrapperProps> = ({
  children,
  useUserQ = _useUserQ,
  useAmazonAccountsQ = _useAmazonAccountsQ,
  useSelectedAccPaidStatusQ = _useSelectedAccPaidStatusQ,
  useGetTotalCostSavingsQuery = _useGetTotalCostSavingsQuery,
  useAssistantCTAsQuery = _useAssistantCTAsQuery,
  useAppState = _useAppState
}) => {
  const {
    shouldDisplayIfAvailable: { accessDenied, childrenComponents }
  } = useAppState({
    useUserQ,
    useGetTotalCostSavingsQuery,
    useSelectedAccPaidStatusQ,
    useAmazonAccountsQ,
    useAssistantCTAsQuery
  });

  const selectedAmazonAccount = useGlobalStore((state) => state.selectedAmazonAccount);
  const URLS = useLinkHandling();
  const router = useRouter();
  const { data: paidStatus } = formatReactQuery(
    useSelectedAccPaidStatusQ,
    selectedAmazonAccount?._id as string
  );
  const isOwnAccount = useIsOwnAmazonAccount();

  const showSubscriptionInfoBox =
    (router.asPath.includes(URLS.RECOMMENDATIONS.path) ||
      router.asPath.includes(URLS.AUTOMATIONS.path) ||
      router.asPath.includes(URLS.REVIEWS_ASINS.path)) &&
    isOwnAccount &&
    !paidStatus?.isSelectedAccPaid;

  return (
    <DashboardLayoutMain
      useUserQ={useUserQ}
      useAmazonAccountsQ={useAmazonAccountsQ}
      useSelectedAccPaidStatusQ={useSelectedAccPaidStatusQ}
      useGetTotalCostSavingsQuery={useGetTotalCostSavingsQuery}
      useAssistantCTAsQuery={useAssistantCTAsQuery}
      showSubscriptionInfoBox={showSubscriptionInfoBox || false}
      showChildrenComponents={childrenComponents}
      showAccessDenied={accessDenied}
    >
      {children}
    </DashboardLayoutMain>
  );
};
