import { Lock } from '@mui/icons-material';
import { useTranslation } from 'next-i18next';

// import { useTogglesQuery as _useTogglesQuery } from '@/components/recommendations/queries/useToggles.query';
import { StatusInfo } from './status-info.component';

export const AccessDenied = ({}) => {
  const { t } = useTranslation('common');

  return (
    <StatusInfo
      title={t('components.access-denied.title')}
      message={t('components.access-denied.message')}
      icon={<Lock sx={{ fontSize: 64 }} color='warning' />}
    />
  );
};
