import { NavItem } from '@/types/navigation/navigation';
import { Badge, Box, ListItem, Tooltip } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import LockIcon from '@mui/icons-material/Lock';
import { getActive } from '../../nav-section.helper';
import NavItemLinkInfo from './nav-item-link-info';
import { ListItemIconStyle, ListItemStyle, ListItemTextStyle } from './nav-item.style';
import { useAbility } from '@casl/react';
import { AbilityContext } from '@/lib/permissions/abilities/Can';
import { useIsOwnAmazonAccount } from '@/lib/hooks/useIsOwnAmazonAccount';
import { useUserQ } from '@/lib/hooks/queries/useUser.query';
import { useCheckPagePermission } from '@/lib/permissions/app-permissions';

interface NavItemLinkContentProps {
  item: NavItem;
  isCollapse: boolean;
  showNotificationCounter?: boolean;
  numberOfRecommendations?: number;
  open?: boolean;
}

const shouldShowLockIcon = (
  isOwnAmazonAccount: boolean,
  userHasEditPermissions: boolean,
  userHasSharingPermission: boolean
) => {
  if (isOwnAmazonAccount && !userHasEditPermissions) {
    return true;
  }

  if (!isOwnAmazonAccount && !userHasSharingPermission) {
    return true;
  }

  return false;
};

export const NavItemLinkContent: React.FC<NavItemLinkContentProps> = ({
  item,
  isCollapse,
  showNotificationCounter = false,
  numberOfRecommendations = 0,
  open
}) => {
  const { t } = useTranslation('nav-bar');
  const translatedTitle = t(`${item.title}` as const);
  const { route } = useRouter();
  const { data: user } = useUserQ();

  const pagePermission = useCheckPagePermission({
    url: item.route,
    userModules: user?.userModules || []
  });

  const isActive = getActive(route, item.route);

  const ability = useAbility(AbilityContext);
  // @ts-ignore
  const userHasEditPermissions = ability.can('edit', item.abilitiesName);
  const isOwnAmazonAccount = useIsOwnAmazonAccount();
  const showLockedIcon =
    isOwnAmazonAccount !== undefined
      ? shouldShowLockIcon(isOwnAmazonAccount, userHasEditPermissions, pagePermission)
      : false;

  return (
    <ListItem
      disablePadding
      data-cy={`nav-item-${item.title}`}
      secondaryAction={
        showLockedIcon && !isCollapse ? (
          <LockIcon data-cy='lock-icon' color={isActive ? 'primary' : 'inherit'} />
        ) : null
      }
      disabled={item.disabled}
    >
      <ListItemStyle active={isActive}>
        {isCollapse ? (
          <Tooltip
            arrow
            enterDelay={0}
            leaveDelay={0}
            // @ts-ignore
            title={translatedTitle}
            placement='right'
            sx={{
              '& .MuiTooltip-tooltip': {
                opacity: isCollapse ? 1 : 0
              }
            }}
          >
            <Badge
              overlap='circular'
              badgeContent={numberOfRecommendations}
              sx={{
                '& .MuiBadge-badge': {
                  opacity: showNotificationCounter ? 1 : 0
                }
              }}
              color='primary'
            >
              <ListItemIconStyle isActive={isActive}>{item.icon}</ListItemIconStyle>
            </Badge>
          </Tooltip>
        ) : (
          <ListItemIconStyle isActive={isActive}>{item.icon}</ListItemIconStyle>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <ListItemTextStyle
            isCollapse={isCollapse}
            // @ts-ignore
            primary={translatedTitle}
            primaryTypographyProps={{
              noWrap: true,
              variant: isActive ? 'subtitle2' : 'body2'
            }}
            secondaryTypographyProps={{
              noWrap: true,
              variant: 'caption'
            }}
          />
          {!isCollapse && showNotificationCounter && !showLockedIcon && (
            <Badge
              badgeContent={numberOfRecommendations}
              color='primary'
              sx={{
                marginLeft: 2.5
              }}
            />
          )}
        </Box>
        {!isCollapse && (
          <NavItemLinkInfo info={item.info} open={open} hasChildren={!!item.children} />
        )}
      </ListItemStyle>
    </ListItem>
  );
};
