import { AmazonAccount } from '@/types/user/user-amazon-account.type';
import { User } from '@sentry/browser';

import { useRouter } from 'next/router';

import { useAmazonAccountsQ as _useAmazonAccountsQ } from '@/lib/hooks/queries/useAmazonAccounts.query';
import { useGetTotalCostSavingsQuery as _useGetTotalCostSavingsQuery } from '@/lib/hooks/queries/useGetTotalCostSavings.query';
import { useSelectedAccPaidStatusQ as _useSelectedAccPaidStatusQ } from '@/lib/hooks/queries/useSelectedAccPaidStatus.query';
import { useUserQ as _useUserQ } from '@/lib/hooks/queries/useUser.query';
// import { useCheckPagePermission } from '@/lib/permissions/app-permissions';
import { useGlobalStore } from '@/lib/state-management/useGlobalStore';

// import { useTogglesQuery as _useTogglesQuery } from '@/components/recommendations/queries/useToggles.query';
import { positionCenter, positionTop } from '../styles/assistant.styles';
import { useAssistantCTAsQuery as _useAssistantCTAsQuery } from '../queries/useAssistantCtas.query';
import { PositionCenter, PositionTop } from '../types/assistant-position.type';
import { useLinkHandling } from '@/lib/hooks/useLinkHandling';
import { formatReactQuery } from '@/lib/helpers/utils/formatReactQuery';
import { useHideableRoute } from './assistant-state.helpers';

export const useAssistantState = ({
  useUserQ = _useUserQ,
  useSelectedAccPaidStatusQ = _useSelectedAccPaidStatusQ,
  useAmazonAccountsQ = _useAmazonAccountsQ,
  useGetTotalCostSavingsQuery = _useGetTotalCostSavingsQuery,
  useAssistantCTAsQuery = _useAssistantCTAsQuery
} = {}) => {
  const router = useRouter();

  const URLS = useLinkHandling();

  let isHideableRoute = useHideableRoute();
  const relevantRoutes = [
    URLS.MAIN_DASHBOARD.path,
    URLS.DASHBOARD.path,
    URLS.RECOMMENDATIONS.path,
    URLS.REVIEWS_ASINS.path,
    URLS.AUTOMATIONS.path
  ];
  const isRelevantRoute = relevantRoutes.some((path) => router.asPath.includes(path));
  // Fetch User data and check Paid user status
  const { isLoading: isLoadingUser, data: user } = useUserQ();
  const userData = user as User;
  const isPaidUser = userData?.isPaid || false;

  const selectedAmazonAccount = useGlobalStore(
    (state) => state.selectedAmazonAccount as AmazonAccount
  );
  const { data } = formatReactQuery(useSelectedAccPaidStatusQ, selectedAmazonAccount?._id);

  const isSelectedAmazonAccountPaid = data?.isSelectedAccPaid || false;

  // Fetch Amazon Accounts
  const {
    isLoading: isLoadingAmazonAccounts,
    data: amazonAccounts,
    isSuccess: isSuccessAmazonAccounts
  } = useAmazonAccountsQ();

  const isOwnAmazonAccount = typeof selectedAmazonAccount?.permission === 'undefined';

  const selectedAmazonAccountId = selectedAmazonAccount?._id || '';

  // Fetch totalCostSavings
  const { isLoading: isLoadingGetTotalCostSavings } = useGetTotalCostSavingsQuery();

  // Fetch Ctas
  const hasSelectedAmazonAccountId = selectedAmazonAccount?._id;
  const { isLoading: isLoadingCTAs } = useAssistantCTAsQuery({
    isPaidUser,
    amazon_account_id: hasSelectedAmazonAccountId ? selectedAmazonAccount?._id : undefined,
    isOwnAmazonAccount: !!isOwnAmazonAccount
  });

  // Loading all necessary information
  const isLoadingAny =
    isLoadingCTAs || isLoadingUser || isLoadingGetTotalCostSavings || isLoadingAmazonAccounts;

  let customPosition: PositionCenter | PositionTop = positionTop;
  let isPositionTopOrCenter: 'position-top' | 'position-center' = 'position-top';
  let showSharedInfoOnReviewsPage = false;
  let showSharedInfoOnRecommendationsPage = false;
  let showMinimizedArrow = true;

  if (!isLoadingAny && isSuccessAmazonAccounts) {
    // This is changing the position top or center of the assistant
    const hasAmazonAccount = selectedAmazonAccount !== null;
    const isAmazonAccountStatusUnfinished =
      hasAmazonAccount && selectedAmazonAccount?.status !== 'finished';
    const isAmazonAccountSyncStatusUnfinished =
      hasAmazonAccount && selectedAmazonAccount?.synch_dashboard?.status !== 'finished';
    const isAmazonAccountStatusDisconnectedOrFaildInit =
      hasAmazonAccount &&
      (selectedAmazonAccount?.status === 'disconnected' ||
        selectedAmazonAccount?.status === 'failed_init');

    const isOwnAmazonAccountSelected = hasAmazonAccount
      ? amazonAccounts?.find((account) => account._id === selectedAmazonAccountId)
      : null;
    const hasPermissionForReviews = selectedAmazonAccount?.permission?.resolved?.find(
      (permission) => permission.name === 'reviews'
    );

    const isAmazonAccountStatusfinishedAndSyncStatusFinished =
      hasAmazonAccount &&
      selectedAmazonAccount?.status === 'finished' &&
      selectedAmazonAccount?.synch_dashboard?.status === 'finished';

    if (
      (isRelevantRoute &&
        (isAmazonAccountStatusUnfinished || isAmazonAccountSyncStatusUnfinished)) ||
      (isRelevantRoute && isAmazonAccountStatusfinishedAndSyncStatusFinished && !isPaidUser)
    ) {
      customPosition = positionCenter;
      isPositionTopOrCenter = 'position-center';
      showMinimizedArrow = false;
      if (
        router.asPath.includes(URLS.RECOMMENDATIONS.path) ||
        router.asPath.includes(URLS.AUTOMATIONS.path)
      ) {
        isHideableRoute = false;
      }
    }

    // hide assistant on recommendations, automations and reviews, if account is not paid
    if (
      (router.asPath.includes(URLS.RECOMMENDATIONS.path) ||
        router.asPath.includes(URLS.AUTOMATIONS.path) ||
        router.asPath.includes(URLS.REVIEWS_ASINS.path)) &&
      !isSelectedAmazonAccountPaid
    ) {
      isHideableRoute = true;
    }

    // This is hiding the assistant on the dashboard because we have now the dashboard status
    if ((isRelevantRoute && isAmazonAccountStatusDisconnectedOrFaildInit) || !hasAmazonAccount) {
      isHideableRoute = true;
    }

    // Show Assistant on Recommendations or Reviews in center when selected account is not own account and has no permission to see content
    if (
      (isRelevantRoute && !isOwnAmazonAccountSelected) ||
      (router.asPath.includes(URLS.REVIEWS_ASINS.path) &&
        !isOwnAmazonAccountSelected &&
        !hasPermissionForReviews)
    ) {
      isHideableRoute = false;
      customPosition = positionCenter;
      isPositionTopOrCenter = 'position-center';
      showMinimizedArrow = false;
      if (router.asPath.includes(URLS.RECOMMENDATIONS.path)) {
        showSharedInfoOnRecommendationsPage = true;
      }
      if (router.asPath.includes(URLS.REVIEWS_ASINS.path)) {
        showSharedInfoOnReviewsPage = true;
      }
    }

    // Commented out the code below, since we want to show Arthy when the selectedAccount
    // is not Paid and the user is in the Recommendations page
    // Arthy will show the offer to upgrade to paid

    // if the shared user have no permission to see the recommendations page, hide the assistant and show the permission denied component
    // if (router.asPath.includes(URLS.RECOMMENDATIONS.path)) {
    //   if (!selectedAccountHasPermissionToAccessPage) {
    //     isHideableRoute = true;
    //   }
    // }
  }

  const ASSISTANT_PROPERTIES = {
    isInMainDashboardOrDashboardOrRecommendationsOrReviews: isRelevantRoute,
    isHideableRoute,
    showSharedInfoOnReviewsPage,
    showSharedInfoOnRecommendationsPage,
    showMinimizedArrow,
    isPositionTopOrCenter,
    customPosition,
    // filteredCtas,
    asPath: router.asPath
  };

  const ASSISTANT_STATE = {
    // isHideableRoute,
    // showSharedInfoOnReviewsPage,
    // showSharedInfoOnRecommendationsPage,
    // showMinimizedArrow,
    // isPositionTopOrCenterDataCy,
    // customPosition,
    // filteredCtas,

    // From here on, it is relevant to decide the state
    isPaidUser,
    isOwnAmazonAccount,
    isLoadingAny,
    isSelectedAmazonAccountPaid
  };

  // TODO: send the state to the backend when it changes, so we can
  const stateNameSnapshot = Object.entries(ASSISTANT_STATE)
    .map(([key, value]) => {
      return `${key}:${JSON.stringify(value)}`;
    })
    .join('-');

  // const statePropertiesNameSnapshot = Object.entries(ASSISTANT_PROPERTIES)
  //   .map(([key, value]) => {
  //     return `${key}:${JSON.stringify(value)}`;
  //   })
  //   .join('-');
  // console.log('statePropertiesNameSnapshot:', statePropertiesNameSnapshot);

  // assistantState.stateNameSnapshot: isPaidStatusSharedAccEnabled:true-isPaidUser:false-isOwnAmazonAccount:false-isLoadingAny:false-isSelectedAmazonAccountPaid:true-isSharingRecommendationsAutomatedEnabled:true-isFbaMissingInboundAutomatedEnabled:true

  const ARTHY_STATE = {
    arthyCenteredVisible: true
  };
  if (isSelectedAmazonAccountPaid && !isLoadingAny && isPositionTopOrCenter === 'position-center') {
    ARTHY_STATE.arthyCenteredVisible = false;
  }

  // const stateArthySnapshot = Object.entries(ARTHY_STATE)
  //   .map(([key, value]) => {
  //     // console.log('key:', key)
  //     // console.log('value:', value)
  //     return `${key}:${JSON.stringify(value)}`;
  //   })
  //   .join('-');
  // console.log('statePropertiesNameSnapshot:', stateArthySnapshot);

  // TODO: from the properties above, create readable state names and return them as a string in the propertie stateName
  const stateName = '';

  // if (isPaidUser)

  return {
    stateName,
    stateNameSnapshot,
    properties: { ...ASSISTANT_PROPERTIES },
    stateProperties: { ...ASSISTANT_STATE },
    arthyState: { ...ARTHY_STATE }
  };
};
