import NavItemComponent from '../nav-section-item/nav-item.component';
import NavVerticalSubListComponent from './nav-vertical-sub-list.component';
import { Collapse, Link, List } from '@mui/material';
import { getActive, isExternalLink } from '../../nav-section.helper';
import { useRouter } from 'next/router';
import { useState } from 'react';

import type { NavItem } from '@/types/navigation/navigation';

interface NavListProps {
  data: NavItem;
  depth: number;
  hasChildren?: boolean;
  isCollapse?: boolean;
}

const NavListComponent: React.FC<NavListProps> = ({
  data,
  depth,
  hasChildren,
  isCollapse = false
}) => {
  const { push, route } = useRouter();
  const isActive = getActive(route, data.route);
  const [open, setOpen] = useState(isActive);

  const handleClickItem = (nextLink = false) => {
    if (!data.disabled) return;
    if (nextLink) return;

    if (!hasChildren) {
      if (!isActive) {
        push(`${data.path}`);
      }
    }

    setOpen(!open);
  };

  return (
    <>
      {isExternalLink(data.path) ? (
        <Link href={data.path} target='_blank' rel='noopener' underline='none'>
          <NavItemComponent item={data} open={open} isCollapse={isCollapse} />
        </Link>
      ) : (
        <NavItemComponent
          item={data}
          open={open}
          isCollapse={isCollapse}
          onClick={() => handleClickItem(true)}
        />
      )}
      {!isCollapse && hasChildren && (
        <Collapse in={open} unmountOnExit>
          <List component='div' disablePadding>
            <NavVerticalSubListComponent data={data.children} depth={depth} />
          </List>
        </Collapse>
      )}
    </>
  );
};

export default NavListComponent;
