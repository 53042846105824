import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';

interface ExitModalProps {
  exitModalOpen: boolean;
  setExitModalOpen: (value: boolean) => void;
  closeAndCleanModal: () => void;
  message?: string | React.ReactNode;
  yesText?: string | React.ReactNode;
  noText?: string | React.ReactNode;
}
const ExitModalComponent: React.FC<ExitModalProps> = ({
  exitModalOpen,
  setExitModalOpen,
  closeAndCleanModal,
  message,
  yesText,
  noText
}) => {
  const { t } = useTranslation('qi');

  return (
    <Dialog
      // @ts-ignore
      maxWidth='sd'
      data-cy='exit-modal'
      open={exitModalOpen}
      onClose={() => setExitModalOpen(false)}
    >
      <DialogContent sx={{ pb: 0.5 }}>
        <Typography>{message || t('orders.logistic.close-modal.text')}</Typography>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
        <Button
          data-cy='exit-modal.no'
          variant='outlined'
          type='button'
          onClick={() => setExitModalOpen(false)}
        >
          {noText || t('orders.logistic.close-modal.no', { ns: 'qi' })}
        </Button>
        <Button data-cy='exit-modal.yes' variant='contained' onClick={closeAndCleanModal}>
          {yesText || t('orders.logistic.close-modal.yes', { ns: 'qi' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ExitModalComponent;
