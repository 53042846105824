import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export const ZUSTAND_BASE_MENU_STORE_KEY = 'baseMenuStore';

type State = {
  filter: {
    newest: boolean;
    open: boolean;
  };
};

type Params = {
  newest: boolean;
  open: boolean;
};

type Actions = {
  setFilter: (params: Params) => void;
  resetFilter: () => void;
};

const initialState: State = {
  filter: {
    newest: false,
    open: false
  }
};

export const useBaseMenuStore = create<State & Actions>()(
  persist(
    (set) => ({
      ...initialState,
      setFilter: (params) => {
        set({
          filter: {
            newest: params.newest,
            open: params.open
          }
        });
      },
      resetFilter: () => {
        set(initialState);
      }
    }),
    {
      name: ZUSTAND_BASE_MENU_STORE_KEY,
      storage: createJSONStorage(() => localStorage)
    }
  )
);
