import { AccessDenied } from '@/components/ui/access-denied.component';
import StyledDashboardMain from '../dashboard.style';
import { SubsciptionInfoBox } from '@/components/subscription-info-box/subsciption-info-box.component';
import { AppConnectInfo } from '@/components/app-connect-info/app-connect-info';
import { useUserQ as _useUserQ } from '@/lib/hooks/queries/useUser.query';
import { useAmazonAccountsQ as _useAmazonAccountsQ } from '@/lib/hooks/queries/useAmazonAccounts.query';
import { useGetTotalCostSavingsQuery as _useGetTotalCostSavingsQuery } from '@/lib/hooks/queries/useGetTotalCostSavings.query';
import { useSelectedAccPaidStatusQ as _useSelectedAccPaidStatusQ } from '@/lib/hooks/queries/useSelectedAccPaidStatus.query';
import { useAssistantCTAsQuery as _useAssistantCTAsQuery } from '../../assistant/queries/useAssistantCtas.query';
import dynamic from 'next/dynamic';

const AssistantWrapperComponent = dynamic(
  () =>
    import('@/components/assistant/assistant-wrapper.component').then(
      (mod) => mod.AssistantWrapperComponent
    ),
  { ssr: false }
);

interface DashboardLayoutMainProps {
  children?: React.ReactNode;
  showSubscriptionInfoBox: boolean;
  showChildrenComponents: boolean;
  showAccessDenied: boolean;
  useUserQ?: typeof _useUserQ;
  useAmazonAccountsQ?: typeof _useAmazonAccountsQ;
  useSelectedAccPaidStatusQ?: typeof _useSelectedAccPaidStatusQ;
  useGetTotalCostSavingsQuery?: typeof _useGetTotalCostSavingsQuery;
  useAssistantCTAsQuery?: typeof _useAssistantCTAsQuery;
}
export const DashboardLayoutMain: React.FC<DashboardLayoutMainProps> = ({
  children,
  useUserQ = _useUserQ,
  useAmazonAccountsQ = _useAmazonAccountsQ,
  useSelectedAccPaidStatusQ = _useSelectedAccPaidStatusQ,
  useGetTotalCostSavingsQuery = _useGetTotalCostSavingsQuery,
  useAssistantCTAsQuery = _useAssistantCTAsQuery,
  showSubscriptionInfoBox,
  showChildrenComponents,
  showAccessDenied
}) => {
  return (
    <>
      <AppConnectInfo />
      <AssistantWrapperComponent
        useUserQ={useUserQ}
        useAmazonAccountsQ={useAmazonAccountsQ}
        useSelectedAccPaidStatusQ={useSelectedAccPaidStatusQ}
        useGetTotalCostSavingsQuery={useGetTotalCostSavingsQuery}
        useAssistantCTAsQuery={useAssistantCTAsQuery}
      />
      <StyledDashboardMain data-cy='dashboard-layout-main'>
        {showSubscriptionInfoBox ? <SubsciptionInfoBox /> : null}
        {showChildrenComponents ? children : null}
        {showAccessDenied && !showChildrenComponents ? <AccessDenied /> : null}
      </StyledDashboardMain>
    </>
  );
};
