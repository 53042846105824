import { useMutation } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import { AppSession } from '@/lib/auth/auth.types';
import { CONFIG } from '@/lib/config/config';
import { processApiResponseReactQuery } from '@/lib/api/process-api-response-react-query.helper';
import { requestApi } from '@/lib/api/request-api.helper';

interface MutationInput {
  client_id?: string;
}

export const useMutationUpdateUserCookieConsent = () => {
  const url = `${CONFIG.API.ENDPOINT}${CONFIG.API.METHODS.USER}`;
  const session = useSession() as AppSession;

  const mutation = useMutation<unknown, unknown, MutationInput>({
    mutationFn: async ({ client_id }: MutationInput) => {
      try {
        const response = await requestApi.patch({
          url,
          session,
          body: {
            client_id
          }
        });
        const processedApi = await processApiResponseReactQuery({ response });
        return processedApi;
      } catch (error) {
        console.error(error);
      }
    }
  });

  return mutation;
};
