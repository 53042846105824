import { useEffect, useRef } from 'react';
import { Box } from '@mui/system';
import useChatStore from '@/lib/state-management/useChatStore';
import { ChatMessageComponent } from './chat-message/chat-message.component';
import { chatHistoryStyles } from '../style/chat.style';

export const ChatHistoryComponent: React.FC = () => {
  const chatHistory = useChatStore((state) => state.chatHistory);
  const isFetching = useChatStore((state) => state.isFetching);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isFetching || !chatHistory.length) return;
    if (messagesEndRef?.current && scrollContainerRef?.current) {
      const scrollHeight = messagesEndRef.current.offsetTop;
      scrollContainerRef.current.scrollTop = scrollHeight;
    }
  }, [chatHistory, isFetching]);

  return (
    <Box sx={chatHistoryStyles} data-cy='chat-history-component' ref={scrollContainerRef}>
      {chatHistory.map((message, index) => (
        <ChatMessageComponent key={index} message={message} />
      ))}
      {isFetching && (
        <ChatMessageComponent
          message={{ type: 'arthy', message: '' }}
          isFetching={true}
          timeout={600}
        />
      )}
      <div ref={messagesEndRef} />
    </Box>
  );
};
