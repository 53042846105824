import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import { AppSession } from '@/lib/auth/auth.types';
import { CONFIG } from '@/lib/config/config';

import { processApiResponseReactQuery } from '@/lib/api/process-api-response-react-query.helper';
import { requestApi } from '@/lib/api/request-api.helper';

import { QUERY_KEYS_STR } from '../query-keys.constants';

type Props = {
  session: AppSession;
};

export const fetchUserNotification = ({ session }: Props) => {
  if (session?.status !== 'authenticated') {
    throw new Error('Session must be provided and user should be authenticated');
  }

  const url = `${CONFIG.API.ENDPOINT}${CONFIG.API.METHODS.USER_NOTIFICATIONS}`;

  return (
    requestApi
      // @ts-ignore
      .get({ url, session })
      // @ts-ignore
      .then((response) => processApiResponseReactQuery({ response }))
  );
};

export const useUserNotificationQ = () => {
  const session = useSession({ required: true });

  const queryResult = useQuery({
    enabled: session.status === 'authenticated',
    staleTime: Infinity,
    cacheTime: Infinity,
    queryKey: [QUERY_KEYS_STR.USER.GET_USER_NOTIFICATIONS],
    // @ts-ignore
    queryFn: async () => fetchUserNotification({ session })
  });

  return { ...queryResult };
};
