export const ACTIONS = {
  SET_STATE: 'SET_STATE',
  ADD_PERMISSION: 'ADD_PERMISSION',
  REMOVE_PERMISSION: 'REMOVE_PERMISSION',
  SET_PERMISSION: 'SET_PERMISSION'
};

export const CUSTOM_ROLE_VALUE = 'custom';

export const GRID_COLUMNS = 13;

export const permissionOrder = [
  'recommendations',
  'main-dashboard',
  'dashboard',
  'ppc_reporting',
  'reviews',
  'product_costs'
];

export const businessInsidesKeysArray = ['main-dashboard', 'dashboard', 'ppc_reporting', 'reviews'];
