export type FeatureFlags =
  | 'ACCOUNT_TASK_COUNTER'
  | 'MAINTENANCE_INFO'
  | 'NEW_PRICING_PACKAGES'
  | 'PRODUCT_FEEDBACKS'
  | 'REPRICER'
  | 'PRODUCT_PAGE'
  | 'DELETE_ACCOUNT'
  | 'RECOMMENDATIONS_FILTER'
  | 'CHAT'
  | 'APP_RECONNECT_INFO'
  | 'VOICERECOGNITION'
  | 'STRIPE_PRICING_TABLE'
  | 'CHAT_WIDGETS';

export type FeatureFlagConfig = {
  [key in FeatureFlags]: string;
};
export const FEATURE_FLAGS: FeatureFlagConfig = {
  MAINTENANCE_INFO: 'maintenance_info',
  ACCOUNT_TASK_COUNTER: 'account_task_counter',
  NEW_PRICING_PACKAGES: 'new_pricing_packages',
  PRODUCT_FEEDBACKS: 'analyzed-returns-usecase',
  REPRICER: 'repricer',
  PRODUCT_PAGE: 'product-page',
  DELETE_ACCOUNT: 'delete-account',
  RECOMMENDATIONS_FILTER: 'recommendations-filter',
  CHAT: 'arthytalk',
  APP_RECONNECT_INFO: 'app-reconnect-info',
  VOICERECOGNITION: 'voicerecognition',
  CHAT_WIDGETS: 'arthytalkwidgets',
  STRIPE_PRICING_TABLE: 'stripe_pricing_table'
};
