import { ParsedUrlQuery } from 'querystring';

import { useGlobalStore } from '../state-management/useGlobalStore';

type ILinkObj = {
  [key: string]: {
    path: string;
  };
};

type LinkOptions = {
  exactMatch?: boolean;
  isAccountRoute?: boolean;
  generateUrl?: ({
    tab,
    path,
    token,
    asins
  }: {
    tab?: string;
    path: string;
    token?: string;
    asins?: [];
  }) => string;
  deserializeParams?: (
    query: ParsedUrlQuery,
    linkObj?: ILinkObj
  ) => {
    token: string | null;
    redirect: string | null;
    asins: [] | string[];
  };
  isPublic?: boolean;
};

type Link = {
  path: string;
  isActive: (router: { asPath: string }) => boolean;
  generateUrl: ({
    tab,
    path,
    token,
    asins
  }: {
    tab?: string;
    path: string;
    token?: string;
    asins?: [];
  }) => string;
  deserializeParams: (
    query: ParsedUrlQuery,
    linkObj?: ILinkObj
  ) => {
    token?: string | null;
    redirect?: string | null;
    asins?: [] | string[];
  };
};

const createLink = (pathname: string, options: LinkOptions = {}): Link => {
  const {
    generateUrl = ({ tab = '', path }) => `${path}${tab && tab.length > 0 ? `/${tab}` : ''}`,
    deserializeParams = (query) => ({ tab: query?.params?.[0] || null }),
    ...otherOptions
  } = options;

  const selectedAmazonAccountId = useGlobalStore.getState().selectedAmazonAccount?._id;

  return {
    ...otherOptions,
    path: options.isAccountRoute
      ? `/accounts/${selectedAmazonAccountId || 'main-account'}/${pathname}`
      : pathname,
    isActive: (router) => {
      if (!router) {
        console.error('No router passed to isActive()');
      }

      if (options.exactMatch === true) {
        return router.asPath === pathname;
      }
      return `${router.asPath}`.startsWith(pathname);
    },
    generateUrl,
    deserializeParams
  };
};
const generateDashbordTabUrl = (path: string, tab: string) => {
  const pathSegments = path.split('/');
  if (pathSegments.length <= 5) {
    return `${path}${tab && tab.length > 0 ? `/${tab}` : ''}`;
  } else {
    pathSegments[5] = tab;
    const newPath = pathSegments.join('/');
    return newPath;
  }
};
export const useLinkHandling = () => {
  const commonOptions: LinkOptions = { isPublic: true };

  const links = {
    LP_MODULE: createLink('/module', { exactMatch: false, ...commonOptions }),
    LP_MODULE_DASHBOARD: createLink('/module/dashboard', commonOptions),
    LP_MODULE_CHROME_EXTENSION: createLink('/module/chrome-extension', commonOptions),
    LP_MODULE_ASIN_EXPLORER: createLink('/module/asin-explorer', commonOptions),
    LP_MODULE_REVIEWS: createLink('/module/reviews', commonOptions),
    LP_MODULE_LISTING_CHECKER: createLink('/module/listing-checker', commonOptions),
    LP_AMAZONSPAPI: createLink('/amazon-sp-api', commonOptions),
    LP_CAREER: createLink('/career', commonOptions),
    INDEX: createLink('/', commonOptions),
    SETTLEMENTS: createLink('/settlements'),
    DASHBOARD: createLink('dashboard/profit', {
      exactMatch: false,
      isAccountRoute: true,
      ...commonOptions,
      generateUrl: ({ tab = '', path }) => generateDashbordTabUrl(path, tab)
    }),
    PPC_DASHBOARD: createLink('dashboard/ppc', {
      exactMatch: false,
      isAccountRoute: true,
      ...commonOptions,

      generateUrl: ({ tab = '', path }) => generateDashbordTabUrl(path, tab)
    }),
    PPC_DASHBOARD_REPORTING: createLink('dashboard/ppc-reporting', {
      exactMatch: false,
      isAccountRoute: true,
      ...commonOptions,

      generateUrl: ({ tab = '', path }) => generateDashbordTabUrl(path, tab)
    }),
    CAMPAIGNGROUP_SETUP: createLink('/ppc/setup'),
    IMPRINT: {
      path: {
        en: 'https://www.getarthy.com/legal#imprint',
        de: 'https://www.getarthy.com/de/rechtliches#impressum'
      }
    },
    PRIVACY: {
      path: {
        en: 'https://www.getarthy.com/legal#privacy',
        de: 'https://www.getarthy.com/de/rechtliches#datenschutz'
      }
    },
    TERMS: {
      path: {
        en: 'https://www.getarthy.com/legal#terms',
        de: 'https://www.getarthy.com/de/rechtliches#agb_1'
      }
    },
    ASINS_EXPLORER: createLink('/asin-explorer', {
      exactMatch: false,
      generateUrl: ({ token = '' }) =>
        `/asin-explorer${token && token.length > 0 ? `/${token}` : ''}`,
      deserializeParams: (query: ParsedUrlQuery) => {
        const { params } = query || {};
        return {
          token: params && params[0] ? params[0] : null
        };
      }
    }),
    LISTING_CHECKER: createLink('/listing-checker'),
    LOGISTICS: createLink('/logistics'),
    QI: createLink('/quality-inspection'),
    SIGN_UP: createLink('/auth/sign-up/01-create-account'),
    SIGN_UP_CREATE_ACCOUNT: createLink('/auth/sign-up/01-create-account'),
    SIGN_UP_ACCOUNT_CONFIRMATION: createLink('/auth/sign-up/02-account-confirmation'),
    SIGN_UP_CONNECT_AMAZON_ACCOUNT: createLink('/auth/sign-up/03-connect-amazon-account'),
    SIGN_UP_CONNECT_ADVERTISING_ACCOUNT: createLink('/auth/sign-up/04-connect-advertising-account'),
    SIGN_UP_BOOK_PACKAGE: createLink('/auth/sign-up/04-book-package'),
    SIGN_UP_INVITE_SHARING_ACTIVATE: createLink('/auth/sign-up/sharing-invite-confirmation'),
    SIGNUP_ACTIVATE: createLink('/auth/sign-up', {
      generateUrl: ({ token = '' }) =>
        `/auth/sign-up${token && token.length > 0 ? `/${token}` : ''}`,
      deserializeParams: (query: ParsedUrlQuery) => {
        const { token } = query;
        return {
          token: typeof token === 'string' ? token : null
        };
      }
    }),
    USER_RESEND_ACTIVATION: createLink('/auth/sign-up/resend-activation'),
    USER_RESEND_PASSWORD: createLink('/auth/sign-up/resend-password'),
    LOGIN: createLink('/auth/login', { ...commonOptions }),
    LOGIN_SSO: {
      ...createLink('/auth/login', { ...commonOptions }),
      generateUrl: ({ token = '' }, redirect: string | null = null) => {
        let pathSegment = '';
        if (token.length > 0) {
          pathSegment = `/${token}`;
          if (redirect && redirect.length > 0) {
            pathSegment = `${pathSegment}/${redirect}`;
          }
        }
        return `/auth/login${pathSegment}`;
      },
      deserializeParams: (query: ParsedUrlQuery, linkObj) => {
        const { params } = query || {};
        const redirectKey = params && params[1] ? params[1] : null;
        let redirect = null;
        if (redirectKey && linkObj[redirectKey] && linkObj[redirectKey].path) {
          // @ts-ignore
          redirect = linkObj[redirectKey].path;
        }
        return {
          token: params && params[0] ? params[0] : null,
          redirect
        };
      }
    },
    POST_LOGIN: createLink('/', { isPublic: false }),
    LOGOUT: createLink('/auth/logout'),
    RELOGIN: createLink('/auth/login'),
    REVIEWS_ASINS: createLink('dashboard/reviews', {
      isAccountRoute: true
    }),
    REVIEWS_LIST: createLink('reviews/read', {
      isAccountRoute: true,
      generateUrl: ({ asins = [] }) =>
        `/reviews/read${Array.isArray(asins) && asins.length > 0 ? `/${asins.join('::')}` : ''}`,
      deserializeParams: (query: ParsedUrlQuery) => {
        const { params } = query || {};
        const asins = params && params[0] ? `${params[0]}`.split('::') : null;
        return { asins: asins || [] };
      }
    }),

    SETTINGS: createLink('/settings'),
    SETTINGS_GENERAL: createLink('/settings/general'),
    SETTINGS_PLAN_AND_BILLING: createLink('/settings/plan-and-billing'),
    SETTINGS_DANGER_ZONE: createLink('/settings/danger-zone'),
    AMAZON_ACCOUNTS: createLink('/settings/amazon-accounts'),
    SETTINGS_SHARING: createLink('/settings/sharing'),
    ADD_AMAZON_ACC: createLink('/settings/amazon-accounts-new'),
    ADD_ADVERT_ACC: createLink('/settings/advertising-accounts-new'),
    SHARING: createLink('/sharing'),
    SUBSCRIPTION: createLink('/subscription'),
    CONFIRMATIONS: createLink('/confirmations'),
    ASSIGNMENTS: createLink('/assignments'),
    QUALITY_INSPECTION: createLink('/quality-inspection/book-qi'),
    QUALITY_INSPECTION_GET_QI: createLink('/quality-inspection/get-qi'),
    TRANSACTIONS: createLink('/transactions'),
    TRANSACTIONS_SUCCESS: createLink('/transactions/success'),
    BASE_DATA: createLink('base-data', {
      isAccountRoute: true
    }),
    ONBOARDING: createLink('onboarding', { isAccountRoute: true }),
    RECOMMENDATIONS: createLink('recommendations', {
      isAccountRoute: true
    }),
    AUTOMATIONS: createLink('automations', {
      isAccountRoute: true
    }),
    FIXCOSTS: createLink('base-data/fixcosts', {
      isAccountRoute: true
    }),
    PRODUCTS: createLink('base-data/products', {
      isAccountRoute: true
    }),
    ADMIN: createLink('/admin'),
    ADMIN_USER_DATA: createLink('/admin/client-data'),
    ADMIN_SETTINGS: createLink('/admin/settings'),
    ADMIN_KPI: createLink('/admin/kpi'),
    ADMIN_RECOMMENDATIONS: createLink('/admin/use-cases'),
    MAIN_DASHBOARD: createLink('dashboard', {
      isAccountRoute: true
    }),
    CHAT: createLink('chat', {
      isAccountRoute: true
    })
    // ACCOUNTS_ACCOUNT: {
    //   createLink('/accounts/5c7e72ebd8b543383f095d57'),
    //   generateAccountId: (accountId = '') => `/accounts/${accountId}`
    // }
  };

  return links;
};
