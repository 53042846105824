import { UserSharingPermissionAccess } from '@/types/user/sharing/user-sharing.type';
import { Cancel, CheckCircle, Error } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';

import { SUCCESS } from '@/lib/styles/palette.style';

import { permissionOrder } from './settings.constants';

// Helper metods to construct DataGrid
export const getStatusIcon = (account) => {
  if (account.confirmedMarketplace === false) {
    return <Error color='warning' />;
  }
  if (['new', 'renew', 'in_progress'].includes(account.status)) {
    return <CircularProgress sx={{ color: SUCCESS.main }} size='1.2rem' />;
  }
  if (['error', 'failed_init'].includes(account.status)) {
    return <Error color='error' />;
  }
  if (['disconnected', 'deleted'].includes(account.status)) {
    return <Cancel color='disabled' />;
  }
  return <CheckCircle sx={{ color: SUCCESS.main }} />;
};

export const getStatusColor = (status) => {
  if (['new', 'renew', 'in_progress'].includes(status)) {
    return SUCCESS.main;
  }
  if (['error', 'failed_init'].includes(status)) {
    return 'error.main';
  }
  if (['disconnected', 'deleted'].includes(status)) {
    return 'text.disabled'; // "disabled.main";
  }
  return SUCCESS.main; // "success.main"
};

export const disableNew = ({ name, mwsToken, sellerId }) =>
  name === '' || mwsToken === '' || sellerId === '';

export const disableEdit = ({
  oldName,
  newName,
  requiresMarketplaceConfirmation,
  defaultMarketplace
}) => (requiresMarketplaceConfirmation === true ? defaultMarketplace === '' : oldName === newName);

export const sortPermissionsArray = (array: UserSharingPermissionAccess[]) => {
  const sortedArray = permissionOrder.map((item) => {
    if (!array?.find((element) => element.name === item)) {
      return null;
    }
    return array?.find((element) => element.name === item);
  });
  return sortedArray?.filter((element) => element !== null);
};
