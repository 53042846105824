import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import { AppSession } from '@/lib/auth/auth.types';
import { CONFIG } from '@/lib/config/config';

import { processApiResponseReactQuery } from '@/lib/api/process-api-response-react-query.helper';
import { requestApi } from '@/lib/api/request-api.helper';

import { QUERY_KEYS_STR } from '@/lib/hooks/queries/query-keys.constants';

export const useUpdateUserNotifications = () => {
  const url = CONFIG.API.ENDPOINT + CONFIG.API.METHODS.USER;
  const queryClient = useQueryClient();
  const session = useSession({ required: true }) as AppSession;
  const queryKeyNotifications = [QUERY_KEYS_STR.USER.GET_USER_NOTIFICATIONS];

  return useMutation({
    mutationFn: async () =>
      requestApi
        //@ts-ignore
        .patch({ url, session, body: { notifications_seen: new Date().getTime() } })
        .then((response) => {
          const data = response as Response;
          return processApiResponseReactQuery({ response: data });
        }),
    onMutate: async () => {
      await queryClient.cancelQueries(queryKeyNotifications);

      const previousNotifications = queryClient.getQueryData(queryKeyNotifications);
      queryClient.setQueryData(queryKeyNotifications, () => []);

      return { previousNotifications };
    },

    // If the mutation fails, use the context we returned above
    onError: (_err, _newCtas, context) => {
      queryClient.setQueryData(queryKeyNotifications, context?.previousNotifications);
    }

    // Always refetch after error or success:
    // onSettled: () => {
    //   queryClient.invalidateQueries(queryKeyNotifications);
    // }
  });
};
