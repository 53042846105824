import React, { Fragment } from 'react';

import { Settings } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Link from 'next/link';

import { GREY } from '@/lib/styles/palette.style';

import ScrollbarComponent from '../../../scroll-bar/scroll-bar.component';
import { listItemWidth } from '../../base-menu.constants';
import { StyledMenuTitle, secondaryTypographyStyle } from '../../base-menu.style';
import { useGlobalStore } from '@/lib/state-management/useGlobalStore';
import { DropdownMenuItem } from './menu-item.component';
import { MenuStatusIcon } from '../../base-menu.helpers';
import { NoAccountFoundComponent } from '../no-account/base-menu-no-account.component';
import { MenuItemProps } from '../../base-menu.types';
import { useIsAutomationAccount } from '@/components/recommendations/helpers/useIsAutomationAccount';
import { BaseMenuTaskStatus } from './base-menu-task-status.component';
import { useLinkHandling } from '@/lib/hooks/useLinkHandling';

interface DropdownListComponentProps {
  filteredDataList: MenuItemProps[];
}

export const DropdownListComponent: React.FC<DropdownListComponentProps> = ({
  filteredDataList
}) => {
  const selectedAmazonAccount = useGlobalStore((state) => state.selectedAmazonAccount);
  const isAutomationAccount = useIsAutomationAccount();

  const URLS = useLinkHandling();
  return filteredDataList ? (
    filteredDataList.map((data) => {
      return (
        <Box key={data.type}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '8px 16px'
            }}
          >
            <StyledMenuTitle>{data.title}</StyledMenuTitle>
            <Link
              href={
                data.type === 'personal'
                  ? `${URLS.SETTINGS.path}/amazon-accounts`
                  : `${URLS.SETTINGS.path}/sharing`
              }
            >
              <Settings
                sx={{
                  color: GREY[400],
                  width: '16px',
                  height: '16px',
                  marginRight: 0.5
                }}
              />
            </Link>
          </Box>
          <ScrollbarComponent sx={{ maxHeight: listItemWidth }} height={listItemWidth}>
            {data && Array.isArray(data.list) && data.list.length > 0 ? (
              data.list.map((item) => {
                return (
                  <Fragment key={item._id}>
                    <DropdownMenuItem
                      item={item}
                      selected={item._id === selectedAmazonAccount?._id}
                    >
                      <Stack alignItems='start' spacing={1}>
                        <Stack>
                          <Typography
                            sx={{
                              fontSize: 14,
                              fontWeight: 'medium'
                            }}
                          >
                            {item.custom_account_name ? item.custom_account_name : item.name}{' '}
                            {!isAutomationAccount ? <MenuStatusIcon status={item.status} /> : null}
                          </Typography>
                          <Typography sx={secondaryTypographyStyle}>{item.region}</Typography>
                        </Stack>
                        <BaseMenuTaskStatus amazonAccountId={item._id} />
                      </Stack>
                    </DropdownMenuItem>
                  </Fragment>
                );
              })
            ) : (
              <NoAccountFoundComponent />
            )}
          </ScrollbarComponent>
        </Box>
      );
    })
  ) : (
    <NoAccountFoundComponent />
  );
};
