import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import { CONFIG } from '@/lib/config/config';
import { useGlobalStore } from '@/lib/state-management/useGlobalStore';

import { processApiResponseReactQuery } from '@/lib/api/process-api-response-react-query.helper';
import { requestApi } from '@/lib/api/request-api.helper';

import { QUERY_KEYS_CHAT } from './query-keys.constants';
import useChatStore from '@/lib/state-management/useChatStore';
import type { AskQuestionResponse, ChatWidgetState, InputTypes } from '../chat.types';
import type { AppSession } from '@/lib/auth/auth.types';
import type { AmazonAccount } from '@/types/user/user-amazon-account.type';

type RequestBody = {
  amazon_account_id: string | undefined;
  question: string | null;
  chat_history_session_id: string;
  components: ChatWidgetState[];
  question_input_type: InputTypes | undefined;
};

type Props = {
  session: AppSession;
  question?: string | null;
  chatComponent?: ChatWidgetState;
  chatComponentsStore: ChatWidgetState[];
  chatHistorySessionId: string;
  selectedAmazonAcc: AmazonAccount | null | undefined;
  inputType: InputTypes | undefined;
};

const fetchChatAskQuestion = async ({
  session,
  question,
  chatComponent,
  chatComponentsStore,
  chatHistorySessionId,
  selectedAmazonAcc,
  inputType
}: Props) => {
  if (!session) {
    throw new Error('Session is required for fetchChatAskQuestion');
  }
  const url = `${CONFIG.API.ENDPOINT}${CONFIG.API.METHODS.CHAT.POST_ASK_QUESTION}`;

  const chatComponentUpdate = (chatComponent) => {
    return chatComponentsStore.map((component) => {
      if (component.type === chatComponent.type) {
        return chatComponent;
      }
      return component;
    });
  };

  const requestBody: RequestBody = {
    amazon_account_id: selectedAmazonAcc?._id,
    question: null,
    chat_history_session_id: chatHistorySessionId,
    components: [],
    question_input_type: inputType
  };

  if (question && !chatComponent) {
    requestBody.question = question;
    requestBody.components = chatComponentsStore;
  }

  if (question && chatComponent) {
    const chatComponents = chatComponentUpdate(chatComponent);
    requestBody.components = chatComponents;
    requestBody.question = question;
  }

  if (!question && chatComponent) {
    const chatComponents = chatComponentUpdate(chatComponent);
    requestBody.components = chatComponents;
    requestBody.question = null;
  }

  const response = await requestApi.post({
    url,
    // @ts-ignore
    session,
    body: requestBody
  });

  return await processApiResponseReactQuery<AskQuestionResponse>({ response });
};

export const useChatAskQuestion = () => {
  const session = useSession({ required: true });
  const queryClient = useQueryClient();
  const selectedAmazonAcc = useGlobalStore((state) => state.selectedAmazonAccount);
  const queryKeyToInvalidateChat = [
    QUERY_KEYS_CHAT.POST_ASK_QUESTION,
    { activeAmazonAccountId: selectedAmazonAcc?._id, isPaidUser: true }
  ];
  const addChatMessage = useChatStore((state) => state.addChatMessage);
  const chatHistorySessionId = useChatStore((state) => state.chatHistorySessionId);
  const setIsFetching = useChatStore((state) => state.setIsFetching);
  const setChatWidgetState = useChatStore((state) => state.setChatWidgetState);
  const chatComponentsStore = useChatStore((state) => state.chatWidgetState);
  const setChatSessionVersion = useChatStore((state) => state.setChatSessionVersion);

  return useMutation({
    mutationFn: async ({
      question,
      chatComponent,
      inputType
    }: {
      question?: string | null;
      chatComponent?: ChatWidgetState;
      inputType?: InputTypes;
    }) => {
      setIsFetching(true);
      const data = await fetchChatAskQuestion({
        session,
        question,
        chatComponent,
        chatComponentsStore,
        chatHistorySessionId,
        selectedAmazonAcc,
        inputType
      });
      setIsFetching(false);

      if (data) {
        if (data.chat_session_version) {
          setChatSessionVersion(data.chat_session_version);
        }
        if (data.answer?.answer) {
          addChatMessage({
            type: 'arthy',
            message: data?.answer.answer,
            review: data?.review,
            notice: data?.notice,
            exampleQuestions: data?.example_questions || []
          });
        }
        if (data?.components) {
          setChatWidgetState(data.components);
        }
      }
      return data;
    },
    onError: () => {
      setIsFetching(false);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeyToInvalidateChat
      });
    }
  });
};
