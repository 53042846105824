import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import { CONFIG } from '@/lib/config/config';
import { useGlobalStore } from '@/lib/state-management/useGlobalStore';

import { processApiResponseReactQuery } from '@/lib/api/process-api-response-react-query.helper';
import { requestApi } from '@/lib/api/request-api.helper';

import { QUERY_KEYS_CHAT } from './query-keys.constants';

export const useAddChatReview = () => {
  const url = `${CONFIG.API.ENDPOINT}${CONFIG.API.METHODS.CHAT.POST_ADD_REVIEW}`;
  const session = useSession({ required: true });
  const queryClient = useQueryClient();
  const selectedAmazonAcc = useGlobalStore((state) => state.selectedAmazonAccount);
  const queryKeyToInvalidateChat = [
    QUERY_KEYS_CHAT.POST_ADD_REVIEW,
    { activeAmazonAccountId: selectedAmazonAcc?._id, isPaidUser: true }
  ];

  return useMutation({
    mutationFn: async (review: object) => {
      const response = await requestApi.post({
        url,
        // @ts-ignore
        session,
        body: review
      });

      const data = await processApiResponseReactQuery({ response });
      return data;
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeyToInvalidateChat
      });
    }
  });
};
