import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { GREY } from '@/lib/styles/palette.style';

export const StyledMenuTitle = styled(Typography)(() => ({
  fontSize: 12,
  color: GREY[600]
}));

export const secondaryTypographyStyle = {
  fontSize: 12,
  textTransform: 'uppercase'
};
