import { NavItem } from '@/types/navigation/navigation';
import { AmazonAccount } from '@/types/user/user-amazon-account.type';

import { useGlobalStore } from '@/lib/state-management/useGlobalStore';

import NavItemLink from './nav-item-link';
import { useUserQ } from '@/lib/hooks/queries/useUser.query';
import { useRecommendationTasksCountQuery } from '@/components/automations/automation-user/queries/useRecommendationTaskCount.query';
import { useMemo } from 'react';

interface NavItemProps {
  item: NavItem;
  open: boolean;
  isCollapse: boolean;
  onClick?: () => void;
}

const NavItemComponent: React.FC<NavItemProps> = ({ item, open, isCollapse }) => {
  const { data: userData } = useUserQ();
  const isPaidUser = userData?.isPaid ?? false;

  const selectedAmazonAccount = useGlobalStore(
    (state) => state.selectedAmazonAccount as AmazonAccount
  );

  const isOwnAccount = typeof selectedAmazonAccount?.permission === 'undefined';

  const hasUserAccessToRecommendations =
    isOwnAccount ||
    selectedAmazonAccount?.permission?.resolved?.some(
      (amzAccPermission) =>
        amzAccPermission.name === 'recommendations' ||
        amzAccPermission.name === 'recommendations_automated'
    );
  const isAllowedToSeeNotifications =
    item.notificationCounter && ((isOwnAccount && isPaidUser) || hasUserAccessToRecommendations);

  const { data } = useRecommendationTasksCountQuery();

  const { tasks } = useMemo(
    () =>
      data?.data.find((item) => item.amazon_account_id === selectedAmazonAccount._id) || {
        tasks: null
      },
    [data, selectedAmazonAccount]
  );

  const newTasksCount = tasks?.newCount || 0;

  return (
    <NavItemLink
      isCollapse={isCollapse}
      item={item}
      showNotificationCounter={isAllowedToSeeNotifications}
      numberOfRecommendations={newTasksCount}
      open={open}
    />
  );
};

export default NavItemComponent;
