import { IconButton, IconButtonProps, styled } from '@mui/material';

const varSmall = {
  hover: { scale: 1.1 },
  tap: { scale: 0.95 }
};

const varMedium = {
  hover: { scale: 1.09 },
  tap: { scale: 0.97 }
};

const varLarge = {
  hover: { scale: 1.08 },
  tap: { scale: 0.99 }
};

const IconButtonAnimate = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'size'
})<IconButtonProps>(({ size = 'medium' }) => ({
  ...(size === 'small' && {
    '&:hover': {
      transform: `scale(${varSmall.hover.scale})`
    },
    '&:active': {
      transform: `scale(${varSmall.tap.scale})`
    }
  }),
  ...(size === 'medium' && {
    '&:hover': {
      transform: `scale(${varMedium.hover.scale})`
    },
    '&:active': {
      transform: `scale(${varMedium.tap.scale})`
    }
  }),
  ...(size === 'large' && {
    '&:hover': {
      transform: `scale(${varLarge.hover.scale})`
    },
    '&:active': {
      transform: `scale(${varLarge.tap.scale})`
    }
  })
}));
export default IconButtonAnimate;
