import { useRecommendationTasksCountQuery } from '@/components/automations/automation-user/queries/useRecommendationTaskCount.query';
import { Stack } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { BaseMenuTaskStatusChip } from './base-menu-task-status-chip.component';
import { useMemo } from 'react';
import { useFlag } from '@unleash/proxy-client-react';
import { FEATURE_FLAGS } from '@/lib/feature-flags/feature-flags.constants';

interface BaseMenuTaskStatusProps {
  amazonAccountId: string;
}
export const BaseMenuTaskStatus: React.FC<BaseMenuTaskStatusProps> = ({ amazonAccountId }) => {
  const { t } = useTranslation('common');
  const { isLoading, data } = useRecommendationTasksCountQuery();
  const isAccountTaskCounterEnabled = useFlag(FEATURE_FLAGS.ACCOUNT_TASK_COUNTER);

  const { tasks } = useMemo(
    () =>
      data?.data.find((item) => item.amazon_account_id === amazonAccountId) || {
        tasks: null
      },
    [data, amazonAccountId]
  );

  if (!tasks || isLoading || !isAccountTaskCounterEnabled) return null;

  return (
    <Stack direction='row' spacing={1}>
      <BaseMenuTaskStatusChip
        count={tasks.newCount}
        label={t('components.tasks.new.title')}
        color='primary'
      />
      <BaseMenuTaskStatusChip
        count={tasks.openCount}
        label={t('components.tasks.open.title')}
        color='success'
      />
    </Stack>
  );
};
