import { User } from '@/types/user/user';
import { Box, Chip, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';

import { useUserQ as _useUserQ } from '@/lib/hooks/queries/useUser.query';
import { useModalStore } from '@/lib/state-management/useModalStore';

import SubscriptionsModal from '@/components/modals/subscriptions-modal/subscriptions-modal.component';
import { useAmazonAccountsQ as _useAmazonAccountsQ } from '@/lib/hooks/queries/useAmazonAccounts.query';
import StarsIcon from '@mui/icons-material/Stars';

interface TrialCountdownProps {
  useUserQ?: typeof _useUserQ;
  useAmazonAccountsQ?: typeof _useAmazonAccountsQ;
}

export const TrialCountdown: React.FC<TrialCountdownProps> = ({
  useUserQ = _useUserQ,
  useAmazonAccountsQ = _useAmazonAccountsQ
}) => {
  const { t } = useTranslation('common');
  const { data, isLoading } = useUserQ();
  const user = data as User;
  const { isLoading: isLoadingAmazonAccounts, data: amazonAccounts } = useAmazonAccountsQ();

  const hasNoAmazonAccount = amazonAccounts && amazonAccounts?.length === 0;
  const isUserInTrial = user?.userModules?.some((module) => module.key === 'trial');
  const isUserUnlimitedOrEnterprise = user?.userModules?.some((module) =>
    ['enterprise', 'unlimited'].includes(module.key)
  );
  const isUserBaseOrPremium = user?.userModules?.some((module) =>
    ['base', 'premium'].includes(module.key)
  );
  const isPremium = user?.userModules?.some((module) => module.key === 'premium');

  const hasOnlyUnfinishedAccounts = !amazonAccounts?.some(
    (account) => account?.synch_dashboard?.status === 'finished'
  );

  const setShowSubscriptionModal = useModalStore((state) => state.setShowSubscriptionModal);
  const getChipColor = (days: number) => {
    if ([null, 0].includes(days)) {
      return 'primary';
    } else if (days <= 7) {
      return 'warning';
    } else if (days <= 1) {
      return 'error';
    } else {
      return 'primary';
    }
  };

  const onClose = () => {
    setShowSubscriptionModal(false);
  };
  const renderContent = (trialDaysRemaining: number) => {
    if (trialDaysRemaining === 0) {
      if (isPremium) {
        return t('trialRemaining.info_premium', { days: trialDaysRemaining });
      }
      return t('trialRemaining.info_zero', { days: trialDaysRemaining });
    } else if (trialDaysRemaining === null) {
      return t('trialRemaining.info_premium', { days: trialDaysRemaining });
    } else if (trialDaysRemaining <= 7) {
      return t('trialRemaining.info', { days: trialDaysRemaining });
    } else if (trialDaysRemaining <= 1) {
      return t('trialRemaining.info_one', { days: trialDaysRemaining });
    } else {
      return t('trialRemaining.info', { days: trialDaysRemaining });
    }
  };

  if (
    isLoading ||
    !user ||
    (user.trial_remaining === null && !isUserBaseOrPremium) ||
    isLoadingAmazonAccounts ||
    hasNoAmazonAccount ||
    (isUserInTrial && hasOnlyUnfinishedAccounts)
  ) {
    return null;
  }

  if (isUserUnlimitedOrEnterprise) {
    return (
      <Chip
        data-cy='trial-countdown-premium-chip'
        variant={'filled'}
        sx={{
          backgroundColor: (theme) => theme.palette.warning.light,
          boxShadow: (theme) => `inset 0 0 4px 1px ${theme.palette.warning.main}`
        }}
        label={
          <Box sx={{ display: 'flex', alignContent: 'center' }}>
            <Typography sx={{ fontWeight: 'bold', mx: 1 }}>UNLIMITED</Typography>
            <StarsIcon />
          </Box>
        }
      />
    );
  }
  const trialDaysRemaining = user.trial_remaining as number;

  return (
    <>
      <SubscriptionsModal onClose={onClose} />
      <Chip
        data-cy='trial-countdown-button'
        variant={[null, 0].includes(trialDaysRemaining) ? 'filled' : 'outlined'}
        sx={{
          padding: (theme) => theme.spacing(1, 2, 1, 2)
        }}
        onClick={() => {
          useModalStore.setState({ showSubscriptionModal: true });
        }}
        color={getChipColor(trialDaysRemaining)}
        label={<span>{renderContent(trialDaysRemaining)}</span>}
      />
    </>
  );
};
