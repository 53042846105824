import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import { AppSession } from '@/lib/auth/auth.types';
import { CONFIG } from '@/lib/config/config';

import { processApiResponseReactQuery } from '@/lib/api/process-api-response-react-query.helper';
import { requestApi } from '@/lib/api/request-api.helper';

import { QUERY_KEYS_STR } from './query-keys.constants';

type Props = {
  session: AppSession;
  amazon_account_id: string;
};
export type UserPaidStatus = {
  isSelectedAccPaid: boolean;
};
export const fetchUserAmazonAccountPaidStatus = async ({ session, amazon_account_id }: Props) => {
  if (session?.status !== 'authenticated') {
    throw new Error('Session must be provided and user should be authenticated');
  }

  const url = `${CONFIG.API.ENDPOINT}${CONFIG.API.METHODS.USER_CHECK_PAID_STATUS}/${amazon_account_id}`;
  const response = await requestApi.get({ url, session });
  return await processApiResponseReactQuery<UserPaidStatus>({ response });
};

export const useSelectedAccPaidStatusQ = (amazon_account_id: string) => {
  const session = useSession({ required: true });

  const queryResult = useQuery({
    enabled: session.status === 'authenticated' && !!amazon_account_id,
    queryKey: [QUERY_KEYS_STR.USER.PAID_STATUS, { amazon_account_id }],
    // @ts-ignore
    queryFn: () => fetchUserAmazonAccountPaidStatus({ session, amazon_account_id })
  });
  if (queryResult.error) {
    return { ...queryResult, isSelectedAccPaid: false };
  }
  return { ...queryResult };
};
