import { Box, Link } from '@mui/material';
import React from 'react';
import { linkRegex } from '../../helpers/chat-helpers';

const parseAndRenderString = (inputString: string): React.ReactNode[] => {
  const parts: React.ReactNode[] = [];
  let lastIndex = 0;
  let match;

  while ((match = linkRegex.exec(inputString)) !== null) {
    // check if normal text is before the first link and push it to parts
    if (match.index > lastIndex) {
      parts.push(inputString.substring(lastIndex, match.index));
    }

    // check for markdown link and push it to parts
    if (match[0].startsWith('[')) {
      parts.push(
        <Link
          data-cy='chat-message-markdown-link'
          href={match[2]}
          key={match.index}
          target='_blank'
          rel='noopener noreferrer'
        >
          {match[1]}
        </Link>
      );
    } else {
      // if it is no markdown it should be a standard link and gets pushed to parts
      parts.push(
        <Link
          data-cy='chat-message-link'
          href={match[0]}
          key={match.index}
          target='_blank'
          rel='noopener noreferrer'
        >
          {match[0]}
        </Link>
      );
    }
    // lastIndex = last progressed index of the inputString
    lastIndex = match.index + match[0].length;
  }

  // check if there is still text content after the last link and push it to parts
  if (lastIndex < inputString.length) {
    parts.push(inputString.substring(lastIndex));
  }

  return parts;
};

interface ChatMessageWithLinkComponentProps {
  message: string;
}
export const ChatMessageWithLinkComponent: React.FC<ChatMessageWithLinkComponentProps> = ({
  message
}) => {
  const parsedContent = parseAndRenderString(message);
  return <Box data-cy='chat-full-text-message'>{parsedContent}</Box>;
};
