import { toast } from 'react-toastify';

import { MenuItem, TextField } from '@mui/material';
import { useTranslation } from 'next-i18next';

import { SelectAmazonAccountTooltip } from './select-amazon-account-tooltip.component';

interface SelectAmazonAccountTypeMenuProps {
  setTypeSelect: (newValue: string) => void;
  disabled?: boolean;
  typeSelect: string;
}

export const SelectAmazonAccountTypeMenu: React.FC<SelectAmazonAccountTypeMenuProps> = ({
  setTypeSelect,
  disabled,
  typeSelect
}) => {
  const { t } = useTranslation(['settings', 'common']);
  return (
    <TextField
      data-cy='amazon-account-name-input'
      select
      inputProps={{ MenuProps: { disableScrollLock: true } }}
      fullWidth
      helperText={<SelectAmazonAccountTooltip />}
      onClick={() => toast.dismiss()}
      disabled={disabled}
      onChange={(e) => {
        if (typeof e.target.value !== 'undefined') setTypeSelect(e.target.value);
      }}
      value={typeSelect}
      label={t('common:components.connected-amazon-accounts.list.type')}
    >
      <MenuItem disabled data-cy={`option-placeholder`}>
        <em>{t('common:components.connected-amazon-accounts.list.type')}</em>
      </MenuItem>

      <MenuItem value='getoauth' data-cy='item-seller-central-account'>
        {t('common:components.connected-amazon-accounts.list.amazon_account.title')}
      </MenuItem>
      <MenuItem value='getoauth-advertising-api' data-cy='item-advertising-account'>
        {t('common:components.connected-amazon-accounts.list.advertising_account.title')}
      </MenuItem>
    </TextField>
  );
};
