import type React from 'react';
import { Box } from '@mui/material';

import { RootStyle, SimpleBarStyle } from './scroll-bar.style';

interface ScrollbarProps {
  children: React.ReactNode;
  sx?: object;
  other?: object | number | string;
  height?: number | string;
}

const ScrollbarComponent: React.FC<ScrollbarProps> = ({ children, sx, ...other }) => {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

  if (isMobile) {
    return (
      <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <RootStyle>
      <SimpleBarStyle
        // @ts-ignore
        timeout={500}
        clickOnTrack={false}
        sx={sx}
        {...other}
      >
        {children}
      </SimpleBarStyle>
    </RootStyle>
  );
};

export default ScrollbarComponent;
