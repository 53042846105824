import { Box, Divider, Menu } from '@mui/material';
import { AddAccountMenuItem } from '../add-item/base-menu-add-item.component';
import { AutoCompleteComponent } from '../autocomplete/base-menu-auto-complete.component';
import { DropdownListComponent } from '../dropdown/base-menu-dropdown-list.component';
import { useAmazonAndSharedAccountsQ } from '@/lib/hooks/queries/useAmazonAndSharedAccounts.query';
import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { useMenu } from '../../providers/menu-provider.component';
import { useSearch } from '../../providers/search-provider.component';
import { MenuItemProps } from '../../base-menu.types';
import { MenuFilter } from '../filter/base-menu-filter.component';
import { useRecommendationTasksCountQuery } from '@/components/automations/automation-user/queries/useRecommendationTaskCount.query';
import { useBaseMenuStore } from '@/lib/state-management/useBaseMenuStore';
import { useFlag } from '@unleash/proxy-client-react';
import { FEATURE_FLAGS } from '@/lib/feature-flags/feature-flags.constants';
import LoadingSpinnerComponent from '@/components/ui/loading-spinner.component';
import { useIsAutomationAccount } from '@/components/recommendations/helpers/useIsAutomationAccount';

export const MenuComponent: React.FC = () => {
  const { t } = useTranslation('common');
  const { data: amazonAndSharedAccountsData = { ownAccounts: [], sharedAccounts: [] }, isLoading } =
    useAmazonAndSharedAccountsQ();

  const { ownAccounts, sharedAccounts } = amazonAndSharedAccountsData;

  const { isLoading: isLoadingRecommendationsTasksCounter, data: recommendationsTasksCounterData } =
    useRecommendationTasksCountQuery();
  const filter = useBaseMenuStore((state) => state.filter);
  const isFilterEnabled = useFlag(FEATURE_FLAGS.ACCOUNT_TASK_COUNTER);
  const isAutomationAccount = useIsAutomationAccount();

  const { anchorEl, setAnchorEl } = useMenu();
  const { search } = useSearch();

  const [dataList, setDataList] = useState<MenuItemProps[]>([]);

  useEffect(() => {
    const tempList: MenuItemProps[] = [
      {
        title: t('common:components.global-dropdown.personal-accounts', {
          defaultValue: ''
        }),
        type: 'personal',
        list: ownAccounts
      }
    ];
    if (sharedAccounts && sharedAccounts.length > 0) {
      tempList.push({
        title: t('common:components.global-dropdown.shared-accounts', {
          defaultValue: ''
        }),
        type: 'sharing',
        list: sharedAccounts
      });
    }
    setDataList(tempList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownAccounts, sharedAccounts, setDataList]);

  const filteredDataList = useMemo(() => {
    return dataList.map((data) => {
      const list = Array.isArray(data.list) ? data.list : [];

      let initialList = list
        .map((item) => {
          const match = recommendationsTasksCounterData?.data.find(
            (d) => d.amazon_account_id === item._id
          );
          return match ? { ...item, ...match } : { ...item };
        })
        .filter((account) => {
          if (!account) return false;
          const nameToCheck = account.custom_account_name ?? account.name;
          return nameToCheck.toLowerCase().includes(search.toLowerCase());
        });

      if (filter.newest || filter.open) {
        initialList = initialList.filter((account) => {
          const hasNewCount = filter.newest && account?.tasks?.newCount > 0;
          const hasOpenCount = filter.open && account?.tasks?.openCount > 0;
          return hasNewCount || hasOpenCount;
        });
      }

      return {
        ...data,
        list: initialList
      };
    });
    // eslint-disable-next-line
  }, [dataList, search, filter]);

  if (isLoading || isLoadingRecommendationsTasksCounter) {
    return <LoadingSpinnerComponent />;
  }

  return (
    <Menu
      id='lock-menu'
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      MenuListProps={{
        'aria-labelledby': 'lock-button',
        role: 'listbox'
      }}
      data-cy='menu-component'
    >
      <AutoCompleteComponent filteredDataList={filteredDataList} />
      <Divider />
      {isFilterEnabled && isAutomationAccount && (
        <Box>
          <MenuFilter />
          <Divider />
        </Box>
      )}
      <DropdownListComponent filteredDataList={filteredDataList} />
      <Divider />
      <AddAccountMenuItem />
    </Menu>
  );
};
