import { ComponentProps } from 'react';

import { MenuItem, TextField } from '@mui/material';

interface OptionType {
  value: string;
  userText: string;
}

interface SingleSelectProps {
  options: OptionType[];
  labelText: string;
  selectedValue: string;
  onChange: (value: string) => void;
  additionalOption?: OptionType;
  selectPlaceholder?: string;
  props?: ComponentProps<typeof TextField>;
}

const SingleSelect = ({
  options,
  labelText,
  selectedValue,
  onChange,
  additionalOption,
  selectPlaceholder,
  ...props
}: SingleSelectProps) => (
  <TextField
    select
    inputProps={{ MenuProps: { disableScrollLock: true } }}
    fullWidth
    label={labelText}
    onChange={(e) => {
      onChange(e.target.value);
    }}
    value={selectedValue}
    {...props}
  >
    {selectPlaceholder && (
      <MenuItem disabled data-cy={`option-placeholder`}>
        <em>{selectPlaceholder}</em>
      </MenuItem>
    )}

    {additionalOption && (
      <MenuItem value={additionalOption.value} disabled data-cy='additional-option'>
        - {additionalOption.userText} -
      </MenuItem>
    )}
    {options.map((option) => (
      <MenuItem key={option.value} value={option.value} data-cy={`option-${option.value}`}>
        {option.userText}
      </MenuItem>
    ))}
  </TextField>
);

export default SingleSelect;
