import { createContext, useState, useContext } from 'react';

type MenuContextProps = {
  anchorEl: HTMLElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
};
export const MenuContext = createContext<MenuContextProps | null>(null);

type MenuProviderProps = {
  children: React.ReactNode;
};
export const MenuProvider: React.FC<MenuProviderProps> = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return <MenuContext.Provider value={{ anchorEl, setAnchorEl }}>{children}</MenuContext.Provider>;
};

export const useMenu = (): MenuContextProps => {
  const context = useContext(MenuContext);
  if (!context) {
    throw new Error('useMenu must be used within a MenuProvider');
  }
  return context;
};
