import NavbarVerticalComponent from '../../navbar/navbar.component';
import { Box } from '@mui/material';
import { DashboardHeaderComponent } from '../dashboard-header.component';
import { DashboardLayoutMainWrapper } from './dashboard-layout-main-wrapper.component';
import { SnackbarProvider } from '../../snackbar/snackbar-provider.component';
import { useAmazonAccountsQ as _useAmazonAccountsQ } from '@/lib/hooks/queries/useAmazonAccounts.query';
import { useAssistantCTAsQuery as _useAssistantCTAsQuery } from '../../assistant/queries/useAssistantCtas.query';
import { useGetTotalCostSavingsQuery as _useGetTotalCostSavingsQuery } from '@/lib/hooks/queries/useGetTotalCostSavings.query';
import { useGlobalStore } from '@/lib/state-management/useGlobalStore';
import { useHydrationStore } from '@/lib/state-management/use-hydration-store.helper';
import { useRegisterRedirect as _useRegisterRedirect } from '@/lib/hooks/useRegisterRedirect';
import { useRouter } from 'next/router';
import { useSelectedAccPaidStatusQ as _useSelectedAccPaidStatusQ } from '@/lib/hooks/queries/useSelectedAccPaidStatus.query';
import { useState } from 'react';
import { useTracking as _useTracking } from '@/lib/hooks/useTracking';
import { useUserQ as _useUserQ } from '@/lib/hooks/queries/useUser.query';
import { ChatConversationLayoutComponent } from '@/components/chat/components/chat-layout/chat-conversation-layout.component';
import { ChatAvatarShowButton } from '@/components/chat/components/chat-layout/chat-avatar-show-button.component';
import { useOnboardingRedirect } from '@/lib/hooks/useOnboardingRedirect';
import { AbilityContext } from '@/lib/permissions/abilities/Can';
import { useAbility } from '@casl/react';
import { SUBJECTS } from '@/lib/permissions/abilities/abilities';
import { useIsOwnAmazonAccount } from '@/lib/hooks/useIsOwnAmazonAccount';

interface DashboardLayoutProps {
  children: React.ReactNode;
  useRegisterRedirect?: typeof _useRegisterRedirect;
  useTracking?: typeof _useTracking;
  useUserQ?: typeof _useUserQ;
  useAmazonAccountsQ?: typeof _useAmazonAccountsQ;
  useSelectedAccPaidStatusQ?: typeof _useSelectedAccPaidStatusQ;
  useGetTotalCostSavingsQuery?: typeof _useGetTotalCostSavingsQuery;
  useAssistantCTAsQuery?: typeof _useAssistantCTAsQuery;
}

// These _useRouter and _useUser are used to mock the useRouter and useUser hooks in the Cypress tests
const DashboardLayoutComponent: React.FC<DashboardLayoutProps> = ({
  children,
  useRegisterRedirect = _useRegisterRedirect,
  useTracking = _useTracking,
  useUserQ = _useUserQ,
  useAmazonAccountsQ = _useAmazonAccountsQ,
  useSelectedAccPaidStatusQ = _useSelectedAccPaidStatusQ,
  useGetTotalCostSavingsQuery = _useGetTotalCostSavingsQuery,
  useAssistantCTAsQuery = _useAssistantCTAsQuery
}) => {
  const [open, setOpen] = useState(true);
  const router = useRouter();
  useRegisterRedirect();
  useTracking();
  useOnboardingRedirect();

  const selectedAmazonAccount = useHydrationStore(
    useGlobalStore,
    (state) => state.selectedAmazonAccount
  );

  const isOwnAmazonAccount = useIsOwnAmazonAccount();
  const { asPath } = router;

  if (asPath.includes('main-account') && selectedAmazonAccount?._id) {
    const newAsPath = asPath.replace('main-account', selectedAmazonAccount?._id);
    router.replace(newAsPath, undefined, { shallow: true });
  }

  const ability = useAbility(AbilityContext);

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          minHeight: '100vh',
          gridTemplateColumns: 'min-content 1fr min-content',
          gridTemplateAreas: `"header header header"
                            "nav assistant chat-conversation"
                            "nav main chat-conversation"`,
          gridTemplateRows: 'min-content min-content 1fr',
          columnGap: 2,
          gridAutoFlow: 'row',
          gridAutoRows: 'auto',
          gridAutoColumns: 'auto',
          position: 'relative',
          backgroundColor: (theme) => theme.palette.grey[100]
        }}
      >
        <SnackbarProvider>
          <DashboardHeaderComponent onOpenSidebar={() => setOpen(true)} />
          <NavbarVerticalComponent isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
          <DashboardLayoutMainWrapper
            useUserQ={useUserQ}
            useAmazonAccountsQ={useAmazonAccountsQ}
            useSelectedAccPaidStatusQ={useSelectedAccPaidStatusQ}
            useGetTotalCostSavingsQuery={useGetTotalCostSavingsQuery}
            useAssistantCTAsQuery={useAssistantCTAsQuery}
          >
            {children}
          </DashboardLayoutMainWrapper>
          {ability.can('see', SUBJECTS.CHAT.name) && isOwnAmazonAccount && (
            <>
              <ChatAvatarShowButton />
              <ChatConversationLayoutComponent />
            </>
          )}
        </SnackbarProvider>
      </Box>
    </>
  );
};

export default DashboardLayoutComponent;
