import { CONFIG } from '@/lib/config/config';

import { ICONS } from './dashboard.style';
import dayjs from 'dayjs';
import { DateRangeShortcut } from '@/types/dashboard/dashboard.type';

export const MENU_OPTIONS = [
  {
    label: 'nav-bar:navigation.settings',
    linkTo: { de: CONFIG.URL.SETTINGS.path, en: CONFIG.URL.SETTINGS.path },
    icon: ICONS.settings,
    dataCy: 'settings-link'
  },
  {
    label: 'nav-bar:footer.terms',
    linkTo: {
      de: CONFIG.URL.TERMS.path.de,
      en: CONFIG.URL.TERMS.path.en
    },
    icon: ICONS.terms,
    target: '_blank',
    dataCy: 'terms-link'
  },
  {
    label: 'nav-bar:footer.privacy',
    linkTo: {
      de: CONFIG.URL.PRIVACY.path.de,
      en: CONFIG.URL.PRIVACY.path.en
    },
    icon: ICONS.privacy,
    target: '_blank',
    dataCy: 'privacy-link'
  },
  {
    label: 'nav-bar:footer.imprint',
    linkTo: {
      de: CONFIG.URL.IMPRINT.path.de,
      en: CONFIG.URL.IMPRINT.path.en
    },
    icon: ICONS.imprint,
    target: '_blank',
    dataCy: 'imprint-link'
  }
];

// export const MENU_OPTIONS_ORIGINAL = [
//   {
//     label: 'navigation.settings',
//     linkTo: CONFIG.URL.SETTINGS.path,
//     icon: ICONS.settings,
//   },
//   {
//     label: 'general.modules.settlements.title',
//     linkTo: CONFIG.URL.SHARING.path,
//     icon: ICONS.settlements
//   },
//   {
//     label: 'navigation.sharing',
//     linkTo: CONFIG.URL.SHARING.path,
//     icon: ICONS.sharing
//   },
//   {
//     label: 'navigation.confirmations',
//     linkTo: CONFIG.URL.CONFIRMATIONS.path,
//     icon: ICONS.confirmations
//   },
//   {
//     label: 'navigation.transactions',
//     linkTo: CONFIG.URL.TRANSACTIONS.path,
//     icon: ICONS.transactions
//   }
// ];

export const dateRangeShortcuts: DateRangeShortcut[] = [
  {
    key: 'today',
    defaultGrouping: 'daily',
    dates: () => ({ startDate: dayjs(), endDate: dayjs() })
  },
  {
    key: 'yesterday',
    defaultGrouping: 'daily',
    dates: () => ({
      startDate: dayjs().subtract(1, 'day'),
      endDate: dayjs().subtract(1, 'day')
    })
  },
  {
    key: 'day_before_yesterday',
    defaultGrouping: 'daily',
    dates: () => ({
      startDate: dayjs().subtract(2, 'days'),
      endDate: dayjs().subtract(2, 'days')
    })
  },
  {
    key: 'this_week',
    defaultGrouping: 'daily',
    dates: () => ({
      startDate: dayjs().startOf('week'),
      endDate: dayjs()
    })
  },
  {
    key: 'last_7_days',
    defaultGrouping: 'daily',
    dates: () => ({
      startDate: dayjs().subtract(6, 'days'),
      endDate: dayjs()
    })
  },
  {
    key: 'last_week',
    defaultGrouping: 'daily',
    dates: () => ({
      startDate: dayjs().subtract(1, 'week').startOf('week'),
      endDate: dayjs().subtract(1, 'week').endOf('week')
    })
  },
  {
    key: 'this_month',
    defaultGrouping: 'daily',
    dates: () => ({
      startDate: dayjs().startOf('month'),
      endDate: dayjs()
    })
  },
  {
    key: 'last_4_weeks',
    defaultGrouping: 'daily',
    dates: () => ({
      startDate: dayjs().subtract(27, 'days'),
      endDate: dayjs()
    })
  },
  {
    key: 'last_month',
    defaultGrouping: 'daily',
    dates: () => ({
      startDate: dayjs().subtract(1, 'month').startOf('month'),
      endDate: dayjs().subtract(1, 'month').endOf('month')
    })
  },
  {
    key: 'this_year',
    defaultGrouping: 'monthly',
    dates: () => ({
      startDate: dayjs().startOf('year'),
      endDate: dayjs()
    })
  },
  {
    key: 'last_12_months',
    defaultGrouping: 'monthly',
    dates: () => ({
      startDate: dayjs().subtract(11, 'month').startOf('month'),
      endDate: dayjs()
    })
  },
  {
    key: 'last_year',
    defaultGrouping: 'monthly',
    dates: () => ({
      startDate: dayjs().subtract(1, 'year').startOf('year'),
      endDate: dayjs().subtract(1, 'year').endOf('year')
    })
  }
];
