import { AutoAwesome, PublishedWithChanges } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { AutomationTooltipBadge } from './automation-tooltip-badge.component';
import { useRecommendationTasksCountQuery as _useRecommendationTasksCountQuery } from './queries/useRecommendationTaskCount.query';
import { useIsAutomationAccount as _useIsAutomationAccount } from '@/components/recommendations/helpers/useIsAutomationAccount';
import { FEATURE_FLAGS } from '@/lib/feature-flags/feature-flags.constants';
import { useFlag } from '@unleash/proxy-client-react';
interface AutomationUserTaskCounterProps {
  useRecommendationTasksCountQuery?: typeof _useRecommendationTasksCountQuery;
  useIsAutomationAccount?: typeof _useIsAutomationAccount;
}

export const AutomationUserTaskCounter: React.FC<AutomationUserTaskCounterProps> = ({
  useRecommendationTasksCountQuery = _useRecommendationTasksCountQuery,
  useIsAutomationAccount = _useIsAutomationAccount
}) => {
  const { data, isLoading } = useRecommendationTasksCountQuery();
  const { t } = useTranslation('common');
  const isAccountTaskCounterEnabled = useFlag(FEATURE_FLAGS.ACCOUNT_TASK_COUNTER);
  const isAutomationAccount = useIsAutomationAccount();
  if (isLoading || !isAutomationAccount || !data || !isAccountTaskCounterEnabled) {
    return null;
  }

  return (
    <Stack alignItems='center' spacing={2} direction='row' data-cy='automation-counter-container'>
      <AutomationTooltipBadge
        title={t('components.tasks.new.title')}
        tooltip={t('components.tasks.new.tooltip')}
        icon={<AutoAwesome />}
        count={data.meta.newOverall}
        color='primary'
      />
      <AutomationTooltipBadge
        title={t('components.tasks.open.title')}
        tooltip={t('components.tasks.open.tooltip')}
        icon={<PublishedWithChanges />}
        count={data.meta.openOverall}
        color='success'
      ></AutomationTooltipBadge>
    </Stack>
  );
};
