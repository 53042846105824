import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import { AppSession } from '@/lib/auth/auth.types';
import { CONFIG } from '@/lib/config/config';

import { processApiResponseReactQuery } from '@/lib/api/process-api-response-react-query.helper';
import { requestApi } from '@/lib/api/request-api.helper';

import { QUERY_KEYS_AUTOMATIONS } from './query-keys.constants';
import { GetAllAutomationsData } from '@/types/api/automations/get-all';

type Props = {
  session: AppSession;
  activeAmazonAccountId: string;
};

export const fetchAutomations = ({ session, activeAmazonAccountId }: Props) => {
  if (!session) {
    throw new Error('Session must be provided to fetch toggles');
  }
  if (!activeAmazonAccountId) {
    throw new Error('id of active amazon account must be provided to fetch toggles');
  }
  const url = `${CONFIG.API.ENDPOINT}${CONFIG.API.METHODS.AUTOMATIONS.GET_AUTOMATIONS}/${activeAmazonAccountId}`;
  return (
    requestApi
      // @ts-ignore
      .get({ url, session })
      .then((response) => processApiResponseReactQuery<GetAllAutomationsData>({ response }))
  );
};

export const useAutomationsQuery = ({
  isPaidUser = false,
  activeAmazonAccountId,
  isOwnAmazonAccount = false
}: {
  isPaidUser: boolean;
  activeAmazonAccountId: string;
  isOwnAmazonAccount: boolean;
}) => {
  const session = useSession({ required: true });
  const queryResult = useQuery({
    enabled: session.status === 'authenticated' && isOwnAmazonAccount,
    queryKey: [
      QUERY_KEYS_AUTOMATIONS.GET_AUTOMATIONS,
      { isPaidUser, activeAmazonAccountId, isOwnAmazonAccount }
    ],
    queryFn: async () => {
      return fetchAutomations({ session: session as AppSession, activeAmazonAccountId });
    },
    refetchOnMount: 'always'
  });

  return queryResult;
};
