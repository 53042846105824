import { useEffect, useRef, useState } from 'react';

import { Link } from '@mui/material';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import NavItemComponent from '../../nav-section-vertical/nav-section-item/nav-item.component';
import { getActive, isExternalLink } from '../../nav-section.helper';
import { PaperStyle } from './nav-list.style';
import NavHorizontalSubListComponent from './nav-sub-list.component';

const NavListComponent = ({ data, depth, hasChildren }) => {
  const menuRef = useRef(null);
  const { pathname, push } = useRouter();
  const currentRoute = usePathname();
  const isActive = getActive(currentRoute, data.path);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickItem = () => {
    if (!hasChildren) {
      push(data.path);
    }
  };

  return (
    <>
      {isExternalLink(data.path) ? (
        <Link href={data.path} target='_blank' rel='noopener' underline='none'>
          <NavItemComponent
            // @ts-ignore
            item={data}
            depth={depth}
            open={open}
            active={isActive}
          />
        </Link>
      ) : (
        <NavItemComponent
          // @ts-ignore
          item={data}
          depth={depth}
          open={open}
          active={isActive}
          ref={menuRef}
          onClick={handleClickItem}
          onMouseEnter={handleOpen}
          onMouseLeave={handleClose}
        />
      )}

      {hasChildren && (
        <PaperStyle
          open={open}
          anchorEl={menuRef.current}
          anchorOrigin={
            depth === 1
              ? { vertical: 'bottom', horizontal: 'left' }
              : { vertical: 'center', horizontal: 'right' }
          }
          transformOrigin={
            depth === 1
              ? { vertical: 'top', horizontal: 'left' }
              : { vertical: 'center', horizontal: 'left' }
          }
          PaperProps={{
            onMouseEnter: handleOpen,
            onMouseLeave: handleClose
          }}
        >
          <NavHorizontalSubListComponent data={data.children} depth={depth} />
        </PaperStyle>
      )}
    </>
  );
};

NavListComponent.propTypes = {
  data: PropTypes.object,
  depth: PropTypes.number,
  hasChildren: PropTypes.bool
};

export default NavListComponent;
