import PropTypes from 'prop-types';

import NavListComponent from './nav-list.component';

const NavVerticalSubListComponent = ({ data, depth }) => {
  return data.map((list) => (
    <NavListComponent
      key={list.title + list.path}
      data={list}
      depth={depth + 1}
      hasChildren={!!list.children}
    />
  ));
};

NavVerticalSubListComponent.propTypes = {
  data: PropTypes.array,
  depth: PropTypes.number
};

export default NavVerticalSubListComponent;
