import React, { createContext, useState } from 'react';

import { Alert, Snackbar } from '@mui/material';

type SnackbarContextType = {
  showSnackbar: boolean;
  setShowSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
};

export const SnackbarContext = createContext<SnackbarContextType>({
  showSnackbar: false,
  setShowSnackbar: (b) => b,
  setMessage: (message) => message
});

export const SnackbarProvider = ({ children }) => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [message, setMessage] = useState('');

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar, setShowSnackbar, setMessage }}>
      {children}

      {showSnackbar && (
        <Snackbar
          open={showSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={10000}
          onClose={handleClose}
        >
          <Alert severity='info' sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
      )}
    </SnackbarContext.Provider>
  );
};
