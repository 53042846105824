import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useLinkHandling } from '@/lib/hooks/useLinkHandling';
import { useAutomationsQuery } from '@/components/automations/queries/useAutomation.query';

export const useHideableRoute = () => {
  const router = useRouter();
  const URLS = useLinkHandling();

  const isHideableRoute = useMemo(() => {
    if (!router) {
      return false;
    }
    const HIDEABLE_ROUTES = [
      URLS.RECOMMENDATIONS.path,
      URLS.AUTOMATIONS.path,
      URLS.SUBSCRIPTION.path,
      URLS.SETTINGS.path,
      URLS.CHAT.path,
      'admin'
    ];

    // main dashboard path cannot be checked with includes, otherwise arthy will be removed from all dashboard pages (all paths contain the path from the main dashboard)
    return (
      HIDEABLE_ROUTES.some((route) => router.asPath.includes(route)) ||
      router.asPath === URLS.MAIN_DASHBOARD.path
    );
  }, [
    router,
    URLS.MAIN_DASHBOARD.path,
    URLS.RECOMMENDATIONS.path,
    URLS.AUTOMATIONS.path,
    URLS.SUBSCRIPTION.path,
    URLS.SETTINGS.path,
    URLS.CHAT.path
  ]);

  return isHideableRoute;
};

export const useActiveAutomationToggles = ({
  isSelectedAmazonAccountPaid,
  selectedAmazonAccountId,
  isOwnAmazonAccount
}) => {
  const { data: toggles } = useAutomationsQuery({
    isPaidUser: isSelectedAmazonAccountPaid,
    activeAmazonAccountId: selectedAmazonAccountId,
    isOwnAmazonAccount: !!isOwnAmazonAccount
  });

  const [activeAutomationToggles, setActiveAutomationToggles] = useState<string[]>([]);

  useEffect(() => {
    if (toggles) {
      const keysToIgnore = [
        'inbound_labels_trigger',
        'reviews_trigger',
        'negative_reviews_trigger',
        'fba_missing_inbound_trigger'
      ];

      const newActiveAutomationToggles = Object.keys(toggles).filter(
        (key) =>
          toggles[key]?.active &&
          !activeAutomationToggles.includes(key) &&
          !keysToIgnore.includes(key)
      );

      setActiveAutomationToggles((prevToggles) => [...prevToggles, ...newActiveAutomationToggles]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggles]);

  return activeAutomationToggles;
};
