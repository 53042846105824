import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import { AppSession } from '@/lib/auth/auth.types';
import { CONFIG } from '@/lib/config/config';

import { processApiResponseReactQuery } from '@/lib/api/process-api-response-react-query.helper';
import { requestApi } from '@/lib/api/request-api.helper';

import { QUERY_KEYS_ASSISTANT } from './query-keys.constants';
import { Ctas } from '../components/cta/types/ctas.type';

type Props = {
  session: AppSession;
  amazon_account_id: string;
  fetch?: string;
};

export const fetchCTAs = async ({ session, amazon_account_id, fetch }: Props) => {
  if (!session) {
    throw new Error('Session must be provided to fetchCTAs');
  }

  let url = `${CONFIG.API.ENDPOINT}${CONFIG.API.METHODS.ASSISTANT.CTAS}/${amazon_account_id}`;
  if (fetch) {
    url += `?fetch=${fetch}`;
  }

  return requestApi
    .get({
      url,
      session
    })
    .then((response) => {
      const data = response as Response;
      return processApiResponseReactQuery<Ctas>({ response: data });
    });
};

export const useAssistantCTAsQuery = ({
  isPaidUser = false,
  amazon_account_id,
  fetch,
  isOwnAmazonAccount = false
}: {
  isPaidUser: boolean;
  select?: (data: Ctas) => Ctas;
  amazon_account_id: string | undefined;
  fetch?: string;
  isOwnAmazonAccount: boolean;
}) => {
  const session = useSession();
  const GET_CTAS_KEY = [
    QUERY_KEYS_ASSISTANT.GET_CTAS,
    { isPaidUser, amazon_account_id, fetch, isOwnAmazonAccount }
  ];
  const queryResult = useQuery({
    enabled: session.status === 'authenticated' && isPaidUser,
    queryKey: GET_CTAS_KEY,
    queryFn: () => {
      if (!isPaidUser || !amazon_account_id || !isOwnAmazonAccount) {
        return [];
      }
      return fetchCTAs({ session: session as AppSession, amazon_account_id, fetch });
    }
  });

  const ctas = queryResult.data;

  let isCtaSetupAutomationEmailTriggerPending = false;
  let isCtaSetupAutomationEmailTriggerAccepted = false;
  let isCtaSetupAutomationRightsTriggerAccepted = false;
  let isSetupAutomationFinished = false;

  if (ctas) {
    const ctaSetupAutomationEmailTrigger = ctas.find(
      (cta) => cta.group_type === 'setup_automation_email_trigger'
    );

    isCtaSetupAutomationEmailTriggerPending =
      ctaSetupAutomationEmailTrigger?.status?.value === 'pending';

    isCtaSetupAutomationEmailTriggerAccepted =
      ctaSetupAutomationEmailTrigger?.status?.value === 'accepted';

    const ctaSetupAutomationRightsTrigger = ctas.find(
      (cta) => cta.group_type === 'setup_automation_rights_trigger'
    );

    isCtaSetupAutomationRightsTriggerAccepted =
      ctaSetupAutomationRightsTrigger?.status?.value === 'accepted';

    isSetupAutomationFinished =
      isCtaSetupAutomationEmailTriggerAccepted && isCtaSetupAutomationRightsTriggerAccepted;
  }

  return {
    ...queryResult,
    isCtaSetupAutomationEmailTriggerPending,
    isCtaSetupAutomationEmailTriggerAccepted,
    isCtaSetupAutomationRightsTriggerAccepted,
    isSetupAutomationFinished
  };
};
