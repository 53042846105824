import { useState } from 'react';

import { Avatar, Box, Divider, MenuItem, Stack, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

import getInitials from '@/lib/helpers/utils/getInitials';
import useIsMountedRef from '@/lib/hooks/useIsMountedRef';

import IconButtonAnimate from '../ui/icon-button-animate.style';
import MenuPopover from '../ui/menu-popover';
import { MENU_OPTIONS } from './dashboard.config';
import { ICONS } from './dashboard.style';
import { useUserQ } from '@/lib/hooks/queries/useUser.query';
import { useLinkHandling } from '@/lib/hooks/useLinkHandling';

const DashboardAccountPopoverComponent: React.FC = () => {
  const { t, i18n } = useTranslation('nav-bar');

  const { language } = i18n;
  const router = useRouter();

  const { data: user } = useUserQ();
  const isMountedRef = useIsMountedRef();

  const URLS = useLinkHandling();

  const [open, setOpen] = useState<Element | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      await router.push(URLS.LOGOUT.path);
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      // enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        data-cy='account-popover'
        sx={{
          p: 0
        }}
      >
        <Avatar>{getInitials(user?.username)}</Avatar>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        disabledArrow
        disableScrollLock
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          width: 'fit-content',
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75
          }
        }}
        data-cy='account-dropdown'
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant='subtitle2' noWrap>
            {user?.username}
          </Typography>
          <Typography variant='body2' sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <NextLink
              key={option.label}
              href={option.linkTo[language]}
              target={option.target}
              passHref
              data-cy={option.dataCy}
            >
              <MenuItem key={option.label} onClick={handleClose}>
                <Box
                  component='div'
                  sx={{
                    display: 'flex',
                    width: '26px',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {option.icon}
                </Box>
                {t(option.label, {
                  defaultValue: 'Settings'
                })}
              </MenuItem>
            </NextLink>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem data-cy='user-logout' onClick={handleLogout} sx={{ m: 1 }}>
          <Box component='div' sx={{ width: '26px', display: 'flex', justifyContent: 'center' }}>
            {ICONS.logout}
          </Box>
          {t('navigation.logout')}
        </MenuItem>
      </MenuPopover>
    </>
  );
};

export default DashboardAccountPopoverComponent;
