import { useEffect } from 'react';

import { getCookie } from 'cookies-next';

import { useMutationUpdateUserCookieConsent } from '@/components/cookie-consent/update-user-cookie-consent.mutation';

import { useUserQ } from './queries/useUser.query';
import { AppSession } from '../auth/auth.types';
import { useSession } from 'next-auth/react';

// This hook should be used only when the user is already authenticated, because
// it user the userUserQ query to get the user from the database,
// and that query is only available when the user is authenticated.
export const useTracking = () => {
  const useUpdateUserMutation = useMutationUpdateUserCookieConsent();
  const { data: user } = useUserQ();
  const session = useSession() as AppSession;
  const gaCookie = getCookie('_ga');

  // added function where ga_cookie gets enabled when accepting marketing
  useEffect(() => {
    if (!user) return;
    if (session.status !== 'authenticated') return;

    if (!gaCookie) {
      // if there is no ga cookie, we need to check if user already has client_id
      // delete client_id if it exists
      if (user.client_id && user.client_id !== '') {
        useUpdateUserMutation.mutate({ client_id: '' });
      }
    }
    if (gaCookie) {
      const shortGaCookie = (gaCookie as string).replace(/^GA1\.1\./, '');
      if (user.client_id !== shortGaCookie) {
        useUpdateUserMutation.mutate({ client_id: shortGaCookie });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, gaCookie, session]);
};
