import Image from 'next/image';
import PropTypes from 'prop-types';

const SvgIconStyle = ({ src, sx }) => (
  <Image
    alt=''
    src={src}
    width={24}
    height={24}
    blurDataURL='/assets/no-image.svg'
    style={{
      ...sx
    }}
  />
);

SvgIconStyle.propTypes = {
  src: PropTypes.string.isRequired,
  sx: PropTypes.object
};

export default SvgIconStyle;
