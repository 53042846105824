import { toast } from 'react-toastify';

import { Launch } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'next-i18next';

interface SettingsAmazonAccountConnectFormContentActionsProps {
  isSourceAmazonAccount: boolean;
  disabledConfirmAccountSource: boolean;
  disabledConfirmAccount: boolean;
  typeSelect: string;
  confirmedMarketplace: boolean;
  isSignUp?: boolean;
}

export const SettingsAmazonAccountConnectFormContentActions: React.FC<
  SettingsAmazonAccountConnectFormContentActionsProps
> = ({
  isSourceAmazonAccount,
  disabledConfirmAccountSource,
  disabledConfirmAccount,
  confirmedMarketplace,
  typeSelect
}) => {
  const { t } = useTranslation(['settings', 'common']);
  const buttonLabel = () => {
    if (isSourceAmazonAccount) {
      if (!confirmedMarketplace) {
        return t('common:components.connected-amazon-accounts.form.setMarketplace');
      } else {
        return t('common:components.connected-amazon-accounts.form.update');
      }
    }
    return t('common:components.connected-amazon-accounts.form.create');
  };
  return isSourceAmazonAccount ? (
    <Button
      data-cy='confirm-button-amazon-account-source'
      variant='contained'
      type='submit'
      onClick={() => toast.dismiss()}
      disabled={disabledConfirmAccountSource}
    >
      {buttonLabel()}
    </Button>
  ) : (
    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
      <Button
        data-cy='confirm-button-amazon-account'
        variant='contained'
        type='submit'
        disabled={disabledConfirmAccount}
        onClick={() => toast.dismiss()}
        endIcon={<Launch />}
      >
        {t(
          typeSelect === 'getoauth'
            ? 'common:components.connected-amazon-accounts.form.connectSellerCentral'
            : 'common:components.connected-amazon-accounts.form.connectAdvertisingApi'
        )}
      </Button>
    </Box>
  );
};
