import SettingsAmazonAccountConnectFormComponent from '../../../settings/settings-amazon-accounts/settings-amazon-account-connect-form/settings-amazon-account-connect-form.component';

export const AddAccountMenuItem: React.FC = () => {
  return (
    <SettingsAmazonAccountConnectFormComponent
      sx={{
        width: '100%'
      }}
    />
  );
};
