import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import { AppSession } from '@/lib/auth/auth.types';
import { CONFIG } from '@/lib/config/config';

import { processApiResponseReactQuery } from '@/lib/api/process-api-response-react-query.helper';
import { requestApi } from '@/lib/api/request-api.helper';
import { QUERY_KEYS_STR } from '@/lib/hooks/queries/query-keys.constants';

interface AccountActionMutation {
  selected: string;
  accountName?: string;
  defaultMarketplace?: string;
  status?: string;
}
export const useAmazonAccountUpdateMutation = () => {
  const session = useSession({ required: true }) as AppSession;
  const queryClient = useQueryClient();
  const queryKeyToInvalidate = [QUERY_KEYS_STR.AMAZON_ACCOUNTS.GET_AMAZON_ACCOUNTS_GET_ALL];
  const queryKeyToInvalidateAmazonAccounts = [QUERY_KEYS_STR.AMAZON_ACCOUNTS.GET_AMAZON_ACCOUNTS];
  return useMutation({
    mutationFn: async ({
      selected,
      accountName,
      defaultMarketplace,
      status
    }: AccountActionMutation) => {
      const url = `${CONFIG.API.ENDPOINT}${CONFIG.API.METHODS.USER_AMAZON_ACC}/${selected}`;
      requestApi
        .patch({
          url,
          session,
          body: {
            name: accountName,
            defaultMarketplace,
            status
          }
        })
        .then(async (response) => {
          const data = response as Response;

          return processApiResponseReactQuery({ response: data });
        });
    },
    onSuccess: () => {
      console.log('onSuccess');
      queryClient.invalidateQueries({
        queryKey: queryKeyToInvalidate
      });
      queryClient.invalidateQueries({
        queryKey: queryKeyToInvalidateAmazonAccounts
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeyToInvalidate
      });
      queryClient.invalidateQueries({
        queryKey: queryKeyToInvalidateAmazonAccounts
      });
    },
    onError: (error) => {
      console.log(error);
    }
  });
};
