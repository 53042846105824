import { SUCCESS, ERROR, GREY } from '@/lib/styles/palette.style';
import { AmazonAccountStatus } from '@/types/user/user-amazon-account.type';
import { Circle } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'next-i18next';

const renderColor = (status: AmazonAccountStatus) => {
  switch (status) {
    case 'new':
    case 'renew':
    case 'in_progress':
    case 'finished':
      return SUCCESS.light;
    case 'failed_init':
    case 'error':
      return ERROR.light;
    case 'disconnected':
      return GREY[400];
  }
};

const renderText = (status: AmazonAccountStatus) => {
  switch (status) {
    case 'new':
    case 'renew':
    case 'in_progress':
    case 'finished':
      return 'active';
    case 'failed_init':
    case 'error':
      return 'error';
    case 'disconnected':
      return 'disconnected';
  }
};

export const MenuStatusIcon = ({ status }) => {
  const { t } = useTranslation('common');

  return (
    <Tooltip
      title={`${t(`common:components.global-dropdown.status.${renderText(status)}`, {
        defaultValue: ''
      })}`}
      placement='right'
      arrow
    >
      <Circle
        fontSize='small'
        sx={{
          height: 8,
          width: 8,
          color: renderColor(status)
        }}
      />
    </Tooltip>
  );
};
