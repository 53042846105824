import { Box, Typography } from '@mui/material';

import { PRIMARY } from '@/lib/styles/palette.style';

interface CtaItemBaseContainerProps {
  title: string | React.ReactNode;
  subtitle: string | React.ReactNode;
  cta?: React.ReactNode;
  dataCy: string;
  children?: React.ReactNode;
}
export const CtaItemBaseContainer: React.FC<CtaItemBaseContainerProps> = ({
  title,
  subtitle,
  cta,
  dataCy,
  children
}) => {
  return (
    <Box data-cy={dataCy}>
      {title && (
        <Typography
          data-cy='assistant-title'
          mb={1}
          sx={{
            fontWeight: 'bold',
            color: PRIMARY.main
          }}
        >
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography mb={1} data-cy='assistant-subtitle'>
          {subtitle}
        </Typography>
      )}
      {children || cta}
    </Box>
  );
};
