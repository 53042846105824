import React from 'react';
import {
  MenuList,
  FormControl,
  FormGroup,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import { BpCheckbox } from '../checkbox/checkbox.component';

import { useBaseMenuStore } from '@/lib/state-management/useBaseMenuStore';
import { useTranslation } from 'next-i18next';
import { ExpandMore } from '@mui/icons-material';
import { GREY } from '@/lib/styles/palette.style';

export const MenuFilter = () => {
  const { t } = useTranslation('common');
  const setFilter = useBaseMenuStore((state) => state.setFilter);
  const filter = useBaseMenuStore((state) => state.filter);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({
      ...filter,
      [event.target.name]: event.target.checked
    });
  };

  return (
    <MenuList sx={{ padding: 0 }}>
      <Accordion
        square
        sx={{ boxShadow: 'none' }}
        slotProps={{ transition: { unmountOnExit: true } }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMore
              sx={{
                color: GREY[400]
              }}
            />
          }
          sx={{
            '& .Mui-expanded': {
              margin: '8px 0'
            },
            width: '100%',
            fontSize: 12,
            color: GREY[600]
          }}
        >
          {t('components.global-dropdown.filter')}
        </AccordionSummary>
        <AccordionDetails>
          <FormControl sx={{ width: '100%' }} component='fieldset' variant='standard'>
            <FormGroup>
              <FormControlLabel
                control={
                  <BpCheckbox
                    checked={filter.newest || false}
                    onChange={handleChange}
                    name='newest'
                  />
                }
                label={
                  <span style={{ fontSize: 14, color: GREY[600] }}>
                    {t('components.global-dropdown.newest')}
                  </span>
                }
              />
              <FormControlLabel
                control={
                  <BpCheckbox checked={filter.open || false} onChange={handleChange} name='open' />
                }
                label={
                  <span style={{ fontSize: 14, color: GREY[600] }}>
                    {t('components.global-dropdown.open')}
                  </span>
                }
              />
            </FormGroup>
          </FormControl>
        </AccordionDetails>
      </Accordion>
    </MenuList>
  );
};
