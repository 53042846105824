import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import { AppSession } from '@/lib/auth/auth.types';
import { CONFIG } from '@/lib/config/config';

import { processApiResponseReactQuery } from '@/lib/api/process-api-response-react-query.helper';
import { requestApi } from '@/lib/api/request-api.helper';

import { QUERY_KEYS_STR } from './query-keys.constants';

type Props = {
  session: AppSession;
};

export const fetchTotalCostSavings = async ({ session }: Props) => {
  if (!session) {
    throw new Error('Session must be provided to getTotalCostSavings');
  }
  const url = `${CONFIG.API.ENDPOINT}${CONFIG.API.METHODS.RECOMMENDATIONS.GET_TOTAL_SAVINGS}`;
  const response = await requestApi.get({ url, session });
  return await processApiResponseReactQuery({ response });
};

export const useGetTotalCostSavingsQuery = () => {
  const session = useSession({ required: true });
  const queryResult = useQuery({
    enabled: session.status === 'authenticated',
    queryKey: [QUERY_KEYS_STR.GET_TOTAL_COST_SAVINGS],
    queryFn: () => fetchTotalCostSavings({ session: session as AppSession })
  });

  return queryResult;
};

// useEffect(() => {
//   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//   // @ts-ignore
//   callApi(
//     `${CONFIG.API.ENDPOINT}${CONFIG.API.METHODS.RECOMMENDATIONS.GET_TOTAL_SAVINGS}`,
//     'get',
//     {
//       errorNotification: true,
//       onSuccess: (responseData: any) => {
//         setTotalCostSavings(responseData?.savings);
//       }
//     }
//   );
// }, [callApi]);
