import { Chip, Stack, Typography } from '@mui/material';

interface BaseMenuTaskStatusChipProps {
  count: number;
  label: string;
  color: 'success' | 'primary';
}

export const BaseMenuTaskStatusChip: React.FC<BaseMenuTaskStatusChipProps> = ({
  count,
  label,
  color
}) => {
  if (!count || count === 0) return null;
  return (
    <Chip
      size='small'
      variant='outlined'
      color={color}
      label={
        <Stack direction='row' spacing={0.5}>
          <Typography variant='inherit' sx={{ fontWeight: 'bold' }}>
            {count}
          </Typography>
          <Typography variant='inherit'>{label}</Typography>
        </Stack>
      }
    />
  );
};
