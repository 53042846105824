import { Check, Close } from '@mui/icons-material';
// import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Grid, Typography } from '@mui/material';

interface PricingSectionFeatureListProps {
  features: {
    benefits?: string[];
    downsides?: string[];
    pricing_info?: string[];
  };
}
const PricingSectionFeatureListNew: React.FC<PricingSectionFeatureListProps> = ({ features }) => {
  return (
    <Box data-cy='pricing-section.feature-list' my={2}>
      <Grid data-cy='pricing-section.feature-list.list' container rowSpacing={1}>
        {features?.benefits &&
          features.benefits.map((feature) => (
            <Grid
              item
              xs={12}
              key={feature}
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row'
              }}
            >
              <Check
                sx={{
                  width: 20,
                  height: 20,
                  mr: 1,
                  borderRadius: 100,
                  backgroundColor: 'success.light',
                  color: 'success.dark'
                }}
              />
              <Typography variant='body2' component='p' sx={{ color: 'text.primary' }}>
                {feature}
              </Typography>
            </Grid>
          ))}
        {features?.downsides &&
          features.downsides.map((feature) => (
            <Grid
              item
              xs={12}
              key={feature}
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row'
              }}
            >
              <Close
                sx={{
                  width: 20,
                  height: 20,
                  mr: 1,
                  borderRadius: 100,
                  backgroundColor: 'error.light',
                  color: 'error.dark'
                }}
              />
              <Typography variant='body2' component='p' sx={{ color: 'text.primary' }}>
                {feature}
              </Typography>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};
export default PricingSectionFeatureListNew;
