import { Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { GREY } from '@/lib/styles/palette.style';

interface ChatMessageNoticeComponentProps {
  notice?: string;
}

export const ChatMessageNoticeComponent: React.FC<ChatMessageNoticeComponentProps> = ({
  notice
}) => {
  if (!notice) return null;

  return (
    <Box sx={{ position: 'absolute', right: 4, top: 4 }} data-cy='chat-message-notice-component'>
      <Tooltip title={notice} placement='top-end'>
        <InfoIcon sx={{ width: '18px', height: '18px', color: GREY[600] }} />
      </Tooltip>
    </Box>
  );
};
