import { Menu } from '@mui/icons-material';
import { Box, Stack, Toolbar } from '@mui/material';

import { useAmazonAndSharedAccountsQ as _useAmazonAndSharedAccountsQ } from '@/lib/hooks/queries/useAmazonAndSharedAccounts.query';
import { useSelectedAccPaidStatusQ as _useSelectedAccPaidStatusQ } from '@/lib/hooks/queries/useSelectedAccPaidStatus.query';
import useResponsive from '@/lib/hooks/useResponsive';

import { BaseMenu } from '../base-menu/base-menu.component';
import IconButtonAnimate from '../ui/icon-button-animate.style';
import Logo from '../ui/logo';
import { TrialCountdown } from '../ui/trial-countdown/trial-countdown.component';
import DashboardAccountPopover from './dashboard-account-popover.component';
import LanguagePopover from './dashboard-language-popover.component';
import NotificationsPopover from './dashboard-notification-popover.component';
import { useUserQ as _useUserQ } from '@/lib/hooks/queries/useUser.query';
import { AutomationUserTaskCounter } from '../automations/automation-user/automation-user-task-counter.component';

interface DashboardHeaderComponentProps {
  onOpenSidebar: () => void;
  useAmazonAndSharedAccountsQ?: typeof _useAmazonAndSharedAccountsQ;
  useSelectedAccPaidStatusQ?: typeof _useSelectedAccPaidStatusQ;
  useUserQ?: typeof _useUserQ;
}

export const DashboardHeaderComponent: React.FC<DashboardHeaderComponentProps> = ({
  useAmazonAndSharedAccountsQ = _useAmazonAndSharedAccountsQ,
  useSelectedAccPaidStatusQ = _useSelectedAccPaidStatusQ,
  useUserQ = _useUserQ,
  onOpenSidebar
}) => {
  const isDesktop = useResponsive('up', 'lg');
  return (
    <Toolbar
      sx={{
        px: { lg: 5 },
        borderBottom: '1px solid #eaeaea',
        backgroundColor: 'white',
        gridArea: 'header',
        position: 'sticky',
        zIndex: 1200,
        top: 0,
        maxHeight: 64
      }}
    >
      {!isDesktop && (
        <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
          <Menu />
        </IconButtonAnimate>
      )}

      <Stack direction='row' spacing={2} alignItems='center'>
        <Logo />
        <BaseMenu
          useAmazonAndSharedAccountsQ={useAmazonAndSharedAccountsQ}
          useSelectedAccPaidStatusQ={useSelectedAccPaidStatusQ}
        />
        <AutomationUserTaskCounter />
      </Stack>

      <Box sx={{ flexGrow: 1 }} />
      <Stack direction='row' alignItems='center' spacing={{ xs: 0.5, sm: 2 }}>
        <TrialCountdown />
        <LanguagePopover useUserQ={useUserQ} />
        <NotificationsPopover />
        <DashboardAccountPopover />
      </Stack>
    </Toolbar>
  );
};
