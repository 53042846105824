export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 64,
  DASHBOARD_DESKTOP_MIN_HEIGHT: 64,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 64
};

export const NAVBAR = {
  BASE_WIDTH: 240,
  DASHBOARD_WIDTH: 240,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
  ASSISTANT_PADDING: 220
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20
};

export const SIDEBAR_WIDTH = 380;

export const MAIN_CONTAINER = {
  PADDING: {
    LEFT: 16,
    RIGHT: 16
  }
};
