import { listItemWidth } from '../../base-menu.constants';
import { MenuItem } from '@mui/material';
import { useGlobalStore } from '@/lib/state-management/useGlobalStore';
import { useLinkHandling } from '@/lib/hooks/useLinkHandling';
import { useMenu } from '../../providers/menu-provider.component';
import { useRouter } from 'next/router';

import type { AmazonAccount } from '@/types/user/user-amazon-account.type';
import type { ReactNode } from 'react';
import { useUpdateUserSelectedAmazonAccountMutation } from '@/lib/queries/user/mutation/use-update-user.mutation';

interface DropdownMenuItemProps {
  children: ReactNode;
  selected: boolean;
  item: AmazonAccount;
}

export const DropdownMenuItem: React.FC<DropdownMenuItemProps> = ({ children, selected, item }) => {
  const router = useRouter();
  const { setAnchorEl } = useMenu();
  const URLS = useLinkHandling();

  const setAmazonAccount = useGlobalStore((state) => state.setAmazonAccount);
  const selectedAmazonAccount = useGlobalStore((state) => state.selectedAmazonAccount);

  const updateUserSelectedAmazonAccount = useUpdateUserSelectedAmazonAccountMutation();
  const handleMenuItemClick = (item: AmazonAccount) => {
    if (!item._id) {
      console.error('selectedAccountId is not defined');
      return;
    }

    if (item._id === selectedAmazonAccount?._id) {
      return;
    }

    setAnchorEl(null);
    updateUserSelectedAmazonAccount.mutate({ selected_amazon_account: item._id });
    setAmazonAccount(item);

    const routerPathname = router.pathname;
    const isFromOnboarding = routerPathname.includes('/onboarding');

    if (isFromOnboarding) {
      router.push(URLS.MAIN_DASHBOARD.path);
      return;
    }

    router.push({
      pathname: router.pathname,
      query: { account: item._id }
    });
  };

  return (
    <MenuItem
      selected={selected}
      onClick={() => handleMenuItemClick(item)}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'baseline',
        minWidth: listItemWidth,
        margin: (theme) => theme.spacing(0.5, 0)
      }}
    >
      {children}
    </MenuItem>
  );
};
