import { CONFIG } from '@/lib/config/config';
import { useModalStore } from '@/lib/state-management/useModalStore';

import { AssistantSignupConfig, AssistantSignupCtaActionConfig } from './assistant-signup.types';

export const assistantSignupCtaActions: AssistantSignupCtaActionConfig = {
  'no-activation-mail': {
    label: 'no-activation-mail',
    variant: 'outlined',
    href: CONFIG.URL.USER_RESEND_ACTIVATION.path
  },
  'auto-redirect': {
    label: 'auto-redirect',
    variant: 'contained',
    loading: true,
    href: CONFIG.URL.SIGN_UP_CONNECT_AMAZON_ACCOUNT.path
  },
  'auto-redirect-arthy': {
    label: 'auto-redirect',
    variant: 'contained',
    loading: true,
    href: CONFIG.URL.POST_LOGIN.path
  },
  'book-package': {
    label: 'book-package',
    variant: 'contained',
    signupVariant: 'SignUpB',
    href: CONFIG.URL.SIGN_UP_BOOK_PACKAGE.path
  },
  'plan-and-billing': {
    label: 'plan-and-billing',
    variant: 'contained',
    signupVariant: 'SignUpC',
    href: CONFIG.URL.SETTINGS_PLAN_AND_BILLING.path
  },
  'discover-arthy': {
    label: 'discover-arthy',
    variant: 'outlined',
    href: CONFIG.URL.POST_LOGIN.path
  },
  'connect-advertising': {
    label: 'connect-advertising',
    variant: 'contained',
    href: CONFIG.URL.SIGN_UP_CONNECT_ADVERTISING_ACCOUNT.path
  },
  'skip-and-discover': {
    label: 'skip-and-discover',
    variant: 'outlined',
    signupVariant: 'SignUpA',
    href: CONFIG.URL.POST_LOGIN.path
  },
  'skip-and-book-package': {
    label: 'skip-and-book-package',
    variant: 'outlined',
    signupVariant: 'SignUpB',
    href: CONFIG.URL.SIGN_UP_BOOK_PACKAGE.path
  },
  'analyze-accounts': {
    label: 'analyze-accounts',
    variant: 'contained',
    loading: true,
    action: () => null
  },
  'connect-seller-account-in-progress': {
    label: 'check-account',
    variant: 'contained',
    loading: true,
    action: () => null
  },
  retry: {
    label: 'retry',
    variant: 'contained',
    href: CONFIG.URL.SIGN_UP_BOOK_PACKAGE.path
  },
  'retry-connect-seller-account': {
    label: 'retry',
    variant: 'contained',
    href: CONFIG.URL.SIGN_UP_CONNECT_AMAZON_ACCOUNT.path
  },
  'retry-book-package': {
    label: 'retry',
    variant: 'contained',
    action: () => {
      useModalStore.setState({ showSubscriptionModal: true });
    }
  },
  'contact-support': {
    label: 'contact-support',
    variant: 'outlined',
    color: 'warning',
    href: 'mailto:support@getarthy.com?subject=Assistant%20Book%20Package%20Error'
  }
};
export const assistantSignupConfig: AssistantSignupConfig = {
  login: { actions: [] },
  createAccount: { actions: [] },
  activateAccount: {
    actions: []
  },
  activateAccountSuccess: {
    actions: [assistantSignupCtaActions['auto-redirect']]
  },
  activateAccountError: {
    actions: []
  },
  forgotPassword: { actions: [] },
  activationMail: { actions: [] },
  connectSellerAccount: { actions: [] },
  connectSellerAccountSuccess: {
    actions: [
      assistantSignupCtaActions['book-package'],
      assistantSignupCtaActions['discover-arthy']
    ]
  },
  connectSellerAccountSuccessNew: {
    actions: [
      assistantSignupCtaActions['auto-redirect-arthy'],
      assistantSignupCtaActions['discover-arthy']
    ]
  },
  connectSellerAccountError: {
    actions: [
      assistantSignupCtaActions['retry-connect-seller-account'],
      assistantSignupCtaActions['contact-support']
    ]
  },
  connectSellerAccountCheckError: {
    actions: [assistantSignupCtaActions['contact-support']]
  },
  connectSellerAccountInProgress: {
    actions: [assistantSignupCtaActions['connect-seller-account-in-progress']]
  },
  connectAdvertisingAccount: {
    actions: [
      assistantSignupCtaActions['skip-and-discover'],
      assistantSignupCtaActions['skip-and-book-package']
    ]
  },
  connectAdvertisingAccountSuccess: {
    actions: [
      assistantSignupCtaActions['book-package'],
      assistantSignupCtaActions['discover-arthy']
    ]
  },
  connectAdvertisingAccountError: { actions: [] },
  bookPackage: {
    actions: []
  },
  bookPackageInProgress: { actions: [assistantSignupCtaActions['analyze-accounts']] },
  bookPackageSuccess: { actions: [assistantSignupCtaActions['discover-arthy']] },
  bookPackageError: {
    actions: [assistantSignupCtaActions['retry'], assistantSignupCtaActions['contact-support']]
  },
  bookPackageSuccessNew: {
    actions: [
      assistantSignupCtaActions['discover-arthy'],
      assistantSignupCtaActions['plan-and-billing']
    ]
  },
  bookPackageErrorNew: {
    actions: [
      assistantSignupCtaActions['retry-book-package'],
      assistantSignupCtaActions['contact-support']
    ]
  }
};
