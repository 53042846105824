import NavbarIcons from '@/components/navbar/navbar-icons.component';
import { useConfig } from './useConfig';
import { useEffect, useState } from 'react';
import { useGlobalStore } from '../state-management/useGlobalStore';
import { useRouter } from 'next/router';
import { useUserQ } from './queries/useUser.query';

import type { MenuItem, NavConfig } from '@/types/navigation/navigation';
import type { AmazonAccount } from '@/types/user/user-amazon-account.type';
import { useFlag } from '@unleash/proxy-client-react';
import { FEATURE_FLAGS } from '../feature-flags/feature-flags.constants';

const useNavConfig = () => {
  const router = useRouter();
  const configData = useConfig();
  const { data } = useUserQ();
  const selectedAmazonAccount = useGlobalStore(
    (state) => state.selectedAmazonAccount as AmazonAccount
  );
  const [CONFIG, setCONFIG] = useState(configData);
  const isOwnAmazonAccount = typeof selectedAmazonAccount?.permission === 'undefined';
  const isChatWidgetsEnabled = useFlag(FEATURE_FLAGS?.CHAT_WIDGETS);

  useEffect(
    () => {
      setCONFIG(configData);
    },
    // eslint-disable-next-line
    [router.asPath]
  );

  if (!data?.userModules) {
    return;
  }

  const businessInsightsConfig: NavConfig = {
    id: 'navbar-config-bussiness-insights-module',
    subheader: 'navigation.businessInsights',
    subheaderKey: 'main-dashboard',
    items: [
      {
        title: 'navigation.mainDashboard',
        path: CONFIG.URL.MAIN_DASHBOARD.path,
        icon: NavbarIcons.mainDashboard,
        key: 'main-dashboard',
        route: '/accounts/[account]/dashboard/[[...params]]',
        abilitiesName: 'main_dashboard'
      },
      {
        title: 'navigation.dashboard',
        path: CONFIG.URL.DASHBOARD.path,
        icon: NavbarIcons.profit,
        key: 'dashboard',
        route: '/accounts/[account]/dashboard/profit/[[...params]]',
        abilitiesName: 'profit_dashboard'
      },
      {
        title: 'navigation.ppcDashboardReporting',
        path: CONFIG.URL.PPC_DASHBOARD_REPORTING.path,
        icon: NavbarIcons.dashboard,
        key: 'ppc_reporting',
        route: '/accounts/[account]/dashboard/ppc-reporting/[[...params]]',
        abilitiesName: 'ppc_dashboard'
      },
      {
        title: 'navigation.reviews',
        path: CONFIG.URL.REVIEWS_ASINS.path,
        icon: NavbarIcons.reviews,
        key: 'reviews',
        route: '/accounts/[account]/dashboard/reviews/[[...params]]',
        abilitiesName: 'reviews'
      }
    ]
  };

  // Add chat widget page
  if (isChatWidgetsEnabled) {
    businessInsightsConfig.items.push({
      title: 'navigation.chat',
      path: CONFIG.URL.CHAT.path,
      icon: NavbarIcons.chat,
      key: 'chat',
      route: '/accounts/[account]/chat',
      abilitiesName: 'chat'
    });
  }

  const dataManagementConfig: NavConfig = {
    id: 'navbar-config-data-management',
    subheader: 'navigation.dataManagement',
    subheaderKey: 'product_costs',
    items: [
      {
        title: 'navigation.baseData',
        path: CONFIG.URL.BASE_DATA.path,
        icon: NavbarIcons.baseData,
        key: 'product_costs',
        route: '/accounts/[account]/base-data/[[...params]]',
        abilitiesName: 'base_data'
      }
      // {
      //   title: 'navigation.qi',
      //   path: CONFIG.URL.QI.path,
      //   icon: NavbarIcons.qi
      // },
      // {
      //   title: 'navigation.logistics',
      //   path: CONFIG.URL.LOGISTICS.path,
      //   icon: NavbarIcons.logistics
      // },
      // {
      //   title: 'navigation.asinExplorer',
      //   path: CONFIG.URL.ASINS_EXPLORER.path,
      //   icon: NavbarIcons.asinExplorer
      // },
      // {
      //   title: 'navigation.listing-checker',
      //   path: CONFIG.URL.LISTING_CHECKER.path,
      //   icon: NavbarIcons.lChecker
      // }
    ]
  };

  // const transactionManagementConfig: NavConfig = {
  //   id: 'navbar-config-transaction-management',
  //   subheader: 'navigation.transactionManagement',
  //   items: [
  // {
  //   title: 'navigation.assignments',
  //   path: CONFIG.URL.ASSIGNMENTS.path,
  //   icon: NavbarIcons.invoice
  // }
  // {
  //   title: 'navigation.qi',
  //   path: CONFIG.URL.QI.path,
  //   icon: NavbarIcons.qi
  // },
  // {
  //   title: 'navigation.logistics',
  //   path: CONFIG.URL.LOGISTICS.path,
  //   icon: NavbarIcons.logistics
  // },
  // {
  //   title: 'navigation.reviews',
  //   path: CONFIG.URL.REVIEWS_ASINS.path,
  //   icon: NavbarIcons.reviews
  // }
  // {
  //   title: 'navigation.asinExplorer',
  //   path: CONFIG.URL.ASINS_EXPLORER.path,
  //   icon: NavbarIcons.asinExplorer
  // },
  // {
  //   title: 'navigation.listing-checker',
  //   path: CONFIG.URL.LISTING_CHECKER.path,
  //   icon: NavbarIcons.lChecker
  // }
  //   ]
  // };

  const defaultNavConfigItems: MenuItem[] = [
    {
      title: 'navigation.recommendations',
      path: CONFIG.URL.RECOMMENDATIONS.path,
      icon: NavbarIcons.recommendations,
      notificationCounter: true,
      key: 'recommendations',
      route: '/accounts/[account]/recommendations',
      abilitiesName: 'recommendations'
    }
  ];

  // Conditionally add the 'navigation.automations' item based on 'isOwnAmazonAccount'
  if (isOwnAmazonAccount) {
    defaultNavConfigItems.push({
      title: 'navigation.automations',
      path: CONFIG.URL.AUTOMATIONS.path,
      icon: NavbarIcons.automations,
      key: 'automations',
      route: '/accounts/[account]/automations',
      abilitiesName: 'automation_page'
    });
  }

  const defaultNavConfig = [
    {
      id: 'navbar-config-recommendations',
      subheader: 'navigation.optimizationMeasures',
      subheaderKey: 'recommendations',
      items: defaultNavConfigItems
    },
    businessInsightsConfig,
    // transactionManagementConfig,
    dataManagementConfig
  ];

  const userModulesArrStr = data.userModules.map((module) => module.key);
  const menuItems: MenuItem[] = [];

  const adminNavConfig: NavConfig = {
    id: 'navbar-config-admin',
    subheader: 'navigation.admin.title',
    items: menuItems
  };

  if (userModulesArrStr && userModulesArrStr.length > 0) {
    if (userModulesArrStr.includes('admin_all')) {
      menuItems.push({
        title: 'navigation.admin.kpi',
        path: CONFIG.URL.ADMIN_KPI.path,
        icon: NavbarIcons.kpi,
        route: '/admin/kpi',
        key: 'admin-kpi',
        abilitiesName: 'admin_kpi'
      });
      menuItems.push({
        title: 'navigation.admin.useCases',
        path: CONFIG.URL.ADMIN_RECOMMENDATIONS.path,
        icon: NavbarIcons.savings,
        route: '/admin/use-cases',
        key: 'admin-use-cases',
        abilitiesName: 'admin_use_cases'
      });
      menuItems.push({
        title: 'navigation.admin.clientData',
        path: CONFIG.URL.ADMIN_USER_DATA.path,
        icon: NavbarIcons.users,
        route: '/admin/client-data',
        key: 'admin-client-data',
        abilitiesName: 'admin_client_data'
      });
      menuItems.push({
        title: 'navigation.settings',
        path: CONFIG.URL.ADMIN_SETTINGS.path,
        icon: NavbarIcons.settings,
        route: '/admin/settings',
        key: 'admin-settings',
        abilitiesName: 'admin_settings'
      });
    }

    if (
      userModulesArrStr.includes('amzscale_managed') &&
      !userModulesArrStr.includes('admin_all')
    ) {
      menuItems.push({
        title: 'navigation.admin.kpi',
        path: CONFIG.URL.ADMIN_KPI.path,
        icon: NavbarIcons.kpi,
        route: '/admin/kpi',
        key: 'admin-kpi',
        abilitiesName: 'admin_kpi'
      });
    }
    if (menuItems.length > 0) defaultNavConfig.push(adminNavConfig);
  }
  return defaultNavConfig;
};

export default useNavConfig;
