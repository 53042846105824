import { Card, CardContent } from '@mui/material';
import { useTranslation } from 'next-i18next';

import { CtaItemBaseContainer } from '../components/cta/cta-item-base-container.component';
import { AssistantSignupActions } from './assistant-signup-actions.component';
import { assistantSignupConfig } from './assistant-signup.config';
import { AssistantSignupOptions, AssistantSignupStep } from './assistant-signup.types';

interface AssistantSignupProps {
  step?: AssistantSignupStep;
  stepIndex?: number;
  overrideSubtitle?: string;
  hideVariant?: boolean;
  options?: AssistantSignupOptions;
  children?: React.ReactNode;
}

// write a test for this components

export const AssistantSignupComponent: React.FC<AssistantSignupProps> = ({
  stepIndex = 1,
  step = 'login',
  hideVariant,
  children,
  overrideSubtitle,
  options
}) => {
  const activeCta = assistantSignupConfig[step];

  const { t } = useTranslation('assistant');
  return (
    <Card data-cy={`assistant-signup-card-${step}`}>
      <CardContent>
        <CtaItemBaseContainer
          // @ts-ignore
          title={t(`assistant.auth.${step}.label`, {
            step: stepIndex,
            total: 3
          })}
          // @ts-ignore
          subtitle={overrideSubtitle ? overrideSubtitle : t(`assistant.auth.${step}.message`)}
          dataCy={`assistant-signup-${step}`}
        >
          {children}
          <AssistantSignupActions
            hideVariant={hideVariant}
            options={options}
            actions={activeCta.actions}
          />
        </CtaItemBaseContainer>
      </CardContent>
    </Card>
  );
};
