import type { AppSession } from '@/lib/auth/auth.types';
import { CONFIG } from '@/lib/config/config';
import { processApiResponseReactQuery } from '@/lib/api/process-api-response-react-query.helper';
import { requestApi } from '@/lib/api/request-api.helper';
import type { Userflow, UserflowType } from '@/types/userflow/userflow.type';
import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import { QUERY_KEYS_USERFLOWS } from './query-keys.constants';
import { useGlobalStore } from '@/lib/state-management/useGlobalStore';

export interface FetchUserflowProps {
  session: AppSession;
  userflow: UserflowType;
  amazonAccountId: string;
}

export const fetchUserflow = async ({ session, userflow, amazonAccountId }: FetchUserflowProps) => {
  if (!session) {
    throw Error('Session must be provided to fetchUserlow');
  }

  if (!amazonAccountId) {
    throw Error('amazonAccountId must be provided to fetchUserflow');
  }

  const url = `${CONFIG.API.ENDPOINT}${CONFIG.API.METHODS.USERFLOW.GET_USERFLOW}/${amazonAccountId}/${userflow}`;
  const response = await requestApi.get({
    url,
    session
  });

  return processApiResponseReactQuery<Userflow>({ response });
};

export const useUserflowQuery = ({
  userflow,
  enabled = true
}: {
  userflow: UserflowType;
  enabled?: boolean;
}) => {
  const session = useSession({ required: true });
  const selectedAmazonAccount = useGlobalStore((state) => state.selectedAmazonAccount);
  const isOwnAccount = typeof selectedAmazonAccount?.permission === 'undefined';
  const queryResult = useQuery({
    enabled: session.status === 'authenticated' && isOwnAccount && enabled,
    queryKey: [QUERY_KEYS_USERFLOWS.GET_USERFLOW, { amazonAccountId: selectedAmazonAccount?._id }],
    refetchOnMount: 'always',
    queryFn: async () => {
      return fetchUserflow({
        session: session as AppSession,
        amazonAccountId: selectedAmazonAccount?._id || '',
        userflow
      });
    }
  });

  return { ...queryResult };
};
