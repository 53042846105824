import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import { AppSession } from '@/lib/auth/auth.types';
import { CONFIG } from '@/lib/config/config';

import { processApiResponseReactQuery } from '@/lib/api/process-api-response-react-query.helper';
import { requestApi } from '@/lib/api/request-api.helper';
import { QUERY_KEYS_STR } from '@/lib/hooks/queries/query-keys.constants';

interface AccountActionMutation {
  account_name: string;
  region: string;
  main_marketplace: string;
  name: string;
  isOnboardingFailedInit?: boolean;
  isAccountFinished?: boolean;
}
export const useAmazonAccountActionMutation = () => {
  const url = `${CONFIG.API.ENDPOINT}${CONFIG.API.METHODS.USER_AMAZON_ACC_ACTIONS}`;
  const session = useSession({ required: true }) as AppSession;
  const queryClient = useQueryClient();
  const queryKeyToInvalidate = [QUERY_KEYS_STR.AMAZON_ACCOUNTS.GET_AMAZON_ACCOUNTS_GET_ALL];
  const queryKeyToInvalidateAmazonAccounts = [QUERY_KEYS_STR.AMAZON_ACCOUNTS.GET_AMAZON_ACCOUNTS];

  return useMutation({
    mutationFn: async ({
      name,
      account_name,
      region,
      main_marketplace,
      isOnboardingFailedInit = false,
      isAccountFinished
    }: AccountActionMutation) =>
      requestApi
        .post({
          url,
          session,
          body: {
            name,
            account_name,
            region,
            main_marketplace,
            isOnboardingFailedInit,
            isAccountFinished
          }
        })
        .then(async (response) => {
          const data = response as Response;
          const newResponse = response.clone();
          const newResponseJson = await newResponse.json();
          const { redirect } = newResponseJson.data;

          if (redirect) {
            window.location.href = redirect;
          }
          return processApiResponseReactQuery({ response: data });
        }),
    onSuccess: () => {
      console.log('onSuccess');
      queryClient.invalidateQueries({
        queryKey: queryKeyToInvalidate
      });
      queryClient.invalidateQueries({
        queryKey: queryKeyToInvalidateAmazonAccounts
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeyToInvalidate
      });
      queryClient.invalidateQueries({
        queryKey: queryKeyToInvalidateAmazonAccounts
      });
    },
    onError: (error) => {
      console.log(error);
    }
  });
};
