import { v4 as uuidv4 } from 'uuid';

export const generateRandomChatHistorySessionId = (): string => {
  return `chat_history_session_id_${uuidv4()}`;
};

export const arraysHaveSameValues = (
  arrA: string[] | number[],
  arrB: string[] | number[]
): boolean => {
  if (arrA.length !== arrB.length) return false;
  const sortedArrA = [...arrA].sort();
  const sortedArrB = [...arrB].sort();
  return sortedArrA.every((value, index) => value === sortedArrB[index]);
};

export const chatChartDataKeys = {
  'chat-reviews-chart-widget': 'reviews',
  'chat-review-feedback-requests-chart-widget': 'reviewfeedbackrequests'
};

export const linkRegex = /\[(.+?)\]\((https?:\/\/[^\s]+)\)|https?:\/\/[^\s]+/g;
