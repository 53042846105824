import NavList from './nav-list/nav-list.component';
import { Box, Divider, List } from '@mui/material';
import { GREY } from '@/lib/styles/palette.style';
import { ListSubheaderStyle } from './nav-section-item/nav-item.style';
import { useGlobalStore } from '@/lib/state-management/useGlobalStore';
import { useTranslation } from 'next-i18next';

import type { NavConfig } from '@/types/navigation/navigation';
import type { AmazonAccount } from '@/types/user/user-amazon-account.type';

interface NavSectionVerticalProps {
  isCollapse?: boolean;
  navConfig: NavConfig[];
}

const NavSectionVerticalComponent: React.FC<NavSectionVerticalProps> = ({
  navConfig,
  isCollapse
}) => {
  const { t } = useTranslation('nav-bar');

  const selectedAmazonAccount = useGlobalStore(
    (state) => state.selectedAmazonAccount as AmazonAccount
  );

  const updateNavConfig = (navConfig: NavConfig[], isUserDataLoading: boolean) => {
    return navConfig.map((group) => ({
      ...group,
      items: group.items.map((item) => ({
        ...item,
        disabled: isUserDataLoading && item.key !== 'main-dashboard'
      }))
    }));
  };

  const isUserDataLoading = selectedAmazonAccount?.status !== 'finished';
  const updatedNavConfig = updateNavConfig(navConfig, isUserDataLoading);

  return (
    <Box>
      {updatedNavConfig?.map((group, index) => (
        <List key={group.id} disablePadding sx={{ px: 2 }}>
          {group.subheader && group.subheader.length > 0 && !isCollapse ? (
            <ListSubheaderStyle disableGutters>
              {group.subheader && t(group.subheader, { defaultValue: '' })}
            </ListSubheaderStyle>
          ) : (
            index > 0 && <Divider sx={{ backgroundColor: GREY[50], mb: 1 }} />
          )}

          {group.items.map((list) => (
            <NavList
              key={list.title + list.path}
              data={list}
              depth={1}
              hasChildren={!!list.children}
              isCollapse={isCollapse}
            />
          ))}
        </List>
      ))}
    </Box>
  );
};

export default NavSectionVerticalComponent;
