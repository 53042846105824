import { alpha, styled } from '@mui/material/styles';
import SimpleBarReact from 'simplebar-react';

export const RootStyle = styled('div')(() => ({
  flexGrow: 1
}));

export const SimpleBarStyle = styled(SimpleBarReact)(({ theme, height }) => {
  const returnStyle = {
    maxHeight: '100%',
    '& .simplebar-scrollbar': {
      '&:before': {
        backgroundColor: alpha(theme.palette.grey[600], 0.48)
      },
      '&.simplebar-visible:before': {
        opacity: 1
      }
    },
    '& .simplebar-track.simplebar-vertical': {
      width: 100
    },
    '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
      height: 6
    },
    '& .simplebar-mask': {
      zIndex: 'inherit'
    },
    '& .simplebar-placeholder': {
      height: '20px !important'
    },
    '& .simplebar-content': {
      maxHeight: height
    }
  };
  return returnStyle;
});
