import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, IconButton } from '@mui/material';

// import PropTypes from 'prop-types';

const CollapseButton = ({ onToggleCollapse, collapseClick, setIsNavbarClosed }) => {
  const handleClick = () => {
    onToggleCollapse();
    setIsNavbarClosed(!collapseClick);
  };

  return (
    // @ts-ignore
    <IconButton onClick={handleClick}>
      <Box
        sx={{
          lineHeight: 0,
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter
            }),
          ...(collapseClick && {
            transform: 'rotate(180deg)'
          })
        }}
      >
        <ChevronLeftIcon />
      </Box>
    </IconButton>
  );
};

// CollapseButton.propTypes = {
//   collapseClick: PropTypes.bool,
//   onToggleCollapse: PropTypes.func
// };

export default CollapseButton;
