import getInitials from '@/lib/helpers/utils/getInitials';
import { GREY } from '@/lib/styles/palette.style';
import { Avatar } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

import ImageComponent from '@/components/image/image.component';
import { useUserQ } from '@/lib/hooks/queries/useUser.query';
import { chatAvatarSize } from '../../style/chat.style';

interface ChatAvatarComponentProps {
  type: 'user' | 'arthy';
  sx?: object;
}

export const ChatAvatarComponent: React.FC<ChatAvatarComponentProps> = ({ type = 'arthy', sx }) => {
  const { data: user } = useUserQ();

  const bgColor = type === 'arthy' ? GREY[0] : GREY[800];
  const avatarName =
    type === 'arthy' ? (
      <ImageComponent alt='arthy profile image' src='/assets/arthy.png' width={28} height={28} />
    ) : (
      getInitials(user?.username)
    );
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', ...sx }}>
      <Avatar
        sx={{
          bgcolor: bgColor,
          ...chatAvatarSize
        }}
      >
        {avatarName}
      </Avatar>
    </Box>
  );
};
