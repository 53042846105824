import {
  Box,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemIconProps,
  ListItemText,
  ListItemTextProps,
  ListSubheader
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

import { ICON, NAVBAR } from '@/lib/constants/layout.constant';
import cssStyles from '@/lib/styles/cssStyles.style';
import palette from '@/lib/styles/palette.style';

export const NotificationCounterStyle = styled(Box)(
  // @ts-ignore
  ({ theme, top = -2, left = 25 }) => ({
    position: 'absolute',
    top,
    left,
    backgroundColor: theme.palette.primary.main,
    width: '24px',
    height: '24px',
    textAlign: 'center',
    borderRadius: 100,
    zIndex: 5000,
    color: theme.palette.grey[0],
    fontWeight: 'bolder',
    fontSize: '0.8rem',
    padding: 2.5
  })
);

interface ListItemStyleProps extends ListItemButtonProps {
  active?: boolean;
}

export const ListItemStyle = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'active'
})<ListItemStyleProps>(({ active, theme }) => ({
  position: 'relative',
  cursor: 'pointer',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(0),
  paddingRight: theme.spacing(1.5),
  marginBottom: theme.spacing(0.5),
  color: palette.light.text.secondary,
  borderRadius: theme.shape.borderRadius,
  height: NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
  ':hover': {
    backgroundColor: alpha(palette.light.primary.main, theme.palette.action.hoverOpacity)
  },
  // Active item
  ...(active && {
    color: palette.light.primary.main,
    backgroundColor: alpha(palette.light.primary.main, theme.palette.action.selectedOpacity)
  })
}));

interface ListItemTextStyleProps extends ListItemTextProps {
  isCollapse?: boolean;
}

export const ListItemTextStyle = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== 'isCollapse'
  // @ts-ignore
})<ListItemTextStyleProps>(({ isCollapse, theme }) => ({
  transition: theme.transitions.create(['width', 'opacity'], {
    duration: theme.transitions.duration.shorter
  }),
  ...(isCollapse && {
    width: 0,
    opacity: 0
  })
}));

interface ListItemIconStyleProps extends ListItemIconProps {
  isActive?: boolean;
}

export const ListItemIconStyle = styled(ListItemIcon, {
  shouldForwardProp: (prop) => prop !== 'isActive'
})<ListItemIconStyleProps>(({ isActive = false }) => ({
  width: ICON.NAVBAR_ITEM,
  height: ICON.NAVBAR_ITEM,
  color: isActive ? palette.light.primary.main : palette.light.text.secondary,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '40px',
  '& svg': { width: '100%', height: '100%' }
}));

export const ListSubheaderStyle = styled(ListSubheader)(({ theme }) => ({
  ...theme.typography.overline,
  borderRadius: theme.shape.borderRadius,
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter
  }),
  ...cssStyles(theme).bgBlur()
}));

export const NotifacationCounterStyle = styled(Box)(
  // @ts-ignore
  ({ theme, top = -2, left = 25 }) => ({
    position: 'absolute',
    top,
    left,
    backgroundColor: theme.palette.primary.main,
    width: '24px',
    height: '24px',
    textAlign: 'center',
    borderRadius: 100,
    zIndex: 5000,
    color: theme.palette.grey[0],
    fontWeight: 'bolder',
    fontSize: '0.8rem',
    padding: 2.5
  })
);
