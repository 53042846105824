import { AppSession } from '@/lib/auth/auth.types';
import { CONFIG } from '@/lib/config/config';
import { processApiResponseReactQuery } from '@/lib/api/process-api-response-react-query.helper';
import { requestApi } from '@/lib/api/request-api.helper';
import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import { QUERY_KEYS_AUTOMATION_USER } from './query-keys.constants';
import { RecommendationTasksCount } from '@/types/recommendations/recommendation.type';

export interface FetchRecommendationTaskCountProps {
  session: AppSession;
  meta?: boolean;
}

export const fetchRecommendationTasksCount = async ({
  session,
  meta
}: FetchRecommendationTaskCountProps) => {
  if (!session) {
    throw Error('Session must be provided to fetchRecommendationTasksCount');
  }
  const url = `${CONFIG.API.ENDPOINT}${CONFIG.API.METHODS.RECOMMENDATIONS.GET_TASK_COUNT}`;
  const response = await requestApi.get({
    url,
    session
  });
  return await processApiResponseReactQuery<RecommendationTasksCount>({
    response,
    meta
  });
};

export const useRecommendationTasksCountQuery = () => {
  const session = useSession({ required: true });
  const queryResult = useQuery({
    enabled: session.status === 'authenticated',
    queryKey: [QUERY_KEYS_AUTOMATION_USER.GET_RECOMMENDATION_TASK_COUNT],
    queryFn: async () => {
      return fetchRecommendationTasksCount({
        session: session as AppSession,
        meta: true
      });
    }
  });

  return { ...queryResult };
};
