import { memo } from 'react';

import { Stack } from '@mui/material';
import PropTypes from 'prop-types';

import NavListComponent from './nav-list/nav-list.component';

const NavSectionHorizontal = ({ navConfig }) => {
  const hideScrollbar = {
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  };

  NavSectionHorizontal.propTypes = {
    navConfig: PropTypes.array
  };

  return (
    <Stack
      direction='row'
      justifyContent='center'
      sx={{ bgcolor: 'background.neutral', borderRadius: 1, px: 0.5 }}
    >
      <Stack direction='row' sx={{ ...hideScrollbar, py: 1 }}>
        {navConfig?.map((group) => (
          <Stack key={group.id} direction='row' flexShrink={0}>
            {group.items.map((list) => (
              <NavListComponent
                key={list.title + list.path}
                data={list}
                depth={1}
                hasChildren={!!list.children}
              />
            ))}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

NavSectionHorizontal.propTypes = {
  navConfig: PropTypes.array
};

export default memo(NavSectionHorizontal);
