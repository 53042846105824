import { useLinkHandling } from '@/lib/hooks/useLinkHandling';
import SvgIconStyle from '../ui/svg-icon';
import { dashboardTypes } from './dashboard.constants';

export const getIcon = (name, widthS = 15, heightS = 15) => (
  <SvgIconStyle src={'/assets/' + `${name}.svg`} sx={{ width: widthS, height: heightS, mr: 1 }} />
);

type GenerateTabUrlProps = {
  tabId: string;
  path: string;
  dashboardType: (typeof dashboardTypes)[keyof typeof dashboardTypes];
  URLS: ReturnType<typeof useLinkHandling>;
};
export const generateTabUrl = ({ tabId, path, dashboardType, URLS }: GenerateTabUrlProps) => {
  const params = { tab: tabId, path };
  if (tabId) {
    if (dashboardType === dashboardTypes.mainDashboard) {
      return URLS.MAIN_DASHBOARD.generateUrl(params);
    }
    if (dashboardType === dashboardTypes.sales) {
      return URLS.DASHBOARD.generateUrl(params);
    }
    if (dashboardType === dashboardTypes.ppc) {
      return URLS.PPC_DASHBOARD.generateUrl(params);
    }
    if (dashboardType === dashboardTypes.ppcReporting) {
      return URLS.PPC_DASHBOARD_REPORTING.generateUrl(params);
    }
    if (dashboardType === dashboardTypes.reviews) {
      return URLS.REVIEWS_ASINS.generateUrl(params);
    }
  }
  return '';
};
