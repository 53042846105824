import { InfoOutlined } from '@mui/icons-material';
import { Box, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';

export const SelectAmazonAccountTooltip: React.FC = () => {
  const { t } = useTranslation(['settings', 'common']);
  return (
    <Tooltip
      arrow
      placement='bottom'
      title={
        <Box>
          <Typography variant='body1' sx={{ fontWeight: 'bold', pt: 1 }}>
            {t(`common:components.connected-amazon-accounts.list.amazon_account.title`)}
          </Typography>
          <Typography variant='body2'>
            {t(`common:components.connected-amazon-accounts.list.amazon_account.description`)}
          </Typography>
          <Typography variant='body1' sx={{ fontWeight: 'bold', pt: 1 }}>
            {t(`common:components.connected-amazon-accounts.list.advertising_account.title`)}
          </Typography>
          <Typography variant='body2'>
            {t(`common:components.connected-amazon-accounts.list.advertising_account.description`)}
          </Typography>
        </Box>
      }
      enterDelay={500}
    >
      <Box
        sx={{
          cursor: 'pointer',
          display: 'inline-flex'
        }}
      >
        {t(`common:components.connected-amazon-accounts.list.tooltip`)}
        <InfoOutlined
          sx={{
            ml: 1,
            fontSize: (theme) => theme.typography.body1.fontSize
          }}
          color='primary'
        />
      </Box>
    </Tooltip>
  );
};
