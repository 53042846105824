import { Box } from '@mui/material';

import Iconify from '@/components/ui/iconify';

interface NavItemLinkInfoProps {
  info?: string;
  open?: boolean;
  hasChildren?: boolean;
}

const NavItemLinkInfo: React.FC<NavItemLinkInfoProps> = ({ info, open, hasChildren }) => (
  <>
    {info && (
      <Box component='span' sx={{ lineHeight: 0 }}>
        {info}
      </Box>
    )}

    {hasChildren && (
      <Iconify
        icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
        sx={{ width: 16, height: 16, ml: 1, flexShrink: 0 }}
      />
    )}
  </>
);

export default NavItemLinkInfo;
