import { CONFIG } from '@/lib/config/config';
import { processApiResponseReactQuery } from '@/lib/api/process-api-response-react-query.helper';
import { QUERY_KEYS_USERFLOWS } from './query-keys.constants';
import { requestApi } from '@/lib/api/request-api.helper';
import { useGlobalStore } from '@/lib/state-management/useGlobalStore';
import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import type { AppSession } from '@/lib/auth/auth.types';
import type { UserflowStatus, UserflowType } from '@/types/userflow/userflow.type';
import type { FetchUserflowProps } from './useUserflow.query';

export const fetchUserflowStatus = async ({
  session,
  userflow,
  amazonAccountId
}: FetchUserflowProps) => {
  if (!session) {
    throw Error('Session must be provided to fetchUserlow');
  }
  if (!amazonAccountId) {
    throw Error('amazonAccountId must be provided to fetchUserflow');
  }
  const url = `${CONFIG.API.ENDPOINT}${CONFIG.API.METHODS.USERFLOW.GET_USERFLOW}/${amazonAccountId}/${userflow}/status`;
  const response = await requestApi.get({
    url,
    session
  });
  return processApiResponseReactQuery<UserflowStatus>({ response });
};

export const useUserflowStatusQuery = ({ userflow }: { userflow: UserflowType }) => {
  const session = useSession({ required: true });
  const selectedAmazonAccount = useGlobalStore((state) => state.selectedAmazonAccount);

  const queryResult = useQuery({
    enabled: session.status === 'authenticated',
    queryKey: [
      QUERY_KEYS_USERFLOWS.GET_USERFLOW_STATUS,
      { amazonAccountId: selectedAmazonAccount?._id }
    ],
    queryFn: async () => {
      return fetchUserflowStatus({
        session: session as AppSession,
        amazonAccountId: selectedAmazonAccount?._id || '',
        userflow
      });
    }
  });

  return { ...queryResult };
};
