import { Button, Dialog, DialogActions, DialogContent, SxProps, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useContactSupport } from './use-contact-support.mutation';
import { SupportReason } from './contact-support.types';

interface ContactSupportProps {
  supportReason: SupportReason;
  dialogHeadline: string;
  dialogMessage: string;
  buttonStyle?: SxProps;
  onCloseDialog?: () => void;
}

export const ContactSupport: React.FC<ContactSupportProps> = ({
  supportReason,
  buttonStyle = {},
  dialogHeadline,
  dialogMessage,
  onCloseDialog = () => null
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { t } = useTranslation(['recommendations', 'common', 'assistant']);
  const contactSupport = useContactSupport();

  const handleClick = () => {
    contactSupport.mutate({ supportReason, setIsDialogOpen });
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    onCloseDialog();
  };

  return (
    <>
      <Button
        variant='contained'
        onClick={handleClick}
        sx={{ ...buttonStyle }}
        data-cy='contact-support-button'
      >
        {t('common:components.support.contact-support')}
      </Button>
      <Dialog open={isDialogOpen}>
        <DialogContent data-cy='contact-support-dialog-content'>
          <Typography sx={{ fontWeight: 'bold', mb: 1 }} data-cy='contact-support-dialog-headline'>
            {dialogHeadline}
          </Typography>
          <Typography data-cy='contact-support-dialog-message'>{dialogMessage}</Typography>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            variant='outlined'
            onClick={handleCloseDialog}
            data-cy='contact-support-dialog-close-button'
          >
            {t('common:components.button.close', { defaultValue: '' })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
