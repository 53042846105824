import React, { useState } from 'react';

import { UserLanguages } from '@/types/user/user';
import { Box, MenuItem } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import { CONFIG } from '@/lib/config/config';

import IconButtonAnimate from '../ui/icon-button-animate.style';
import IconSplitFlag from '../ui/icon-split-flag.component';
import Iconify from '../ui/iconify';
import MenuPopover from '../ui/menu-popover';
import { useUserQ as _useUserQ } from '@/lib/hooks/queries/useUser.query';
import { useUpdateUserLanguageMutation } from '@/lib/queries/user/mutation/use-update-user.mutation';
import { IconifyIconName } from '@iconify/react';

interface StringMap {
  [key: string]: string;
}
interface LanguagePopoverProps {
  useUserQ?: typeof _useUserQ;
}
const LanguagePopover: React.FC<LanguagePopoverProps> = ({ useUserQ = _useUserQ }) => {
  const { t } = useTranslation();
  const { data: user } = useUserQ();
  const userLanguage = user?.language;
  const router = useRouter();
  const { pathname, asPath, query } = router;

  const useUpdateUserLanguage = useUpdateUserLanguageMutation();
  const availableLanguages: UserLanguages = CONFIG.SETTINGS.LANGUAGES;
  const languageFlags = CONFIG.SETTINGS.LANGUAGE_FLAGS as StringMap;
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const open = Boolean(anchorEl);
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (event.currentTarget) setAnchorEl(event.currentTarget);
  };
  const changeLanguageHandler = (newLanguage: string) => {
    useUpdateUserLanguage.mutate({ language: newLanguage });
    router.push({ pathname, query }, asPath, { locale: newLanguage });
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  if (!userLanguage) return null;
  const userLanguageFlag = languageFlags[userLanguage];

  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
        data-cy='language-popover'
      >
        {userLanguage === 'en' ? (
          <IconSplitFlag firstFlag={languageFlags.us} secondFlag={userLanguageFlag} />
        ) : (
          <Iconify
            icon={userLanguageFlag as unknown as IconifyIconName}
            sx={{}}
            width={24}
            height={24}
          />
        )}
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableScrollLock
        disabledArrow
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75
          }
        }}
        data-cy='language-dropdown'
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          {availableLanguages.map((lang) => (
            <MenuItem
              onClick={() => changeLanguageHandler(lang)}
              key={lang}
              value={lang}
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <>
                {lang === 'en' ? (
                  <IconSplitFlag
                    hasMargin
                    firstFlag={languageFlags.us}
                    secondFlag={languageFlags[lang]}
                  />
                ) : (
                  <Iconify
                    icon={languageFlags[lang] as unknown as IconifyIconName}
                    width={30}
                    height={24}
                    sx={{ mr: 1 }}
                  />
                )}
                {t(`general.language.${lang}`, {
                  defaultValue: ''
                })}
              </>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
};

export default LanguagePopover;
