import { Box } from '@mui/material';

import Iconify from './iconify';

interface SplitflagIconProps {
  firstFlag: string;
  secondFlag: string;
  width?: number;
  height?: number;
  hasMargin?: boolean;
}
const IconSplitFlag: React.FC<SplitflagIconProps> = ({
  firstFlag,
  secondFlag,
  width = 30,
  height = 24,
  hasMargin = false
}) => (
  <Box
    sx={{
      position: 'relative',
      display: 'inline',
      mr: hasMargin ? 1 : null,
      width,
      height
    }}
  >
    <Box
      sx={{
        clipPath: 'polygon(100% 100%, 0% 100%, 100% 0)',
        position: 'absolute',
        zIndex: 1,
        bottom: 0,
        right: 0,
        left: 0,
        top: 0
      }}
    >
      <Iconify icon={secondFlag} width={width} height={height} sx={{}} />
    </Box>
    <Iconify icon={firstFlag} width={width} height={height} sx={{}} />
  </Box>
);

export default IconSplitFlag;
