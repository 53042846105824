import { Avatar, Fab } from '@mui/material';
import { chatAvatarShowButton } from '../../style/chat.style';
import useChatStore from '@/lib/state-management/useChatStore';
import { useFlag } from '@unleash/proxy-client-react';
import { FEATURE_FLAGS } from '@/lib/feature-flags/feature-flags.constants';

export const ChatAvatarShowButton = () => {
  const isArthyTalkEnabled = useFlag(FEATURE_FLAGS.CHAT);
  const setIsMinimized = useChatStore((state) => state.setIsMinimized);

  if (!isArthyTalkEnabled) return null;
  return (
    <Fab
      data-cy='chat-avatar-toggle-button'
      aria-label='show chat'
      color='primary'
      sx={chatAvatarShowButton}
      onClick={() => {
        setIsMinimized(false);
      }}
    >
      <Avatar sx={{ width: '100%', height: '100%' }} src='/assets/arthy.png' />
    </Fab>
  );
};
