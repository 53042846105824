export const ODD_OPACITY = 0.2;
export const DASHBOARD_HEIGHT = 600;

export const dashboardTypes = {
  sales: 'sales',
  ppc: 'ppc',
  ppcReporting: 'ppc-reporting',
  mainDashboard: 'main-dashboard',
  reviews: 'reviews'
};
