import AssignmentIcon from '@mui/icons-material/AssignmentOutlined';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import InsightsIcon from '@mui/icons-material/InsightsOutlined';
import LocalShippingIcon from '@mui/icons-material/LocalShippingOutlined';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOnOutlined';
import People from '@mui/icons-material/PeopleOutline';
import QueryStatsIcon from '@mui/icons-material/QueryStatsOutlined';
import ReviewsIcon from '@mui/icons-material/ReviewsOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import SourceIcon from '@mui/icons-material/SourceOutlined';
import SpeedIcon from '@mui/icons-material/SpeedOutlined';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import TravelExploreIcon from '@mui/icons-material/TravelExploreOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';

import { getIcon } from './navbar.helper';

const NavbarIcons = {
  mainDashboard: <DashboardOutlinedIcon fontSize='medium' />,
  profit: <MonetizationOnIcon fontSize='medium' />,
  dashboard: <SpeedIcon fontSize='medium' />,
  invoice: <AssignmentIcon fontSize='medium' />,
  reviews: <ReviewsIcon fontSize='medium' />,
  asinExplorer: getIcon('menu_tools'),
  lChecker: getIcon('checkbox_checked_green'),
  settings: <SettingsIcon fontSize='medium' />,
  baseData: <SourceIcon fontSize='medium' />,
  logistics: <LocalShippingIcon fontSize='medium' />,
  qi: <TravelExploreIcon fontSize='medium' />,
  kpi: <InsightsIcon fontSize='medium' />,
  savings: <QueryStatsIcon fontSize='medium' />,
  users: <People fontSize='medium' />,
  recommendations: <TipsAndUpdatesOutlinedIcon fontSize='medium' />,
  automations: <AutoModeIcon fontSize='medium' />,
  chat: <ChatOutlinedIcon fontSize='medium' />
};

export default NavbarIcons;
