import React from 'react';

import { Badge, CircularProgress, Grid } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { StyledBadge } from './assistant-avatar.style';
import arthyImage from '../../../../../public/assets/arthy.png';
import Image from 'next/image';
interface AssistantAvatarProps {
  isLoading: boolean;
  isOnline?: boolean;
  avatarSize?: number;
}
export const AssistantAvatarComponent: React.FC<AssistantAvatarProps> = ({
  isOnline = false,
  isLoading = false,
  avatarSize = 64
}) => {
  return (
    <Grid item xs='auto' display='flex' alignItems='flex-start'>
      <Badge
        color='secondary'
        overlap='circular'
        invisible={!isLoading}
        badgeContent={
          isLoading && <CircularProgress data-cy='assistant-loading' color='inherit' size={10} />
        }
      >
        <Stack direction='row'>
          <StyledBadge
            show={isOnline}
            overlap='circular'
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant='dot'
          >
            <Avatar sx={{ width: avatarSize, height: avatarSize, backgroundColor: '#fff' }}>
              <Image src={arthyImage} alt='Arthy' style={{ width: '100%', height: 'auto' }} />
            </Avatar>
          </StyledBadge>
        </Stack>
      </Badge>
    </Grid>
  );
};
