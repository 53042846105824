import React from 'react';
import { Card, CardContent, Fade, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ChatAvatarComponent } from '../chat-layout/chat-avatar.component';
import { GREY } from '@/lib/styles/palette.style';
import { ChatMessageActionsComponent } from './chat-message-actions.component';
import { useUserQ } from '@/lib/hooks/queries/useUser.query';
import { LoadingFlashingDots } from '@/components/ui/loading/loading-flashing-dots.component';
import type { ChatMessage } from '../../chat.types';
import { ChatMessageNoticeComponent } from './chat-message-notice.component';
import { ChatMessageWithLinkComponent } from './chat-message-with-link.component';
import { linkRegex } from '../../helpers/chat-helpers';

interface ChatMessageComponentProps {
  message: ChatMessage;
  isFetching?: boolean;
  timeout?: number;
}

export const ChatMessageComponent: React.FC<ChatMessageComponentProps> = ({
  message,
  isFetching = false,
  timeout = 300
}) => {
  const { data: user } = useUserQ();

  const isMessageTypeArthy = message.type === 'arthy' ? true : false;
  const isShowChatMessageActions = !isFetching && isMessageTypeArthy ? true : false;

  const bgColor = isMessageTypeArthy ? 'white' : GREY[800];
  const textColor = isMessageTypeArthy ? GREY[800] : 'white';

  const messageContainsLink = linkRegex.test(message.message);

  return (
    <Fade in={true} timeout={timeout}>
      <Box display='flex' sx={{ my: 2 }} data-cy='chat-message-component'>
        <Box display='flex' alignItems='end'>
          <ChatAvatarComponent type={message.type} />
        </Box>
        <Box ml={2}>
          <Typography
            variant='body1'
            mb={1}
            fontSize={11}
            color={GREY[600]}
            data-cy='chat-message-name'
          >
            {isMessageTypeArthy ? 'Arthy' : user?.username}
          </Typography>
          <Card variant='outlined' sx={{ backgroundColor: bgColor, position: 'relative' }}>
            <ChatMessageNoticeComponent notice={message?.notice} />
            <CardContent sx={{ pb: '16px !important' }}>
              {isFetching ? (
                <LoadingFlashingDots />
              ) : (
                <Typography sx={{ color: textColor, whiteSpace: 'pre-wrap' }}>
                  {messageContainsLink ? (
                    <ChatMessageWithLinkComponent message={message.message} />
                  ) : (
                    message.message
                  )}
                </Typography>
              )}
            </CardContent>
            {isShowChatMessageActions && message?.review && (
              <ChatMessageActionsComponent review={message?.review} />
            )}
          </Card>
        </Box>
      </Box>
    </Fade>
  );
};
