import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack } from '@mui/material';
import { useTranslation } from 'next-i18next';

import LinkButton from '@/components/ui/link-button.component';

import { AssistantSignupCtaAction, AssistantSignupOptions } from './assistant-signup.types';

interface AssistantSignupActionsProps {
  actions: AssistantSignupCtaAction[];
  hideVariant?: boolean;
  options?: AssistantSignupOptions;
}
export const AssistantSignupActions: React.FC<AssistantSignupActionsProps> = ({
  actions,
  hideVariant,
  options
}) => {
  const { t } = useTranslation('assistant');
  const translationOptions = {
    count: options?.bookPackageInProgress?.count,
    total: options?.bookPackageInProgress?.total,
    seconds:
      options?.activateAccountSuccess?.seconds || options?.connectSellerAccountSuccessNew?.seconds
  };
  return (
    <Stack direction='row' spacing={2}>
      {actions.map((action) => {
        const content = (
          <>
            {
              // @ts-ignore
              t(`assistant.auth.actions.${action.label}`, { ...translationOptions })
            }
          </>
        );

        if (hideVariant) {
          return null;
        }
        if (action.loading) {
          return (
            <LoadingButton
              data-cy={action.label}
              loading
              sx={{}}
              key={action.label}
              variant={action.variant}
              loadingPosition='start'
            >
              <Box sx={{ pl: 2 }}>{content}</Box>
            </LoadingButton>
          );
        }
        if (action.action) {
          return (
            <Button
              data-cy={action.label}
              onClick={() => {
                typeof action.action !== 'undefined' && action.action();
              }}
              variant={action.variant}
              key={action.label}
            >
              {content}
            </Button>
          );
        }

        return (
          <LinkButton
            variant={action.variant}
            href={action.href}
            key={action.label}
            data-cy={action.label}
          >
            {content}
          </LinkButton>
        );
      })}
    </Stack>
  );
};
