import { Icon, IconifyIconName } from '@iconify/react';
import { Box, BoxProps } from '@mui/material';

interface IconifyProps extends BoxProps {
  icon: IconifyIconName;
}

const Iconify: React.FC<IconifyProps> = ({ icon, sx, ...other }) => (
  <Box component={Icon} icon={icon} sx={{ ...sx }} {...other} />
);

export default Iconify;
