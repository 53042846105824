import { AppSession } from '@/lib/auth/auth.types';
import { CONFIG } from '@/lib/config/config';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS_STR } from './query-keys.constants';
import { useSession } from 'next-auth/react';
import { requestApi } from '@/lib/api/request-api.helper';
import { processApiResponseReactQuery } from '@/lib/api/process-api-response-react-query.helper';
import { PricingTable } from '@/types/payment/payment-package';

export const fetchPricingTable = async ({ session }: { session: AppSession }) => {
  if (!session) {
    throw new Error('Session is required');
  }
  const url = `${CONFIG.API.ENDPOINT}${CONFIG.API.METHODS.TRANSACTIONS.PAYMENT_PAYMENT_PACKAGE_PRICNG_TABLE}`;
  const response = await requestApi.get({ url, session });
  return await processApiResponseReactQuery<PricingTable>({ response });
};

export const usePricingTableQuery = () => {
  const session = useSession({ required: true });
  return useQuery({
    enabled: session.status === 'authenticated',
    queryKey: [QUERY_KEYS_STR.PAYMENT.GET_PRICNG_TABLE],
    queryFn: async () => fetchPricingTable({ session })
  });
};
