import { create } from 'zustand';

type ModalState = {
  showSubscriptionModal: boolean;
  setShowSubscriptionModal: (show: boolean) => void;
};

export const useModalStore = create<ModalState>((set) => ({
  showSubscriptionModal: false,
  setShowSubscriptionModal: (show: boolean) => {
    set({ showSubscriptionModal: show });
  }
}));
