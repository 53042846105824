import { MenuItem, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';

export const NoAccountFoundComponent: React.FC = () => {
  const { t } = useTranslation('common');

  return (
    <MenuItem disabled data-cy='base-menu-no-account-found-component'>
      <Typography>{t('components.global-dropdown.zero-results')}</Typography>
    </MenuItem>
  );
};
