import { AppBar } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { DataGridPremium, gridClasses } from '@mui/x-data-grid-premium';

import { HEADER } from '@/lib/constants/layout.constant';
import cssStyles from '@/lib/styles/cssStyles.style';

import { ODD_OPACITY } from './dashboard.constants';
import { getIcon } from './dashboard.helpers';

export const StyledDashboardMain = styled('main')(({ theme }) => ({
  padding: theme.spacing(2, 0, 2, 0),
  gridArea: 'main',
  overflow: 'hidden',
  backgroundColor: theme.palette.grey[100]
}));

interface RootStyleProps {
  isCollapse: boolean;
  isOffset: boolean;
  verticalLayout: boolean;
}

export const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout'
})<RootStyleProps>(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur,
  // height: HEADER.MOBILE_HEIGHT,
  minHeight: HEADER.MOBILE_HEIGHT,
  height: 'auto',
  backgroundColor: theme.palette.grey[100],
  boxShadow: 'none',
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('lg')]: {
    // width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`, TODO: CHECK
    ...(isCollapse &&
      {
        // width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)` TODO: CHECK
      }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default
    })
  }
}));

export const ICONS = {
  settings: getIcon('menu_settings'),
  settlements: getIcon('settlements-icon', 15, 15),
  sharing: getIcon('menu_sharing', 15, 15),
  confirmations: getIcon('menu_confirmations', 19, 21),
  transactions: getIcon('transactions'),
  logout: getIcon('logout'),
  terms: getIcon('terms'),
  privacy: getIcon('privacy'),
  imprint: getIcon('imprint')
};

export const StyledDataGrid = styled(DataGridPremium)(
  // @ts-ignore
  ({ theme, isChild = false }) => ({
    ...(isChild && {
      '& .MuiDataGrid-columnHeaders': { display: 'none' },
      '& .MuiDataGrid-virtualScroller': {
        marginTop: '0!important',
        overflowX: 'hidden'
      },
      margin: theme.spacing(0, 0, 0, 2),
      msOverflowX: 'none',
      border: '1px solid',
      borderRadius: 0,
      borderColor: theme.palette.divider
    }),
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent'
        }
      }
    },
    [`& .${gridClasses.row}.odd`]: {
      backgroundColor: 'transparent',
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent'
        }
      }
    },

    '& .MuiDataGrid-cell:focus': {
      outline: 'solid transparent 1px'
    },
    '& .MuiDataGrid-cell:focus-within': {
      outline: 'solid transparent 1px'
    },
    '& .MuiDataGrid-columnHeader:focus': {
      outline: 'solid transparent 1px'
    },
    '& .MuiDataGrid-columnHeader:focus-within': {
      outline: 'solid transparent 1px'
    },
    '& .MuiDataGrid-iconButtonContainer': {
      width: '0px',
      position: 'absolute',
      right: '0px'
      // backgroundColor: theme.palette.background.default
    }
  })
);

export default StyledDashboardMain;
