import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useLinkHandling } from './useLinkHandling';
import { useUserflowStatusQuery } from '@/components/userflow/queries/useUserflowStatus.query';
import { useUserflowQuery } from '@/components/userflow/queries/useUserflow.query';

export const useOnboardingRedirect = () => {
  useUserflowQuery({
    userflow: 'initial_setup'
  });

  const { isLoading, data } = useUserflowStatusQuery({
    userflow: 'initial_setup'
  });
  const router = useRouter();
  const { ONBOARDING } = useLinkHandling();

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (!data) {
      return;
    }

    if (data.access && data.redirect) {
      router.push(ONBOARDING.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data, ONBOARDING.path]);
};
