import { create } from 'zustand';

type AppConnectInfoStoreProps = {
  isNotifactionHidden: boolean;
  setIsNotifactionHidden: (arg1: boolean) => void;
};

export const useAppConnectInfoStore = create<AppConnectInfoStoreProps>((set) => ({
  isNotifactionHidden: false,
  setIsNotifactionHidden: (isHidden: boolean) => {
    set({ isNotifactionHidden: isHidden });
  }
}));
