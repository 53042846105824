import React from 'react';

import { UnfoldMore } from '@mui/icons-material';
import { ListItem, ListItemText } from '@mui/material';
import { useTranslation } from 'next-i18next';

import { GREY } from '@/lib/styles/palette.style';
import { useGlobalStore } from '@/lib/state-management/useGlobalStore';
import { useMenu } from '../../providers/menu-provider.component';
import { useSearch } from '../../providers/search-provider.component';

export const ListItemComponent: React.FC = () => {
  const { t } = useTranslation('common');
  const selectedAmazonAccount = useGlobalStore((state) => state.selectedAmazonAccount);
  const { setAnchorEl } = useMenu();
  const { setSearch } = useSearch();

  const primaryText =
    selectedAmazonAccount?.custom_account_name ??
    (selectedAmazonAccount?.name || t('components.global-dropdown.no-account-selected'));
  const secondaryText = selectedAmazonAccount?.region;

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setSearch('');
  };

  return (
    <ListItem
      id='lock-button'
      aria-haspopup='listbox'
      aria-controls='lock-menu'
      onClick={handleClickListItem}
      sx={{
        padding: 0,
        cursor: 'pointer'
      }}
      data-cy='list-item-component'
    >
      <ListItemText
        inset
        primary={primaryText}
        secondary={secondaryText}
        primaryTypographyProps={{
          fontSize: 14,
          fontWeight: 'bold',
          color: GREY[800]
        }}
        secondaryTypographyProps={{
          fontSize: 12,
          textTransform: 'uppercase'
        }}
        sx={{
          paddingLeft: 0
        }}
      />
      <UnfoldMore
        sx={{
          marginLeft: 1,
          color: GREY[600]
        }}
      />
    </ListItem>
  );
};
