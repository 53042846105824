import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

import { HEADER } from '@/lib/constants/layout.constant';

import { PositionCenter, PositionTop } from '../types/assistant-position.type';

export const StyledStatusTextField = styled(TextField)(({ theme }) => ({
  fieldset: {
    borderColor: theme.palette.primary.main
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main
    }
  },
  label: {
    color: theme.palette.primary.main
  }
}));

export const customButtonStylesGray = (additionalStyles = {}) => {
  return {
    ...additionalStyles,
    color: 'grey.600',
    borderColor: 'grey.600',
    '&:hover': {
      borderColor: 'grey.500',
      color: 'grey.500',
      backgroundColor: 'grey.100'
    }
  };
};

export const positionCenter: PositionCenter = {
  position: 'sticky',
  top: `calc(50% + ${HEADER.MAIN_DESKTOP_HEIGHT}px)`,
  transform: 'translate(0%, -50%)',
  mx: '10%',
  zIndex: 999
};

export const positionTop: PositionTop = {
  mb: 2,
  gridArea: 'assistant',
  overflow: 'inherit',
  position: 'sticky',
  zIndex: 1100,
  top: HEADER.MAIN_DESKTOP_HEIGHT + 16 //  16px is the padding of main
};
