import { z } from 'zod';

export const CheckoutSubscriptionSessionRequestSchema = z.object({
  priceId: z.string()
});

export type CheckoutSubscriptionSessionRequest = z.infer<
  typeof CheckoutSubscriptionSessionRequestSchema
>;

export const CheckoutSubscriptionSessionResponseSchema = z.object({
  url: z.string()
});

export type CheckoutSubscriptionSessionResponse = z.infer<
  typeof CheckoutSubscriptionSessionResponseSchema
>;
