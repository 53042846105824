import { Box, Button, Card, Grid, Typography } from '@mui/material';
import SubscriptionsModal from '../modals/subscriptions-modal/subscriptions-modal.component';
import { useModalStore } from '@/lib/state-management/useModalStore';
import { useTranslation } from 'next-i18next';
import { AssistantAvatarComponent } from '../assistant/components/assistant/assistant-avatar.component';
import { useRouter } from 'next/router';
import { useLinkHandling } from '@/lib/hooks/useLinkHandling';

export const SubsciptionInfoBox: React.FC = () => {
  const setShowSubscriptionModal = useModalStore((state) => state.setShowSubscriptionModal);
  const { t } = useTranslation('common');
  const { asPath } = useRouter();
  const URLS = useLinkHandling();

  const getTranslationByPath = () => {
    switch (asPath) {
      case URLS.RECOMMENDATIONS.path:
        return 'recommendations';
      case URLS.AUTOMATIONS.path:
        return 'automations';
      case URLS.REVIEWS_ASINS.path:
        return 'reviews';
      default:
        return 'recommendations';
    }
  };

  return (
    <Card
      sx={{ p: 2, borderTop: (theme) => `8px solid ${theme.palette.primary.main}`, mb: 4 }}
      data-cy='subscription-info-box'
    >
      <SubscriptionsModal onClose={() => setShowSubscriptionModal(false)} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8} sx={{ display: 'flex', gap: 4 }}>
          <Box>
            <AssistantAvatarComponent isLoading={false} />
          </Box>
          <Box>
            <Typography
              mb={1}
              variant='h5'
              sx={{ color: (theme) => theme.palette.primary.main, mb: 0 }}
            >
              {t(`subscription-info-box.headline-${getTranslationByPath()}`)}
            </Typography>
            <Typography
              sx={{
                fontWeight: 'bold'
              }}
            >
              {t(`subscription-info-box.upgrade-text-${getTranslationByPath()}`)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            variant='contained'
            size='large'
            onClick={() => setShowSubscriptionModal(true)}
            sx={{ py: 1, width: 256, mt: 2 }}
          >
            {t('subscription-info-box.upgrade-now')}
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};
