import { IconButton } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import useChatStore from '@/lib/state-management/useChatStore';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import ArrowForwardIosTwoToneIcon from '@mui/icons-material/ArrowForwardIosTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import useResponsive from '@/lib/hooks/useResponsive';
import { useChatAskQuestion } from '../../queries/use-chat-ask-question.mutation';

export const ChatHeaderComponent: React.FC = () => {
  const setChatHistory = useChatStore((state) => state.setChatHistory);
  const setChatHistorySessionId = useChatStore((state) => state.setChatHistorySessionId);
  const setIsMinimized = useChatStore((state) => state.setIsMinimized);
  const isMobile = useResponsive('down', 'sm');
  const useChatAskQuestionMutation = useChatAskQuestion();

  const handleDeletChathistory = () => {
    setChatHistory([]);
    setChatHistorySessionId();
    useChatAskQuestionMutation.mutate({ question: null });
  };

  return (
    <Box display='flex' justifyContent='space-between' data-cy='chat-header-component'>
      <IconButton
        data-cy='minimize-chat'
        aria-label='minimize chat'
        onClick={() => setIsMinimized(true)}
        sx={{ width: '40px' }}
      >
        {isMobile ? (
          <CloseTwoToneIcon fontSize='medium' />
        ) : (
          <ArrowForwardIosTwoToneIcon fontSize='small' />
        )}
      </IconButton>
      <IconButton
        data-cy='delete-history'
        aria-label='new chat'
        onClick={() => handleDeletChathistory()}
      >
        <DeleteOutlineTwoToneIcon />
      </IconButton>
    </Box>
  );
};
