import React, { useEffect, useState } from 'react';
import { InputAdornment, OutlinedInput, IconButton, Stack } from '@mui/material';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';

import { ChatAvatarComponent } from '../chat-layout/chat-avatar.component';
import useChatStore from '@/lib/state-management/useChatStore';
import { useChatAskQuestion } from '../../queries/use-chat-ask-question.mutation';

import { useTranslation } from 'next-i18next';
import { useFlag } from '@unleash/proxy-client-react';
import { FEATURE_FLAGS } from '@/lib/feature-flags/feature-flags.constants';

import dynamic from 'next/dynamic';
import { useUserQ as _useUserQ } from '@/lib/hooks/queries/useUser.query';
import { useGlobalStore } from '@/lib/state-management/useGlobalStore';
import { useSession } from 'next-auth/react';
import { InputTypes } from '../../chat.types';

const ChatMicrophoneComponent = dynamic(
  () => import('../chat-microphone.component').then((mod) => mod.ChatMicrophoneComponent),
  { ssr: false }
);
interface ChatMessageInputProps {
  useUserQ?: typeof _useUserQ;
}
export const ChatMessageInputComponent: React.FC<ChatMessageInputProps> = ({
  useUserQ = _useUserQ
}) => {
  const isVoiceRecognitionEnabled = useFlag(FEATURE_FLAGS.VOICERECOGNITION);
  const { t } = useTranslation('chat');
  const session = useSession();
  const [isVoiceRecognitionRunning, setIsVoiceRecognitionRunning] = useState(false);
  const addChatMessage = useChatStore((state) => state.addChatMessage);
  const isIntroSent = useChatStore((state) => state.isIntroSent);
  const setIsIntroSent = useChatStore((state) => state.setIsIntroSent);
  const useChatAskQuestionMutation = useChatAskQuestion();
  const [chatInputText, setChatInputText] = useState('');
  const { isLoading: isLoadingUser, data: user } = useUserQ();
  const selectedAmazonAcc = useGlobalStore((state) => state.selectedAmazonAccount);

  useEffect(() => {
    if (!isIntroSent && !isLoadingUser && selectedAmazonAcc && user && session && session.data) {
      useChatAskQuestionMutation.mutate({ question: null });
      setIsIntroSent(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntroSent, session]);

  const handleAskQuestion = (inputType: InputTypes) => {
    if (!chatInputText || chatInputText.trim() === '') return;
    addChatMessage({ type: 'user', message: chatInputText });
    useChatAskQuestionMutation.mutate({ question: chatInputText, inputType });
    setChatInputText('');
  };

  return (
    <Stack spacing={2} direction='row' data-cy='chat-message-input-component'>
      <ChatAvatarComponent type='user' />
      <OutlinedInput
        inputProps={{ 'data-cy': 'chat-message-input' }}
        value={chatInputText}
        onChange={(e) => setChatInputText(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleAskQuestion('textfield');
            e.preventDefault();
          }
        }}
        size='small'
        fullWidth
        disabled={isVoiceRecognitionRunning}
        placeholder={t('chat.input-placeholder')}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              data-cy='chat-message-send'
              edge='end'
              onClick={() => handleAskQuestion('textfield')}
              disabled={isVoiceRecognitionRunning}
            >
              <SendOutlinedIcon />
            </IconButton>
          </InputAdornment>
        }
      />
      {isVoiceRecognitionEnabled ? (
        <ChatMicrophoneComponent
          setChatInputText={setChatInputText}
          handleAskQuestion={handleAskQuestion}
          setIsVoiceRecognitionRunning={setIsVoiceRecognitionRunning}
        />
      ) : null}
    </Stack>
  );
};
