import { UserModule } from '@/types/user/user';
import { useGlobalStore } from '../state-management/useGlobalStore';
import { useFlag } from '@unleash/proxy-client-react';
import { FEATURE_FLAGS } from '../feature-flags/feature-flags.constants';
import { useAbility } from '@casl/react';
import { AbilityContext } from './abilities/Can';
import { urlToSubject } from './abilities/abilities';

interface CheckPermissionProps {
  url: string;
  userModules: UserModule[];
}

export const urlToPermissionMapping = {
  '/accounts/[account]/automations': ['automation'],
  '/accounts/[account]/dashboard/profit/[[...params]]': ['dashboard'],
  '/accounts/[account]/dashboard/[[...params]]': ['main-dashboard'],
  '/accounts/[account]/dashboard/ppc-reporting/[[...params]]': ['ppc_reporting'],
  '/accounts/[account]/base-data/[[...params]]': ['product_costs'],
  '/accounts/[account]/dashboard/reviews/[[...params]]': ['reviews']
};

export const publicRoutes = [
  // URL / should be public because it is being redirected to /dashboard. We should check permissions for dashboard instead
  // if URL / is not public, while the user is not redirected, he will see a permission denied page
  '/',
  '/settings/[[...params]]',
  '/settings/general',
  '/settings/amazon-accounts',
  '/settings/plan-and-billing',
  '/settings/sharing'
];

export const adminRoutes = ['/admin', '/admin/client-data', '/admin/kpi', '/admin/use-cases'];

urlToPermissionMapping['/accounts/[account]/recommendations'] = [
  'recommendations',
  'recommendations_automated'
];

export const useCheckPagePermission = ({ url, userModules }: CheckPermissionProps): boolean => {
  const selectedAmazonAccount = useGlobalStore((state) => state.selectedAmazonAccount);

  const ability = useAbility(AbilityContext);

  const isNewPricingPackagesEnabled = useFlag(FEATURE_FLAGS.NEW_PRICING_PACKAGES);
  // const freeRoutesForNotPaidOrTrialUsers = [
  //   '/accounts/[account]/dashboard/[[...params]]',
  //   '/accounts/[account]/dashboard/profit/[[...params]]',
  //   '/accounts/[account]/dashboard/ppc-reporting/[[...params]]',
  //   '/accounts/[account]/base-data/[[...params]]'
  // ];

  if (!url) {
    return false;
  }

  // if the route is public, like settings, then return true anyway
  if (publicRoutes.includes(url)) {
    return true;
  }

  // checks if the account is selected
  if (!selectedAmazonAccount) {
    return false;
  }

  // checks if its amazonAccountOfParentUser
  const isAmazonAccountOfParentUser = selectedAmazonAccount?.isAmazonAccountOfParentUser;

  // checks the role of userModules and checks if the user is paid or in trial
  const userKeyModules = userModules?.map((module: UserModule) => module.key);

  const isAdmin: boolean = userKeyModules?.includes('admin_all');

  // if the role has isAdmin && its an admin route, return true
  if (isAdmin && adminRoutes.includes(url)) {
    return true;
  }

  if (!isAmazonAccountOfParentUser) {
    if (userModules?.length === 0 || !userModules) {
      return false;
    }

    const isUserPaidOrTrial: boolean =
      userKeyModules.includes('paid') || userKeyModules.includes('trial');

    if (
      isUserPaidOrTrial
      // || (isNewPricingPackagesEnabled && freeRoutesForNotPaidOrTrialUsers.includes(url))
    ) {
      return true;
    }

    if (isNewPricingPackagesEnabled) {
      const subjectReturned = urlToSubject(url);
      const resultAbility = ability.can('see', subjectReturned);
      return resultAbility;
    }
  }

  if (isAmazonAccountOfParentUser) {
    // const isAmazonUserPaidOrTrail =
    //   selectedAmazonAccount.roles?.includes('paid') ||
    //   selectedAmazonAccount.roles?.includes('trial');

    // get the permissions of the selected amazon account
    const amazonAccountPermission = selectedAmazonAccount.permission?.resolved;

    // checks maps the url and gets the permission key
    const permissionKey = urlToPermissionMapping[url];

    // if it doesnt find any permission key in the object, returns false
    if (!permissionKey) {
      return false;
    }

    const hasPermission = permissionKey?.some(
      (permission) =>
        amazonAccountPermission?.some(
          // commenting out isAmaazonUserPaidOrTrial, because this hook should only decide if the user hasPermissions shared with him or not
          // independent of the user being paid or not, this hook should decide about the permissions
          // If the user owner of the amazon account is not paid, the assistant should offer the user to upgrade
          // the useState component should decide which component should be displayed in this case, since both are centered: AccessDeniedComponent or Arthy
          // (amzAccPermission) => amzAccPermission.name === permission && isAmazonUserPaidOrTrail
          (amzAccPermission) => amzAccPermission.name === permission
        )
    );
    return hasPermission;
  }

  return false;
};
