import { Badge, Chip, ChipOwnProps, Tooltip } from '@mui/material';

export interface AutomationTooltipBadgeProps {
  title: string;
  icon: ChipOwnProps['icon'];
  count: number;
  color: 'primary' | 'success';
  maxBadgeCount?: number;
  tooltip: string;
}

export const AutomationTooltipBadge: React.FC<AutomationTooltipBadgeProps> = ({
  title,
  tooltip,
  icon,
  color,
  count,
  maxBadgeCount = 1000
}) => {
  return (
    <Tooltip title={tooltip}>
      <Badge showZero color={color} badgeContent={count} max={maxBadgeCount}>
        <Chip size='small' variant='outlined' color={color} label={title} icon={icon} />
      </Badge>
    </Tooltip>
  );
};
