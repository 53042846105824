import Link from 'next/link';
import { NavItemLinkContent } from './nav-item-link-content';
import { useCheckPagePermission } from '@/lib/permissions/app-permissions';
import { useUserQ } from '@/lib/hooks/queries/useUser.query';
import 'tippy.js/dist/tippy.css';

import type { NavItem } from '@/types/navigation/navigation';

interface NavItemLinkProps {
  item: NavItem;
  isCollapse: boolean;
  showNotificationCounter?: boolean;
  numberOfRecommendations?: number;
  open?: boolean;
}

const NavItemLink: React.FC<NavItemLinkProps> = ({
  item,
  isCollapse,
  showNotificationCounter = false,
  numberOfRecommendations = 0,
  open
}) => {
  const { data } = useUserQ();
  const pagePermission = useCheckPagePermission({
    url: item.route,
    userModules: data?.userModules
  });
  const navLinkContent = (
    <NavItemLinkContent
      item={item}
      isCollapse={isCollapse}
      numberOfRecommendations={numberOfRecommendations}
      showNotificationCounter={showNotificationCounter}
      open={open}
    />
  );

  return pagePermission && !item.disabled ? (
    <Link id={item.title} href={item.path}>
      {navLinkContent}
    </Link>
  ) : (
    navLinkContent
  );
};

export default NavItemLink;
