import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import { AppSession } from '@/lib/auth/auth.types';
import { CONFIG } from '@/lib/config/config';
import { useGlobalStore } from '@/lib/state-management/useGlobalStore';

import { processApiResponseReactQuery } from '@/lib/api/process-api-response-react-query.helper';
import { requestApi } from '@/lib/api/request-api.helper';
import { QUERY_KEYS_STR } from './query-keys.constants';
import { AmazonAccountsResponse } from '@/types/api/user/amazon-accounts.type';

type Props = {
  session: AppSession;
};

const fetchUserAmazonAccounts = async ({ session }: Props) => {
  if (session?.status !== 'authenticated') {
    throw new Error('Session must be provided and user should be authenticated');
  }
  const url = CONFIG.API.ENDPOINT + CONFIG.API.METHODS.USER_AMAZON_ACC;
  const response = await requestApi.get({ url, session });
  return processApiResponseReactQuery<AmazonAccountsResponse>({ meta: true, response });
};

export function findAccountById(ownAndSharedAccounts, ID) {
  return ownAndSharedAccounts.find(
    (account) => account._id === ID || account.amazon_account_id === ID
  );
}

export const useAmazonAndSharedAccountsQ = ({
  shouldRefetch = false,
  ms = 5000
}: {
  shouldRefetch?: boolean;
  ms?: number;
} = {}) => {
  const session = useSession({ required: false });
  const selectedAmazonAccount = useGlobalStore((state) => state.selectedAmazonAccount);
  const setAmazonAccount = useGlobalStore((state) => state.setAmazonAccount);
  const resetStore = useGlobalStore((state) => state.resetStore);
  const refetchInterval = shouldRefetch ? { refetchInterval: ms } : {};

  const queryResult = useQuery({
    enabled: session.status === 'authenticated',
    queryKey: [QUERY_KEYS_STR.AMAZON_ACCOUNTS.GET_AMAZON_ACCOUNTS_AND_SHARED],
    ...refetchInterval,
    queryFn: () => fetchUserAmazonAccounts({ session }),
    select: (amazonAccObj: AmazonAccountsResponse) => {
      const ownAndSharedAccounts = [
        ...amazonAccObj.data.amazon_accounts,
        ...amazonAccObj.data.shared_amazon_accounts_receiving
      ]
        // != instead of !== because we want to exclude null and undefined
        .filter((amazonAccObj) => amazonAccObj != null);

      if (ownAndSharedAccounts.length > 0 && selectedAmazonAccount === null) {
        setAmazonAccount(ownAndSharedAccounts[0]);
      }
      if (ownAndSharedAccounts.length === 0) {
        resetStore();
      }

      const newSelectedAmazonAccount = findAccountById(
        ownAndSharedAccounts,
        selectedAmazonAccount?._id
      );
      if (newSelectedAmazonAccount) {
        setAmazonAccount(newSelectedAmazonAccount);
      }
      return {
        ownAccounts: amazonAccObj.data.amazon_accounts,
        sharedAccounts: amazonAccObj.data.shared_amazon_accounts_receiving
      };
    }
  });
  return queryResult;
};
