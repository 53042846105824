import { Alert, Box, Button, Snackbar, Typography } from '@mui/material';
import { useAmazonAccountActionMutation } from '../settings/queries/amazon-account-action.mutation';
import { useGlobalStore } from '@/lib/state-management/useGlobalStore';
import { FEATURE_FLAGS } from '@/lib/feature-flags/feature-flags.constants';
import { useFlag } from '@unleash/proxy-client-react';
import { Trans, useTranslation } from 'next-i18next';
import { useUserQ } from '@/lib/hooks/queries/useUser.query';
import { useAppConnectInfoStore } from './useAppConnectInfoStore';

export const AppConnectInfo: React.FC = () => {
  const { t } = useTranslation('common');
  const { data: user } = useUserQ();
  const amazonAccountActionMutation = useAmazonAccountActionMutation();
  const selectedAmazonAccount = useGlobalStore((state) => state.selectedAmazonAccount);
  const isAppReconnectInfoEnabled = useFlag(FEATURE_FLAGS.APP_RECONNECT_INFO);
  const isNotifactionHidden = useAppConnectInfoStore((state) => state.isNotifactionHidden);
  const setIsNotifactionHidden = useAppConnectInfoStore((state) => state.setIsNotifactionHidden);

  if (selectedAmazonAccount?.is_arthy_app || !isAppReconnectInfoEnabled || isNotifactionHidden)
    return;

  return (
    <Snackbar
      open={true}
      key='app-connect-info'
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert variant='filled' severity='warning' icon={false}>
        <Typography variant='body2'>
          <Trans i18nKey='app-connect-info.info-text' values={{ username: user?.username }} />
        </Typography>
        <Box sx={{ textAlign: 'end', mt: 1 }}>
          <Button
            color='inherit'
            variant='outlined'
            size='small'
            onClick={() => setIsNotifactionHidden(true)}
          >
            {t('components.button.close')}
          </Button>
          <Button
            color='inherit'
            size='small'
            sx={{
              color: (theme) => theme.palette.background.paper,
              background: (theme) => theme.palette.text.primary,
              placeSelf: 'center',
              ml: 1,

              '&:hover': {
                background: (theme) => theme.palette.text.secondary
              }
            }}
            onClick={() => {
              if (selectedAmazonAccount) {
                amazonAccountActionMutation.mutate({
                  account_name: selectedAmazonAccount.name,
                  region: selectedAmazonAccount.region,
                  main_marketplace: selectedAmazonAccount.default_marketplace.marketplace_id,
                  name: 'getoauth',
                  isOnboardingFailedInit: false,
                  isAccountFinished: selectedAmazonAccount?.status === 'finished'
                });
              }
            }}
          >
            {t('app-connect-info.reconnect')}
          </Button>
        </Box>
      </Alert>
    </Snackbar>
  );
};
