// useStore.ts
import { useState, useEffect } from 'react';
// https://github.com/pmndrs/zustand/blob/main/docs/integrations/persisting-store-data.md#usage-in-nextjs

/**
 * This helper is used to hydrate the store on the client side, when the store is initialized on the server side.
 */
export const useHydrationStore = <T, F>(
  store: (callback: (state: T) => unknown) => unknown,
  callback: (state: T) => F
) => {
  const result = store(callback) as F;
  const [data, setData] = useState<F>();

  useEffect(() => {
    setData(result);
  }, [result]);

  return data;
};
