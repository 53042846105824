import { FormEvent, useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';

import { AdvertisingAccount } from '@/types/user/user-advertising-account.type';
import { AmazonAccount } from '@/types/user/user-amazon-account.type';
import { Add } from '@mui/icons-material';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps
} from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { SettingsAmazonAccountConnectFormContentActions } from './settings-amazon-account-connect-form-content-actions.component';
import { SettingsAmazonAccountConnectFormContent } from './settings-amazon-account-connect-form-content.component';
import { useAllAccountsQ } from '@/lib/hooks/queries/useAmazonAccounts.query';

import { useAmazonAccountActionMutation } from '../../queries/amazon-account-action.mutation';

import { useSettingsStore } from '../../useSettings.store';
import { useAmazonAccountUpdateMutation } from '../../queries/amazon-account-update.mutation';

interface SettingsAmazonAccountConnectFormProps {
  standalone?: boolean;
  defaultSelectType?: 'getoauth' | 'getoauth-advertising-api';
  hideAccountTypeSelect?: boolean;
  isSignUp?: boolean;
  sx?: SxProps;
  isOnboardingFailedInit?: boolean;
}

const SettingsAmazonAccountConnectFormComponent: React.FC<
  SettingsAmazonAccountConnectFormProps
> = ({
  standalone = false,
  hideAccountTypeSelect = false,
  defaultSelectType = 'getoauth',
  isSignUp = false,
  sx,
  isOnboardingFailedInit = false
}) => {
  const { t } = useTranslation(['settings', 'common']);
  const [modalOpen, setModalOpen] = useState(false);

  const { query } = useRouter();

  const amazonAccountUpdateMutation = useAmazonAccountUpdateMutation();
  const amazonAccountActionMutation = useAmazonAccountActionMutation();
  const selectedAccount = useSettingsStore((state) => state.preselectedAccount);
  const setPreselectedAccount = useSettingsStore((state) => state.setPreselectedAccount);

  const { data: allAccounts, isLoading: isLoadingAccounts } = useAllAccountsQ();

  const [nameInput, setNameInput] = useState('');
  const [typeSelect, setTypeSelect] = useState<string>(defaultSelectType);
  const [defaultMarketplace, setDefaultMarketplace] = useState('');
  const [sourceAmazonAccount, setSourceAmazonAccount] = useState<
    AmazonAccount | AdvertisingAccount | null
  >(null); // represents the last persisted state of amazon account
  // const [formDisabled, setFormDisabled] = useState(false);
  const [limitReached, setLimitReached] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState('eu');
  const [selectedMainMarketplace, setSelectedMainMarketplace] = useState('');

  const isFormDisabled = isLoadingAccounts;
  useEffect(() => {
    setModalOpen(typeof selectedAccount === 'string');
  }, [selectedAccount]);

  useEffect(() => {
    Modal.setAppElement('body');
  });

  useEffect(() => {
    if (
      allAccounts &&
      allAccounts.metadata?.usedAccounts &&
      allAccounts.metadata?.allowedAccounts &&
      allAccounts.metadata?.usedAccounts >= allAccounts.metadata?.allowedAccounts &&
      !sourceAmazonAccount
    ) {
      setLimitReached(true);
    } else {
      setLimitReached(false);
    }
  }, [allAccounts, sourceAmazonAccount]);
  const requiresMarketplaceConfirmation =
    // @ts-ignore
    sourceAmazonAccount && sourceAmazonAccount.confirmedMarketplace === false;

  useEffect(() => {
    if (selectedAccount && allAccounts) {
      const accounts = [...allAccounts.amazon_accounts, ...allAccounts.advertising_accounts];
      const prefilledAccount = accounts.find((acc) => acc._id === selectedAccount);
      if (prefilledAccount) {
        setSourceAmazonAccount(prefilledAccount);
        setNameInput(prefilledAccount.name);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount]);

  const newButtonClicked = useCallback(
    (type = 'getoauth') => {
      setPreselectedAccount('');
      setSourceAmazonAccount(null);
      setNameInput('');
      setTypeSelect(type);
    },
    [setPreselectedAccount]
  );

  useEffect(() => {
    const { params } = query;
    if (params && params.length > 0) {
      if (params[0] === 'amazon-accounts-new') {
        newButtonClicked('getoauth');
      }
      if (params[0] === 'advertising-accounts-new') {
        newButtonClicked('getoauth-advertising-api');
      }
    }
  }, [query, newButtonClicked]);

  const headline = sourceAmazonAccount
    ? t('common:components.connected-amazon-accounts.form.headline-edit', {
        // @ts-ignore
        title: sourceAmazonAccount.name
      })
    : t('common:components.connected-amazon-accounts.form.headline');
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleRegion = (newValue: string) => {
    setSelectedRegion(newValue);
    setSelectedMainMarketplace('');
  };

  const handleMainMarketplace = (newValue: string) => {
    setSelectedMainMarketplace(newValue);
  };
  const submitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (sourceAmazonAccount) {
      const payload = {
        // @ts-ignore
        selected: sourceAmazonAccount._id,
        accountName: nameInput
      };
      if (requiresMarketplaceConfirmation) {
        // @ts-ignore
        payload.defaultMarketplace = defaultMarketplace;
      }
      amazonAccountUpdateMutation.mutate(payload);
    } else {
      amazonAccountActionMutation.mutate({
        account_name: nameInput,
        region: selectedRegion,
        main_marketplace: selectedMainMarketplace,
        name: typeSelect,
        isOnboardingFailedInit
      });
    }
    setPreselectedAccount(null);
  };
  const content = (
    <SettingsAmazonAccountConnectFormContent
      hideAccountTypeSelect={hideAccountTypeSelect}
      limitReached={limitReached}
      sourceAmazonAccount={sourceAmazonAccount}
      setButtonDisabled={setButtonDisabled}
      requiresMarketplaceConfirmation={requiresMarketplaceConfirmation}
      handleRegion={handleRegion}
      handleMainMarketplace={handleMainMarketplace}
      selectedMainMarketplace={selectedMainMarketplace}
      selectedRegion={selectedRegion}
      nameInput={nameInput}
      setNameInput={setNameInput}
      typeSelect={typeSelect}
      setTypeSelect={setTypeSelect}
      defaultMarketplace={defaultMarketplace}
      setDefaultMarketplace={setDefaultMarketplace}
      formDisabled={isFormDisabled}
    />
  );

  const actions = (
    <SettingsAmazonAccountConnectFormContentActions
      isSourceAmazonAccount={!!sourceAmazonAccount}
      confirmedMarketplace={sourceAmazonAccount?.confirmedMarketplace || false}
      disabledConfirmAccountSource={buttonDisabled}
      disabledConfirmAccount={
        amazonAccountActionMutation.isPending ||
        nameInput.length <= 0 ||
        (typeSelect === 'getoauth' && !selectedMainMarketplace)
      }
      typeSelect={typeSelect}
      isSignUp={isSignUp}
    />
  );
  return (
    <Box component='div'>
      <>
        {!standalone && (
          <Button
            data-cy='settings.amazon-accounts-new-button'
            size='large'
            onClick={() => newButtonClicked()}
            id='amazon-accounts-new'
            startIcon={<Add />}
            sx={{ ...sx }}
          >
            {t('common:components.connected-amazon-accounts.form.create')}
          </Button>
        )}

        {standalone ? (
          <Box data-cy='amazon-account-standalone' component='form' onSubmit={(e) => submitForm(e)}>
            {content}
            {actions}
          </Box>
        ) : (
          <Dialog
            open={modalOpen}
            onClose={() => {
              setPreselectedAccount(null);
            }}
          >
            <Box
              data-cy='amazon-account-new-dialog'
              component='form'
              sx={{ width: 600 }}
              onSubmit={(e) => submitForm(e)}
            >
              <DialogTitle>{headline}</DialogTitle>
              <DialogContent>{content}</DialogContent>
              <DialogActions style={{ margin: 15 }}>
                <Button
                  variant='outlined'
                  type='button'
                  data-cy='confirm-cancel-button'
                  onClick={() => setPreselectedAccount(null)}
                >
                  {t('common:components.connected-amazon-accounts.form.cancel')}
                </Button>
                {actions}
              </DialogActions>
            </Box>
          </Dialog>
        )}
      </>
    </Box>
  );
};

export default SettingsAmazonAccountConnectFormComponent;
