import { PaymentSinglePackage } from '@/types/payment/new-payment-package';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { formatFloat } from '@/components/dashboard/helpers/format-float.helper';

interface PricingSectionCheckoutProps {
  paymentPackage: PaymentSinglePackage;
  currency?: 'EUR' | 'USD';
  redirectType?: 'signup';
  trialMessage?: string;
  activeVariant: number;
}

const SubscriptionPriceInfo = ({ packageName }) => {
  const { t } = useTranslation(['payment', 'common']);

  if (packageName === 'arthy-base') {
    return (
      <Typography variant='caption' component='p' sx={{ color: 'text.primary' }}>
        {t(`payment:payment.free-info`)}
      </Typography>
    );
  }
  if (packageName === 'arthy-enterprise') {
    return (
      <Typography variant='caption' component='p' sx={{ color: 'text.primary' }}>
        {t(`payment:payment.individual-info`)}
      </Typography>
    );
  }

  return (
    <Typography variant='caption' component='p' sx={{ color: 'text.primary' }}>
      {t('payment:payment.subscription-info')}
      <br />
      {t('payment:payment.price-info')}
    </Typography>
  );
};

const PricingSectionInfo: React.FC<PricingSectionCheckoutProps> = ({
  paymentPackage,
  currency,
  activeVariant
}) => {
  const { t } = useTranslation(['payment', 'common']);

  const currentInterval = paymentPackage.variants[activeVariant].interval;

  const monthVariant = paymentPackage.variants.find((variant) => variant.interval === 'month');
  const sixMonthVariant = paymentPackage.variants.find(
    (variant) => variant.interval === 'six-month'
  );
  const monthPriceVariantMonth = (monthVariant?.price || 0) / 100;
  const monthPriceVariantSixMonth = (sixMonthVariant?.price || 0) / 100 / 6;

  const oldMonthPriceVariantMonth = (monthVariant?.old_price || 0) / 100;
  const oldMonthPriceVariantSixMonth = (sixMonthVariant?.old_price || 0) / 100 / 6;

  return (
    <Box sx={{ height: 88, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      {paymentPackage.name === 'arthy-enterprise' ? (
        <Typography variant='body1' component='h6' fontWeight='bold'>
          {t(`payment:payment.individual`)}
        </Typography>
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'baseline',
              textDecoration: 'line-through'
            }}
          >
            <Typography
              data-cy='pricing-section-checkout-active-price'
              variant='body1'
              component='h6'
              fontWeight='bold'
            >
              {formatFloat({
                amount:
                  currentInterval === 'six-month'
                    ? oldMonthPriceVariantSixMonth
                    : oldMonthPriceVariantMonth,
                decimalCount: 2,
                decimal: t('general.decimalPoint', {
                  ns: 'common'
                }) as string,
                thousands: t('general.thousandPoint', {
                  ns: 'common'
                }) as string
              })}{' '}
              {currency === 'USD' ? '$' : '€'}
            </Typography>

            <Typography variant='body1' component='h6' fontWeight='bold'>
              /{' ' + t('general.date.month', { ns: 'common' })}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'baseline'
            }}
          >
            <Typography
              data-cy='pricing-section-checkout-active-price'
              variant='body1'
              component='h6'
              fontWeight='bold'
            >
              {formatFloat({
                amount:
                  currentInterval === 'six-month'
                    ? monthPriceVariantSixMonth
                    : monthPriceVariantMonth,
                decimalCount: 2,
                decimal: t('general.decimalPoint', {
                  ns: 'common'
                }) as string,
                thousands: t('general.thousandPoint', {
                  ns: 'common'
                }) as string
              })}{' '}
              {currency === 'USD' ? '$' : '€'}
            </Typography>

            <Typography variant='body1' component='h6' fontWeight='bold'>
              /{' ' + t('general.date.month', { ns: 'common' })}
            </Typography>
          </Box>
        </>
      )}
      <SubscriptionPriceInfo packageName={paymentPackage.name} />
    </Box>
  );
};

export default PricingSectionInfo;
