import { useState } from 'react';

import {
  Badge,
  Box,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { useTranslation } from 'next-i18next';

import Scrollbar from '../scroll-bar/scroll-bar.component';
import IconButtonAnimate from '../ui/icon-button-animate.style';
import Iconify from '../ui/iconify';
import MenuPopover from '../ui/menu-popover';
import { Notification } from './dashboard-notification.type';
import { useUserNotificationQ as _useUserNotificationQ } from '@/lib/hooks/queries/use-user-notifications/use-user-notification.query';
import { useUpdateUserNotifications as _useUpdateUserNotifications } from '@/lib/hooks/queries/use-user-notifications/use-update-user-notifications.mutation';

interface NotificationsPopoverProps {
  useUserNotificationQ?: typeof _useUserNotificationQ;
  useUpdateUserNotifications?: typeof _useUpdateUserNotifications;
}
const NotificationsPopoverComponent: React.FC<NotificationsPopoverProps> = ({
  useUserNotificationQ = _useUserNotificationQ,
  useUpdateUserNotifications = _useUpdateUserNotifications
}) => {
  const { t } = useTranslation('nav-bar');

  const { data: userNotificationData } = useUserNotificationQ();
  const notifications: Notification[] | null = userNotificationData;
  const totalUnRead = notifications?.length || 0;
  const updateUserNotifications = useUpdateUserNotifications();

  const [open, setOpen] = useState<Element | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = () => {
    setOpen(null);
    updateUserNotifications.mutate();
  };

  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
        data-cy='notifications-popover'
      >
        <Badge badgeContent={totalUnRead} color='error' data-cy='badge-un-read'>
          {/* @ts-ignore */}
          <Iconify icon='eva:bell-fill' width={20} height={20} />
        </Badge>
      </IconButtonAnimate>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        disabledArrow
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
        data-cy='notifications-dropdown'
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant='subtitle1'>
              {t(`notification-bell.title`, { ns: 'nav-bar' })}
            </Typography>
            <Typography variant='body2' sx={{ color: 'text.secondary' }}>
              {t('notification-bell.unreadMessages', {
                ns: 'nav-bar',
                unreadCount: totalUnRead
              })}
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip arrow title={t('notification-bell.markAsSeen', { ns: 'nav-bar' })}>
              <IconButton color='primary' onClick={handleMarkAllAsRead} data-cy='mark-all-as-read'>
                {/* @ts-ignore */}
                <Iconify icon='eva:done-all-fill' width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          {/* @ts-ignore */}
          <List px={2.5}>
            {notifications &&
              notifications?.map((notification) => (
                <ListItem key={notification.key}>
                  <Stack direction='column'>
                    <Typography variant='subtitle1'>
                      {t(`notification-bell.messages.${notification.key}.headline`, {
                        ns: 'nav-bar',
                        defaultValue: ''
                      })}
                    </Typography>
                    {t(`notification-bell.messages.${notification.key}.description`, {
                      ns: 'nav-bar',
                      defaultValue: ''
                    })}
                    {(() => {
                      const isExternalLink = `${notification.link}`
                        .toLowerCase()
                        .startsWith('http');

                      if (!notification.link) {
                        return null;
                      }

                      if (isExternalLink) {
                        return (
                          <Link href={notification.link} target='_blank' rel='noopener noreferrer'>
                            {t(`notification-bell.messages.${notification.key}.button`, {
                              ns: 'nav-bar',
                              defaultValue: ''
                            })}
                          </Link>
                        );
                      }

                      return (
                        <Link href={notification.link}>
                          {t(`notification-bell.messages.${notification.key}.button`, {
                            ns: 'nav-bar',
                            defaultValue: ''
                          })}
                        </Link>
                      );
                    })()}
                  </Stack>
                </ListItem>
              ))}
          </List>
        </Scrollbar>
      </MenuPopover>
    </>
  );
};

export default NotificationsPopoverComponent;
