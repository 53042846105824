import { AmazonAccount, UserAmazonAccountMetadata } from '@/types/user/user-amazon-account.type';
import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import { AppSession } from '@/lib/auth/auth.types';
import { CONFIG } from '@/lib/config/config';

import { processApiResponseReactQuery } from '@/lib/api/process-api-response-react-query.helper';
import { requestApi } from '@/lib/api/request-api.helper';
import { AmazonAccountsDataResponse } from '@/types/api/user/amazon-accounts.type';
import { QUERY_KEYS_STR } from './query-keys.constants';
import { AdvertisingAccount } from '@/types/user/user-advertising-account.type';

type Props = {
  session: AppSession;
};

export const fetchUserAmazonAccounts = async ({ session }: Props) => {
  if (session?.status !== 'authenticated') {
    throw new Error('Session must be provided and user should be authenticated');
  }
  const url = CONFIG.API.ENDPOINT + CONFIG.API.METHODS.USER_AMAZON_ACC;

  const response = await requestApi
    .get({ url, session })
    .then((response) =>
      processApiResponseReactQuery<AmazonAccountsDataResponse>({ response, returnAll: true })
    );

  return { ...response.data, metaData: response.metadata };
};
// TODO: rewrite and optimize query using select property and stop spreading queryResult. This is bad  practice since 3.0. Combine useAmazonAccountsQ and useAmazonAccountAndSharedQ
export const useAmazonAccountsQ = () => {
  const session = useSession({ required: true });
  const queryResult = useQuery<AmazonAccount[]>({
    enabled: session.status === 'authenticated',
    queryKey: [QUERY_KEYS_STR.AMAZON_ACCOUNTS.GET_AMAZON_ACCOUNTS],
    queryFn: async () =>
      fetchUserAmazonAccounts({ session: session as AppSession }).then(
        (amazonAccountData) => amazonAccountData.amazon_accounts
      )
  });
  return {
    ...queryResult
  };
};

export const useAllAccountsQ = () => {
  const session = useSession({ required: true });
  const queryResult = useQuery<{
    amazon_accounts: AmazonAccount[];
    advertising_accounts: AdvertisingAccount[];
    metadata: { usedAccounts: number; allowedAccounts: number };
  }>({
    enabled: session.status === 'authenticated',
    queryKey: [QUERY_KEYS_STR.AMAZON_ACCOUNTS.GET_AMAZON_ACCOUNTS_GET_ALL],
    queryFn: async () => fetchUserAmazonAccounts({ session: session as AppSession })
  });
  return {
    ...(queryResult as {
      data: {
        amazon_accounts: AmazonAccount[];
        advertising_accounts: AdvertisingAccount[];
        metadata: UserAmazonAccountMetadata;
      };
      isLoading: boolean;
      refetch: () => void;
    })
  };
};

export const useIntervalAmazonAccountsQ = ({ shouldRefetch }: { shouldRefetch: boolean }) => {
  const session = useSession({ required: true });

  const queryResult = useQuery({
    enabled: session.status === 'authenticated',
    queryKey: [QUERY_KEYS_STR.AMAZON_ACCOUNTS.GET_AMAZON_ACCOUNTS],
    queryFn: async () =>
      fetchUserAmazonAccounts({ session: session as AppSession }).then(
        (amazonAccountData) => amazonAccountData?.amazon_accounts
      ),
    refetchInterval: shouldRefetch ? 2000 : false
  });
  return { ...queryResult };
};
