import { useState } from 'react';

import { CheckoutSubscriptionSessionResponseSchema } from '@/types/api/payment/post-checkout-subscription-session';
import { PaymentSinglePackage } from '@/types/payment/new-payment-package';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Link,
  Typography
} from '@mui/material';
import { Trans, useTranslation } from 'next-i18next';

import { CONFIG } from '@/lib/config/config';
import { useUserQ } from '@/lib/hooks/queries/useUser.query';

import { requestApi } from '@/lib/api/request-api.helper';

interface PricingSectionCheckoutNewProps {
  paymentPackage: PaymentSinglePackage;
  redirectType?: 'signup';
  activeVariant: number;
}

const PricingSectionCheckoutNew: React.FC<PricingSectionCheckoutNewProps> = ({
  paymentPackage,
  activeVariant,
  redirectType
}) => {
  const { data: user } = useUserQ();

  const { t } = useTranslation(['payment', 'common']);
  const [cancelationPolicy, setCancelationPolicy] = useState(false);
  const [termsOfService, setTermsOfService] = useState(false);
  const urlTermsAndConditions = t('payment:payment.url-terms-and-conditions');
  const urlPrivacyPolicy = t('payment:payment.url-privacy-policy');

  const makeAStripePost = () => {
    requestApi
      .post({
        state: {
          user
        },
        url: `${CONFIG.API.ENDPOINT}${CONFIG.API.METHODS.TRANSACTIONS.PAYMENT_CHECKOUT_SUBSCRIPTION_SESSION}`,
        body: {
          // @ts-ignore
          priceId: paymentPackage.variants[activeVariant].priceId,
          redirectType
        }
      })
      .then((res) => {
        // @ts-ignore
        if (res.ok) {
          // @ts-ignore
          res.json().then((data: CheckoutSubscriptionSessionResponse) => {
            // window.open(data.url, '_blank'); // creates new tab with the payment
            const result = CheckoutSubscriptionSessionResponseSchema.safeParse(data);
            if (result.success) {
              window.location.assign(result.data.url); // same window send to the checkout url
            } else {
              console.error(result.error);
            }
          });
        }

        // @ts-ignore
        if (!res.ok) {
          // @ts-ignore
          res.json().then((url) => Promise.reject(url));
        }
      });
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormGroup
          sx={{
            pb: 2
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                data-cy='subscriptions-modal-terms-of-service-checkbox'
                onChange={() => {
                  setTermsOfService(!termsOfService);
                }}
              />
            }
            label={
              <Typography variant='caption'>
                <Trans i18nKey='payment.policies.general-terms-link' ns='payment'>
                  <Link
                    href={urlTermsAndConditions}
                    target='_blank'
                    rel='noopener'
                    underline='hover'
                  />
                </Trans>
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                data-cy='subscriptions-modal-privacy-policy-checkbox'
                onChange={() => {
                  setCancelationPolicy(!cancelationPolicy);
                }}
              />
            }
            label={
              <Typography variant='caption'>
                <Trans i18nKey='payment.policies.privacy-policy-link' ns='payment'>
                  <Link href={urlPrivacyPolicy} target='_blank' rel='noopener' underline='hover' />
                </Trans>
              </Typography>
            }
          />
        </FormGroup>
      </Box>

      <Box sx={{ textAlign: 'center' }}>
        <Button
          data-cy='subscriptions-modal-subscribe-button'
          variant='contained'
          disabled={!(cancelationPolicy && termsOfService)}
          onClick={() => makeAStripePost()}
          sx={{ alignSelf: 'center' }}
        >
          {t('payment:payment.actions.subscribe')}
        </Button>
      </Box>
    </>
  );
};

export default PricingSectionCheckoutNew;
