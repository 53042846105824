import React, { Ref } from 'react';

import { BoxProps } from '@mui/material';
import Image from 'next/image';

interface ImageComponentProps extends BoxProps {
  src?: string | React.ReactElement | null;
  alt: string;
  width?: number;
  height?: number;
  other?: string | undefined;
  ref?: Ref<HTMLImageElement>;
  color?: string | undefined;
}

const ImageComponent: React.FC<ImageComponentProps> = ({
  src,
  alt = '',
  width = 64,
  height = 64,
  ref,
  ...other
}) => {
  const typedOther = other as {
    [key: string]: string | undefined;
  };

  if (!src) {
    src = '/assets/no-image.svg';
  }

  if (React.isValidElement(src)) {
    return src;
  }

  const isSvg = (src as string).toLowerCase().endsWith('.svg');

  if (isSvg) {
    return (
      <Image
        ref={ref}
        src={src as string}
        alt={alt}
        width={width}
        height={height}
        style={{ objectFit: 'contain' }}
        {...typedOther}
      />
    );
  }

  return (
    <div
      style={{
        width: `${width}px`,
        height: `${height}px`,
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      <Image
        src={src as string}
        alt={alt}
        blurDataURL='/assets/no-image.svg'
        fill
        sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
        {...typedOther}
      />
    </div>
  );
};

export default ImageComponent;
