import { PaymentPackages } from '@/types/payment/new-payment-package';
import { Box, Card, CardContent, Chip, Stack, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';

import { AssistantSignupComponent } from '@/components/assistant/assistant-signup/assistant-signup.component';

import React, { useState } from 'react';
import PricingSectionPackage from './new-pricing-section-package';
import PricingSectionVariants from './new-pricing-section-variants';

interface PricingSectionProps {
  paymentPackage: PaymentPackages;
  trial?: string;
  redirectType?: 'signup';
  productName?: string;
  currency?: 'EUR' | 'USD';
}

const PricingSectionNew: React.FC<PricingSectionProps> = ({
  paymentPackage,
  currency = 'EUR',
  trial,
  redirectType,
  productName
}) => {
  const { t } = useTranslation('payment');
  const baseName = t('payment.modals.payment-base-package.product');
  const [activeVariant, setActiveVariant] = useState(1);

  return (
    <Stack gap={2}>
      {redirectType === 'signup' && <AssistantSignupComponent step='bookPackage' stepIndex={4} />}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Card sx={{ width: '100%' }} variant='outlined'>
          <CardContent>
            <Box
              sx={{
                flex: 2,
                mb: 2
              }}
            >
              <Typography variant='h6' textAlign='center'>
                <Box data-cy='pricing-section.name' sx={{ pr: 1, display: 'inline' }}>
                  {productName || baseName}
                </Box>

                {trial && (
                  <Chip
                    sx={{ fontSize: 'smaller' }}
                    data-cy='pricing-section.trial'
                    color='info'
                    label={trial}
                  />
                )}
              </Typography>
            </Box>
            <Box sx={{ textAlign: 'center', mb: 1 }}>
              <PricingSectionVariants
                activeVariant={activeVariant}
                setActiveVariant={setActiveVariant}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                gap: 2,
                '@media (max-width: 1000px)': {
                  flexWrap: 'wrap',
                  justifyContent: 'center'
                }
              }}
            >
              {/* pricing später durch paymanetPackage ersetzen? */}
              {paymentPackage.prices.map((pricing) => (
                <React.Fragment key={pricing.name}>
                  <PricingSectionPackage
                    redirectType={redirectType}
                    paymentPackage={pricing}
                    currency={currency}
                    activeVariant={activeVariant}
                  />
                </React.Fragment>
              ))}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Stack>
  );
};
export default PricingSectionNew;
