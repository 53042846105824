export const formatFloat = ({
  amount: amountParam,
  decimalCount: decimalCountParam = 2,
  decimal = '.',
  thousands = ','
}) => {
  try {
    // @ts-ignore
    let decimalCount = parseFloat(decimalCountParam);
    decimalCount = Math.abs(decimalCount);
    decimalCount = Number.isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amountParam < 0 ? '-' : '';

    const amount = Math.abs(Number(amountParam) || 0).toFixed(decimalCount);
    const i = parseInt(amount, 10).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
      (decimalCount
        ? decimal +
          //@ts-ignore
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    console.warn(e);
  }
  return '';
};
