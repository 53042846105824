import { Box, Button } from '@mui/material';
import { useTranslation } from 'next-i18next';
import React from 'react';

interface PricingSectionVariantsProps {
  activeVariant: number;
  setActiveVariant: (variant: number) => void;
}

const PricingSectionVariants: React.FC<PricingSectionVariantsProps> = ({
  activeVariant,
  setActiveVariant
}) => {
  const { t } = useTranslation('payment');

  return (
    <Box>
      <Button
        variant={activeVariant === 0 ? 'contained' : 'outlined'}
        sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, width: 192 }}
        onClick={() => setActiveVariant(0)}
      >
        1 {t(`general.date.month`, { ns: 'common' })}
      </Button>
      <Button
        variant={activeVariant === 1 ? 'contained' : 'outlined'}
        sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, width: 192 }}
        onClick={() => setActiveVariant(1)}
      >
        6 {t(`general.date.months`, { ns: 'common' })}
      </Button>
    </Box>
  );
};
export default PricingSectionVariants;
