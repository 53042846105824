import { AmazonAccount } from '@/types/user/user-amazon-account.type';
import { useGlobalStore } from '../state-management/useGlobalStore';
import { useAmazonAccountsQ } from './queries/useAmazonAccounts.query';

/**
 * TODO: Use hook everywhere in code where we have manual checks like:
 * typeof selectedAmazonAccount?.permission === "undefined"
 * We could also use the check mentioned above to skip calling the useAmazonAccountsQ. Could reduce execution time, even if the query is cached.
 * But this is only a theory.
 */
export const useIsOwnAmazonAccount = () => {
  const selectedAmazonAccount = useGlobalStore(
    (state) => state.selectedAmazonAccount as AmazonAccount
  );
  const { data: amazonAccounts } = useAmazonAccountsQ();
  // NOTE: Added fallback false because the result of some on the array can also be undefined
  const isOwnAmazonAccount =
    amazonAccounts?.some((acc) => acc?._id?.includes(selectedAmazonAccount?._id)) ?? false;
  return isOwnAmazonAccount;
};
