import { create } from 'zustand';

type State = { preselectedAccount: string | null };
type Actions = { setPreselectedAccount: (account_id: string | null) => void };

const initialState: State = {
  preselectedAccount: null
};

export const useSettingsStore = create<State & Actions>()((set) => ({
  ...initialState,
  setPreselectedAccount: (account_id) => {
    set({ preselectedAccount: account_id });
  }
}));
