import { ChatWidgetState, ChatMessage } from '@/components/chat/chat.types';
import { generateRandomChatHistorySessionId } from '@/components/chat/helpers/chat-helpers';
import { Daterange, MarketplaceCountries } from '@/types/dashboard/dashboard.type';
import { create } from 'zustand';

export interface ChatState {
  chatHistory: ChatMessage[];
  setChatHistory: (arg1: ChatMessage[] | []) => void;
  addChatMessage: (message: ChatMessage) => void;
  chatHistorySessionId: string;
  setChatHistorySessionId: () => void;
  isFetching: boolean;
  setIsFetching: (isFetching: boolean) => void;
  chatWidgetState: ChatWidgetState[];
  setChatWidgetState: (widgets: ChatWidgetState[]) => void;
  daterange?: Daterange;
  marketplaces?: MarketplaceCountries[];
  ratings?: number[];
  products?: string[];
  chatSessionVersion: string;
  setChatSessionVersion: (chatSessionVersion: string) => void;
  isMinimized: boolean;
  setIsMinimized: (isMinimized: boolean) => void;
  isIntroSent: boolean;
  setIsIntroSent: (isIntroSent: boolean) => void;
}

const initialState = {
  chatWidgetState: [],
  chatHistory: [],
  chatHistorySessionId: generateRandomChatHistorySessionId(),
  chatSessionVersion: '',
  isFetching: false,
  isMinimized: true,
  isIntroSent: false
};

const useChatStore = create<ChatState>((set) => ({
  ...initialState,
  setChatHistory: (chat) => {
    set({ chatHistory: chat });
  },
  setChatWidgetState: (widgets: ChatWidgetState[]) => {
    set({ chatWidgetState: widgets });
  },

  addChatMessage: (message) => {
    set((state) => ({ chatHistory: [...state.chatHistory, message] }));
  },
  setChatHistorySessionId: () => {
    set({ chatHistorySessionId: generateRandomChatHistorySessionId() });
  },
  setIsFetching: (isFetching: boolean) => {
    set({ isFetching });
  },
  setChatSessionVersion: (chatSessionVersion: string) => {
    set({ chatSessionVersion });
  },
  setIsMinimized: (isMinimized: boolean) => {
    set({ isMinimized });
  },
  setIsIntroSent: (isIntroSent: boolean) => {
    set({ isIntroSent });
  }
}));

export default useChatStore;
