import { Box, Card, CardContent, Stack, Typography } from '@mui/material';

import { ReactNode } from 'react';

interface StatusInfoProps {
  title?: string;
  message?: string | React.ReactNode;
  icon?: ReactNode;
}

export const StatusInfo: React.FC<StatusInfoProps> = ({ title = '', message = '', icon }) => {
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
      }}
    >
      <Card>
        <CardContent data-cy='access-denied'>
          <Stack gap={2} alignItems='center'>
            {icon || null}
            <Typography variant='h5' color='warning'>
              {title}
            </Typography>
            <Typography variant='body1'>{message}</Typography>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
};
