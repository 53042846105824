import React, { useEffect } from 'react';

import { AmazonAccount } from '@/types/user/user-amazon-account.type';
import { Box } from '@mui/material';
import List from '@mui/material/List';

import { useAmazonAndSharedAccountsQ as _useAmazonAndSharedAccountsQ } from '@/lib/hooks/queries/useAmazonAndSharedAccounts.query';
import { useSelectedAccPaidStatusQ as _useSelectedAccPaidStatusQ } from '@/lib/hooks/queries/useSelectedAccPaidStatus.query';
import { useGlobalStore } from '@/lib/state-management/useGlobalStore';

import { ListItemComponent } from './components/list/base-menu-list-item.component';
import { MenuComponent } from './components/menu/base-menu-menu.component';
import { MenuProvider } from './providers/menu-provider.component';
import { SearchProvider } from './providers/search-provider.component';
import LoadingSpinnerComponent from '../ui/loading-spinner.component';
import { useUserQ } from '@/lib/hooks/queries/useUser.query';
import { useUpdateUserSelectedAmazonAccountMutation } from '@/lib/queries/user/mutation/use-update-user.mutation';

export type UseAmazonAndSharedAccountsQResponse = {
  isLoading: boolean;
  data: AmazonAccount[][] | null | undefined | [];
};

interface BaseMenuProps {
  useAmazonAndSharedAccountsQ?: typeof _useAmazonAndSharedAccountsQ;
  useSelectedAccPaidStatusQ?: typeof _useSelectedAccPaidStatusQ;
}

export const BaseMenu: React.FC<BaseMenuProps> = ({
  useAmazonAndSharedAccountsQ = _useAmazonAndSharedAccountsQ,
  useSelectedAccPaidStatusQ = _useSelectedAccPaidStatusQ
}) => {
  const { data: user, isLoading } = useUserQ();
  const selectedAmazonAccount = useGlobalStore((state) => state.selectedAmazonAccount);
  const selectedAmazonAccountId = user?.selected_amazon_account;
  const setAmazonAccount = useGlobalStore((state) => state.setAmazonAccount);
  const { isLoading: isAmazonAndSharedLoading, data } = useAmazonAndSharedAccountsQ();
  useSelectedAccPaidStatusQ(selectedAmazonAccountId);

  const updateUserSelectedAmazonAccount = useUpdateUserSelectedAmazonAccountMutation();
  useEffect(() => {
    if (data && !selectedAmazonAccount && selectedAmazonAccountId && !isLoading) {
      // search for account from user model
      const amazonAccount = [...data.ownAccounts, ...data.sharedAccounts]
        .flat()
        .find((acc) => acc._id === selectedAmazonAccountId);
      if (amazonAccount) {
        setAmazonAccount(amazonAccount);
      }
    }

    if (data && !selectedAmazonAccount && !selectedAmazonAccountId && !isLoading) {
      // user has no selected amazon account so we use the first one and update the user model initially

      const selectableAccount = data.ownAccounts[0] ?? data.sharedAccounts[0] ?? null;
      if (!selectableAccount) return;
      setAmazonAccount(selectableAccount);
      updateUserSelectedAmazonAccount.mutate({ selected_amazon_account: selectableAccount._id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedAmazonAccount, selectedAmazonAccountId]);

  if (isAmazonAndSharedLoading) {
    return <LoadingSpinnerComponent />;
  }
  return (
    <Box
      style={{
        marginLeft: 24
      }}
    >
      <MenuProvider>
        <SearchProvider>
          <List
            component='nav'
            sx={{
              padding: 0,
              bgcolor: 'background.paper'
            }}
          >
            <ListItemComponent />
          </List>
          <MenuComponent />
        </SearchProvider>
      </MenuProvider>
    </Box>
  );
};
