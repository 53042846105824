import { useTranslation } from 'next-i18next';

import { CONFIG } from '@/lib/config/config';

import SingleSelect from '@/components/ui/single-select';

import { SelectAmazonAccountTypeMenu } from './select-amazon-account-type-menu.component';

interface SelectAmazonAccountProps {
  selectedMainMarketplace: string;
  selectedRegion: string;
  typeSelect: string;
  handleMainMarketplace: (newValue: string) => void;
  handleRegion: (newValue: string) => void;
  setTypeSelect: (newValue: string) => void;
  disabled?: boolean;
  hideAccountTypeSelect?: boolean;
}

export const SelectAmazonAccount: React.FC<SelectAmazonAccountProps> = ({
  selectedMainMarketplace,
  selectedRegion,
  typeSelect,
  handleRegion,
  handleMainMarketplace,
  setTypeSelect,
  disabled,
  hideAccountTypeSelect = false
}) => {
  const {
    t,
    i18n: { language }
  } = useTranslation(['settings', 'common']);
  const regionOptions = CONFIG.SETTINGS.REGIONS_DASHBOARD.map((region) => ({
    value: region,
    userText: t(`common:general.regions.${region}`, { defaultValue: '' })
  }));

  const marketplaceOptions =
    CONFIG.SETTINGS.REGIONS[selectedRegion]?.map((marketplace: string) => ({
      value: marketplace,
      userText: t(`common:general.countries.${marketplace}`, {
        defaultValue: ''
      })
    })) ?? [];

  return (
    <>
      {!hideAccountTypeSelect && (
        <SelectAmazonAccountTypeMenu
          setTypeSelect={setTypeSelect}
          disabled={disabled}
          typeSelect={typeSelect}
        />
      )}
      <SingleSelect
        data-cy='amazon-account-region-input'
        options={regionOptions}
        labelText={t('common:components.select.region-selector.label')}
        selectedValue={selectedRegion}
        onChange={(value) => handleRegion(value)}
        selectPlaceholder={t('common:components.select.region-selector.placeholder')}
      />
      {typeSelect === 'getoauth' && (
        <SingleSelect
          data-cy='amazon-account-marketplace-input'
          options={marketplaceOptions.sort((a, b) =>
            new Intl.Collator(language).compare(a.userText, b.userText)
          )} // new Intl.Collator needs to be updated for future languages
          labelText={t('common:components.select.home-marketplace-selector.label')}
          selectedValue={selectedMainMarketplace}
          onChange={(value) => handleMainMarketplace(value)}
          selectPlaceholder={t('common:components.select.home-marketplace-selector.placeholder')}
        />
      )}
    </>
  );
};
