import React, { useState } from 'react';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import { CardActions, IconButton } from '@mui/material';
import { chatMessageActionButtonSize } from '../../style/chat.style';
import { useAddChatReview } from '../../queries/use-add-chat-review.mutation';
import { GREY } from '@/lib/styles/palette.style';
import type { ChatReview } from '../../chat.types';
import type { ThumbsColor } from './chat-message.types';

interface ChatMessageActionsComponentProps {
  review: ChatReview;
}

export const ChatMessageActionsComponent: React.FC<ChatMessageActionsComponentProps> = ({
  review
}) => {
  const addChatReviewMutation = useAddChatReview();
  const [disableThumbButtons, setDisableThumbButtons] = useState<boolean>(false);

  const [thumbsColor, setThumbsColor] = useState<ThumbsColor>({ up: {}, down: {} });
  const thumbColor = { color: `${GREY[600]} !important` };

  const handleAddChatReview = (feedback: boolean) => {
    addChatReviewMutation.mutate({ ...review, feedback });
    setDisableThumbButtons(true);
    if (feedback) {
      setThumbsColor({ up: thumbColor, down: {} });
    } else {
      setThumbsColor({ up: {}, down: thumbColor });
    }
  };

  return (
    <CardActions sx={{ pt: 0, mt: -1 }} data-cy='chat-message-actions-component'>
      <IconButton
        sx={thumbsColor.up}
        disabled={disableThumbButtons}
        onClick={() => handleAddChatReview(true)}
        data-cy='chat-message-action-button-thumbs-up'
      >
        <ThumbUpOffAltIcon sx={chatMessageActionButtonSize} />
      </IconButton>
      <IconButton
        sx={thumbsColor.down}
        disabled={disableThumbButtons}
        onClick={() => handleAddChatReview(false)}
        data-cy='chat-message-action-button-thumbs-down'
      >
        <ThumbDownOffAltIcon sx={chatMessageActionButtonSize} />
      </IconButton>
    </CardActions>
  );
};
