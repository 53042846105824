import { usePricingTableQuery } from '@/lib/hooks/queries/usePricingTable.query';
import { useUserQ } from '@/lib/hooks/queries/useUser.query';
import { Box } from '@mui/material';

export const StripePricingTableComponent = () => {
  const { isLoading, data } = usePricingTableQuery();
  const { data: user } = useUserQ();
  if (isLoading || !data || !user) return null;
  return (
    <Box sx={{ width: '100%' }}>
      <stripe-pricing-table
        pricing-table-id={data.stripe_id}
        client-reference-id={user._id}
        customer-email={user.email}
        publishable-key={process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY}
      ></stripe-pricing-table>
    </Box>
  );
};
