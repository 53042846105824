import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import { AppSession } from '@/lib/auth/auth.types';
import { CONFIG } from '@/lib/config/config';

import { processApiResponseReactQuery } from '@/lib/api/process-api-response-react-query.helper';
import { requestApi } from '@/lib/api/request-api.helper';
import { PaymentPackages } from '@/types/payment/new-payment-package';

type Props = {
  session: AppSession;
  packageName: string;
};
const paymentPackageKeys = {
  all: ['payment-package'] as const
};
const fetchPaymentPackage = async ({ session, packageName }: Props) => {
  if (session?.status !== 'authenticated') {
    throw new Error('Session must be provi');
  }

  const url = `${CONFIG.API.ENDPOINT}${CONFIG.API.METHODS.TRANSACTIONS.PAYMENT_PAYMENT_PACKAGE}/name/${packageName}`;
  return requestApi
    .get({ url, session })
    .then((response) => processApiResponseReactQuery({ response }));
};

export const usePaymentPackageByNameQ = ({
  packageName,
  isNewPricingPackagesEnabled
}: {
  packageName: string;
  isNewPricingPackagesEnabled: boolean;
}) => {
  const session = useSession({ required: true });

  const queryResult = useQuery({
    enabled: session.status === 'authenticated' && isNewPricingPackagesEnabled,
    queryKey: [paymentPackageKeys.all],
    queryFn: async () =>
      fetchPaymentPackage({ session, packageName }).then((paymentPackageObj) => paymentPackageObj)
  });
  return { ...queryResult } as { data: PaymentPackages };
};
