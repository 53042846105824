import { styled } from '@mui/material/styles';
import Link from 'next/link';

import { GREY, PRIMARY } from '@/lib/styles/palette.style';

export const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter
    })
  }
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  color: GREY[500],
  '&:hover': {
    textDecoration: 'underline',
    color: PRIMARY.main
  },
  fontSize: theme.typography.pxToRem(13)
}));
