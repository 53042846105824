import { useState } from 'react';

import { Box, Button, Dialog, DialogActions } from '@mui/material';
import { useTranslation } from 'next-i18next';

import { useAmazonAccountsQ as _useAmazonAccountsQ } from '@/lib/hooks/queries/useAmazonAccounts.query';
import { useGetTotalCostSavingsQuery as _useGetTotalCostSavingsQuery } from '@/lib/hooks/queries/useGetTotalCostSavings.query';
import { useUserQ as _useUserQ } from '@/lib/hooks/queries/useUser.query';
import { useModalStore } from '@/lib/state-management/useModalStore';

import ExitModalComponent from '../exit-modal/exit-modal.component';
import { useFlag } from '@unleash/proxy-client-react';
import { FEATURE_FLAGS } from '@/lib/feature-flags/feature-flags.constants';
import { StripePricingTableComponent } from './stripe-pricing-table.component';
import PricingSectionNew from './pricing-section/new-pricing-section.component';
import { usePaymentPackageByNameQ } from '@/lib/hooks/queries/usePaymentPackageByName.query';

interface SubscriptionsModalProps {
  productId?: string;
  onClose: () => void;
  useUserQ?: typeof _useUserQ;
  useAmazonAccountsQ?: typeof _useAmazonAccountsQ;
  useGetTotalCostSavingsQuery?: typeof _useGetTotalCostSavingsQuery;
}

const SubscriptionsModal: React.FC<SubscriptionsModalProps> = ({
  onClose,
  useUserQ = _useUserQ
}) => {
  const showSubscriptionModal = useModalStore((state) => state.showSubscriptionModal);
  const { t } = useTranslation('payment');
  const [showExitModal, setShowExitModal] = useState(false);
  const onExitModalCloseConfirm = () => {
    setShowExitModal(false);
    onClose();
  };
  // @ts-ignore
  const { isLoading: isLoadingUser, data: userData } = useUserQ();
  // if more than one amazon account, show EUR
  const isStripePricingTableEnabled = useFlag(FEATURE_FLAGS.STRIPE_PRICING_TABLE);
  const { data: paymentPackageNew } = usePaymentPackageByNameQ({
    packageName: 'pricing-december-2023',
    isNewPricingPackagesEnabled: true
  });
  if (isLoadingUser && !userData) return null;

  return (
    <Dialog
      data-cy='subscriptions-dialog-container'
      onClose={onClose}
      open={showSubscriptionModal}
      fullWidth
      maxWidth='md'
    >
      <Box data-cy='subscriptions-modal' sx={{ width: '100%' }}>
        {isStripePricingTableEnabled ? (
          <StripePricingTableComponent />
        ) : (
          <PricingSectionNew paymentPackage={paymentPackageNew} />
        )}
      </Box>
      <DialogActions
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          p: 2
        }}
      >
        <Button
          data-cy='subscriptions-modal-cancel-button'
          onClick={() => setShowExitModal(true)}
          color='primary'
          variant='outlined'
        >
          {t('payment.actions.cancel', { ns: 'payment' })}
        </Button>
      </DialogActions>
      <ExitModalComponent
        exitModalOpen={showExitModal}
        closeAndCleanModal={onExitModalCloseConfirm}
        setExitModalOpen={setShowExitModal}
        message={t('payment.modals.payment-abort.message', { ns: 'payment' })}
        yesText={t('payment.actions.payment-abort.yes', { ns: 'payment' })}
        noText={t('payment.actions.payment-abort.no', { ns: 'payment' })}
      />
    </Dialog>
  );
};

export default SubscriptionsModal;
