import { useMemo } from 'react';

import { AmazonAccount } from '@/types/user/user-amazon-account.type';

export const useCalculateYearlyRevenueForAllAccounts = (amazonAccounts: AmazonAccount[]) => {
  const yearlyRevenueForAllAccounts = useMemo(() => {
    return amazonAccounts?.reduce((acc, curr) => {
      return acc + (curr?.yearly_revenue ? curr.yearly_revenue : 0);
    }, 0);
  }, [amazonAccounts]);

  const hasYearlyRevenue =
    !yearlyRevenueForAllAccounts || yearlyRevenueForAllAccounts === 0 ? false : true;

  return {
    yearlyRevenueForAllAccounts,
    hasYearlyRevenue,
    accountsWithoutRevenue: amazonAccounts?.filter(
      (account) => typeof account?.yearly_revenue === 'undefined'
    )
  };
};
