import { useMutation } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import { AppSession } from '@/lib/auth/auth.types';
import { CONFIG } from '@/lib/config/config';

import { processApiResponseReactQuery } from '@/lib/api/process-api-response-react-query.helper';
import { requestApi } from '@/lib/api/request-api.helper';

export const useContactSupport = () => {
  const session = useSession({ required: true }) as AppSession;

  return useMutation({
    mutationFn: async ({
      supportReason
    }: {
      supportReason: string;
      setIsDialogOpen: (isOpen: boolean) => void;
    }) =>
      requestApi
        .post({
          url: `${CONFIG.API.ENDPOINT}${CONFIG.API.METHODS.SUPPORT}`,
          session,
          body: { supportReason }
        })
        .then((response) => {
          const data = response as Response;
          return processApiResponseReactQuery({ response: data });
        }),
    onSuccess: (data, variables) => {
      if (data) {
        variables.setIsDialogOpen(true);
      }
    }
  });
};
