import { SxProps } from '@mui/system';
import Image from 'next/image';
import NextLink from 'next/link';

import ArthyLogo from '/public/assets/arthy_logo.svg';
import ArthyLogoA from '/public/assets/logo_a.svg';

interface LogoProps extends React.HTMLAttributes<HTMLDivElement> {
  isCollapse?: boolean;
  disabledLink?: boolean;
  sx?: SxProps;
}
const Logo: React.FC<LogoProps> = ({ isCollapse = false, disabledLink = false }) => {
  const logo = (
    <Image
      src={isCollapse ? ArthyLogoA : ArthyLogo}
      priority
      height={isCollapse ? 28 : 32}
      alt='logo'
      style={{ cursor: isCollapse ? 'default' : 'pointer' }}
      data-cy='arthy-logo'
    />
  );
  if (disabledLink) {
    return logo;
  }
  return (
    <NextLink href='/' legacyBehavior>
      {logo}
    </NextLink>
  );
};

export default Logo;
